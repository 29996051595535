import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { Card, createStyles, makeStyles, Theme, Typography } from '@bb-ui/react-library';
import type { SummaryWidget as SummaryWidgetType } from '@lct-jobs/config';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '1rem',
    },
  }),
);

export const SummaryWidget: React.FC = () => {
  const { execution } = useJobExecutionContext();
  const classes = useStyles();
  const widget = execution?.jobConfig.jobSummary as SummaryWidgetType | undefined;
  if (widget && widget.widgetId && widget.inputPaths) {
    switch (widget.widgetId) {
      case 'TitleSubtitleTenant':
        return (
          <Card className={classes.card}>
            <Typography variant="h4">{widget.inputPaths.title ?? ''}</Typography>
            <Typography>{widget.inputPaths.subtitle}</Typography>
            <Typography>{widget.inputPaths.tenant}</Typography>
          </Card>
        );
      case 'TitleTenant':
        return (
          <Card className={classes.card}>
            <Typography variant="h4">{widget.inputPaths.title}</Typography>
            <Typography>{widget.inputPaths.tenant}</Typography>
          </Card>
        );
      case 'TitleSubtitleLink':
        return (
          <Card className={classes.card}>
            <Typography variant="h4">{widget.inputPaths.title}</Typography>
            <Typography>{widget.inputPaths.subtitle}</Typography>
            <Typography>
              <a href={widget.inputPaths.link}>Link</a>
            </Typography>
          </Card>
        );
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};
