import { BbThemeProvider, DefaultButton } from '@bb-ui/react-library';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Header } from '../../components/Header';

export interface ErrorPageProps {
  type?: 'notFound' | 'unauthorized';
}

export const useStyles = makeStyles({
  content: {
    height: '100vh',
    width: '100vw',
  },
});

// Loosely typing this so we can use <ButtonBase> props.
const LooseDefaultButton = DefaultButton as any;

export function ErrorPage(props: ErrorPageProps) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const message = t(`error.${props.type ?? 'notFound'}`);

  return (
    <>
      <BbThemeProvider theme="dark">
        <Header />
      </BbThemeProvider>
      <div className={classes.content}>
        <ErrorMessage title={message}>
          <LooseDefaultButton component={Link} to="/">{t('error.return')}</LooseDefaultButton>
        </ErrorMessage>
      </div>
    </>
  );
}
