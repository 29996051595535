// Responds to the result of a successful Auth0 login. appState is created by
// the Auth0 context before login occurs, and contains the path we should return
// to. Auth0 receives this as part of the login request and returns it back to
// us when it's done.

import React from 'react';
import { withStyles, WithStyles } from '@bb-ui/react-library';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { useAuthContext } from 'contexts/AuthContext';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { Redirect, useLocation } from 'react-router-dom';
import { ErrorMessage } from 'components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { styles } from './Callback.styles';

export const Callback: React.FunctionComponent<WithStyles<typeof styles>> = (props) => {
  const { classes } = props;
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { appState, isAuthenticated } = useAuthContext();
  const { supportRedirectUrl } = useAppConfigContext();
  const { t } = useTranslation();

  if (searchParams.get('error')) {
    console.error(`Error from Auth provider: "${searchParams.get('error_description')}"`);
    return (
      <ErrorMessage
        title={t('auth.error')}
        message={searchParams.get('error_description') ?? undefined}
      />
    );
  }

  if (appState && appState.returnPath && isAuthenticated) {
    return <Redirect push to={appState.returnPath as string} />;
  }

  if (searchParams.get('isSupport') === 'true' && supportRedirectUrl) {
    window.location.assign(
      `${supportRedirectUrl}?tenantId=${searchParams.get(
        'tenantId',
      )}&tenantUriComponent=${searchParams.get('tenantUriComponent')}`,
    );
  }

  return (
    <div className={classes.root} data-testid="callback-page">
      <CircularProgress ariaLabel={t('global.loadingIndicator.loading').toString()} />
    </div>
  );
};

export default withStyles(styles)(Callback);
