import React from 'react';
import { JSONSchema7 } from 'json-schema';
import { FieldProps } from '@rjsf/core';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import ReactJson from 'react-json-view';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemIcon } from '@bb-ui/react-library';
import { Error } from '@material-ui/icons';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { JobCardList } from './basic/JobCardList';
import { JobFormCard } from './basic/JobFormCard';

function recursiveParse(object: any): any {
  if (typeof object === 'string') {
    try {
      return recursiveParse(JSON.parse(object));
    } catch (e) {
      // It's not a JSON string
      return object;
    }
  }
  if (Array.isArray(object)) {
    return object.map((item) => recursiveParse(item));
  }
  if (typeof object === 'object') {
    return Object.fromEntries(Object.keys(object).map((key) => [key, recursiveParse(object[key])]));
  }
  return object;
}

const JsonField: React.FC<FieldProps> = (props) => (
  <div>
    <strong>Error Details: </strong>
    <ReactJson src={recursiveParse(props.formData)} enableClipboard={false} name={false} />
  </div>
);

export const JobErrorBox: React.FC = () => {
  const { execution } = useJobExecutionContext();
  const errorSchema: JSONSchema7 = {
    type: 'object',
    properties: {
      error: {
        type: 'string',
        title: 'Error',
      },
      message: {
        type: 'string',
        title: 'Error Message',
      },
      detail: {
        type: 'string',
        title: 'Error Detail',
      },
    },
  };
  return (
    <JobCardList title="Job Error">
      <ListItem>
        <ListItemIcon>
          <Error />
        </ListItemIcon>
        <ListItemText
          primary={execution?.exception?.code}
          secondary={execution?.exception?.message}
        />
      </ListItem>
      {execution?.exception?.detail && (
        <ListItem>
          <ReactJson src={recursiveParse(execution?.exception?.detail)} name={false} />
        </ListItem>
      )}
    </JobCardList>
  );
};
