import * as React from 'react';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAuthContext } from 'contexts/AuthContext';

interface SignInProps {
  returnPath?: string;
}

export const SignIn = (props: React.PropsWithChildren<SignInProps>) => {
  const { returnPath } = props;
  const { signIn } = useAuthContext();

  React.useEffect(() => {
    if (signIn) {
      signIn({ appState: { returnPath } });
    }
  }, [signIn, returnPath]);

  return <LoadingIndicator theme="light" variant="fill-window" data-testid="signing-in" />;
};
