// A button with dropdown menu allowing creation of various things--for now,
// just a new Foundations group.

import { AddCircle } from '@bb-ui/icons/dist/medium/AddCircle';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useAuthorization } from 'hooks/useAuthorization';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateGroupDialog } from 'dialogs/CreateGroupDialog';

export const AddGroupButton: React.FunctionComponent = () => {
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const [groupDialogOpen, setGroupDialogOpen] = React.useState(false);

  if (!hasPermission('fnds-group', 'create')) {
    return null;
  }

  return (
    <>
      <PrimaryButton
        startIcon={<AddCircle />}
        data-testid="header-add-button"
        onClick={() => setGroupDialogOpen(true)}
      >
        {t('groupList.addNew')}
      </PrimaryButton>
      <CreateGroupDialog
        id="create-group-dialog"
        onClose={() => setGroupDialogOpen(false)}
        open={groupDialogOpen}
      />
    </>
  );
};
