import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useRestApi from 'hooks/useRestApi';
import { PageTemplate } from 'components/PageTemplate';
import { apiUrl } from 'utils/apiUrl';
import { TenantData } from 'App.types';
import { AddTenantButton } from './AddTenantButton';
import { TenantListTable } from '../../components/TenantList';

export const TenantList: React.FunctionComponent = (props) => {
  const { data, error, loading, fetch } = useRestApi(apiUrl('tenancy', 'tenants/searchable'));
  const { t } = useTranslation();

  React.useEffect(() => {
    // Manually fetch on each initial render to avoid stale data
    fetch().catch(() => {
      // Handled through useRestApi `error`
    });
  }, [fetch]);

  // TODO: Filter Tenants based on Tenant supported regions for this deployment
  //  (when we have a separate GovCloud deployment)
  // Tenant list datasource
  const tenants = React.useMemo<TenantData[]>(() => data?.results ?? [], [data]);

  return (
    <PageTemplate
      showTabs
      headerControl={<AddTenantButton />}
      title={t('tenantList.pageTitle')}
      data-testid="tenant-list-page"
    >
      <TenantListTable tenants={tenants} error={error} loading={loading} {...props} />
    </PageTemplate>
  );
};
