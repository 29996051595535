import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, createStyles, makeStyles } from '@bb-ui/react-library/dist/components/styles';
import { CardHeader } from '@bb-ui/react-library/dist/components/CardHeader';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { List } from '@bb-ui/react-library/dist/components/List';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { Lock } from '@bb-ui/icons/dist/small/Lock';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useTenantContext } from 'contexts/TenantContext';
import { PageCard } from 'components/PageCard';
import { IdentityProvider, PublicAuth0BrokerData } from '../TenantIdp.types';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  cardContent: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  cardHeader: {
    padding: theme.spacing(0, 0, 4, 0),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardHeaderSecondary: {
    padding: theme.spacing(0, 0, 3, 0),
  },
  cardHeaderAction: {
    marginRight: 0,
  },
  cardHeaderActionControl: {
    marginLeft: theme.spacing(1.5),
  },
  listText: {
    paddingLeft: 0,
  },
  listTextPrimary: {
    marginBottom: theme.spacing(1),
  },
}));

export interface IdpInfoCardProps {
  idp?: IdentityProvider;
}

export const IdpSettingsCard: React.FunctionComponent<IdpInfoCardProps> = (props) => {
  const { idp } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { supportAuth0 } = useAppConfigContext();
  const { tenant } = useTenantContext();

  const samlMetadataUrl = React.useMemo(() => {
    if (idp?.type !== 'SAML') {
      return null;
    }
    const tenantRegion = tenant?.region;
    const spDomain = tenantRegion && supportAuth0?.regions?.[tenantRegion]?.domain;
    const spId = (idp?.brokerData as PublicAuth0BrokerData)?.identityProviderName;
    return spDomain && spId && `https://${spDomain}/samlp/metadata?connection=${spId}`;
  }, [tenant, supportAuth0, idp]);

  return (
    <PageCard data-testid="idp-settings-card">
      <CardHeader
        className={classes.cardHeaderSecondary}
        classes={{ action: classes.cardHeaderAction }}
        title={t('tenantIdp.idpSettings')}
        titleTypographyProps={{ component: 'h3', variant: 'h2' } as any} // any: component expects 'span' | undefined
        action={
          <IconButton className={classes.cardHeaderActionControl}>
            <Lock />
            {t('global.readOnly')}
          </IconButton>
        }
      />
      <CardContent className={classes.cardContent}>
        <List>
          <ListItem disableGutters>
            <ListItemText
              className={classes.listText}
              classes={{ primary: classes.listTextPrimary }}
              primary={t('tenantIdp.idpDisplayName')}
              secondary={idp?.displayName || <i>{t('global.unset')}</i>}
            />
          </ListItem>
          {samlMetadataUrl && (
            <ListItem disableGutters>
              <ListItemText
                className={classes.listText}
                classes={{ primary: classes.listTextPrimary }}
                primary={t('tenantIdp.samlSpMetadata')}
                secondary={<Link href={samlMetadataUrl}>{samlMetadataUrl}</Link>}
              />
            </ListItem>
          )}
        </List>
      </CardContent>
    </PageCard>
  );
};
