// A helper component for a route which contains subroutes. This handles
// rendering the appropriate child route. It only handles a single level of
// nesting; you will need to use <Subroutes /> each time you expect route
// nesting.

import * as React from 'react';
import { Switch } from 'react-router-dom';
import { AppRoute, AppRouteProps } from './AppRoute';

export interface SubroutesProps {
  routes: AppRouteProps[];
}

export const Subroutes: React.FunctionComponent<SubroutesProps> = ({ routes }) => (
  <Switch>
    {routes.map((route, i) => (
      <AppRoute key={i} {...route} />
    ))}
  </Switch>);
