// This displays a loading indicator instead of content while contexts are
// loading data.

import React from 'react';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAuthorization } from 'hooks/useAuthorization';
import { useAuthContext } from 'contexts/AuthContext';
import { SignIn } from '../pages/SignIn';

export const WaitForContexts: React.FunctionComponent = ({ children }) => {
  const { accessToken, loading: authLoading } = useAuthContext();
  const { loading: authzLoading } = useAuthorization();

  if (authLoading || authzLoading) {
    return <LoadingIndicator theme="light" variant="fill-window" data-testid="loading-contexts" />;
  }

  // If access token expired, return to SignIn page;
  if (accessToken) {
    const jwtPayload = JSON.parse(window.atob(accessToken.split('.')[1]));
    if (Date.now() >= jwtPayload.exp * 1000) {
      return <SignIn />;
    }
  }

  return <>{children}</>;
};
