import i18n from 'i18next';
import ICU from 'i18next-icu';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // use ICU format
  // learn more: https://github.com/i18next/i18next-icu
  .use(ICU)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === 'development', // logs info level to console output
    fallbackLng: 'en', // language to use if translations in user language are not available
    nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    detection: {
      lookupQuerystring: 'lng',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    whitelist: [
      'ar', 'bg', 'ca', 'cs', 'cy', 'da', 'de', 'en', 'es', 'fi', 'fr', 'ga', 'he', 'it', 'ja',
      'ko', 'ms', 'nl', 'nn', 'nb', 'pl', 'pt', 'ru', 'sv', 'th', 'tr', 'vi', 'zh', 'mi',
    ],
    backend: {
      // points to CRA's default public folder: e.g. /locales/en/translation.json
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  });

// add locale-data on languageChanged and reinitialize

let currentLng: string;

i18n.on('languageChanged', (lng) => {
  const lngCode = lng.split('-')[0];

  if (!currentLng || currentLng !== lngCode) {
    import(`i18next-icu/locale-data/${lngCode}`)
      .then(localeData => {
        currentLng = lngCode;

        i18n.init({
          i18nFormat: { localeData },
        });
      });
  }
});

export default i18n;
