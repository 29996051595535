import { useAppRouteContext } from 'contexts/AppRouteContext';
import { useAuthorization } from 'hooks/useAuthorization';
import { ErrorPage } from 'pages/Error';
import * as React from 'react';
import { Redirect } from 'react-router-dom';

// Redirects to the first top-level non-public tab available to the user. If
// none are, shows an error message.
//
// This won't work right if a top-level route has any parameters (e.g. like a
// `:tenantId`). It's unclear what we would do in this instance anyway, since
// top-level routes are lists.

export const Home: React.FC = () => {
  const { currentLevelRoutes } = useAppRouteContext();
  const { hasPermission, loading } = useAuthorization();

  // Don't do anything until we know what permissions the user has.

  if (loading) {
    return null;
  }

  const availableRoutes = currentLevelRoutes.filter((route) => {
    // Ignore public routes and the one leading to this one.

    if (route.public || route.component === Home) {
      return false;
    }

    // Ignore routes the user's not authorized for.

    if (
      route.authorization &&
      route.authorization.some(({ permission, service }) => !hasPermission(service, permission))
    ) {
      return false;
    }

    return true;
  });

  if (availableRoutes[0]) {
    return <Redirect to={availableRoutes[0].path} />;
  }

  return <ErrorPage type="unauthorized" />;
};
