import React, { ReactNode } from 'react';
import {
  Combobox,
  ComboboxOption,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@bb-ui/react-library';
import Form from '@rjsf/material-ui';
import { IJobConfig, ListProvidersResponseBody, ProviderRegistration } from '@lct-jobs/core';
import { ISubmitEvent } from '@rjsf/core';
import useRestApi from '../../hooks/useRestApi';

import { apiUrl } from '../../utils/apiUrl';

export interface JobSubmitEvent extends ISubmitEvent<any> {
  selectedProvider?: ProviderRegistration;
}

export interface RunDialogProps {
  job: IJobConfig;
  tenantId: string;
  closeDialog: () => void;
  submitJob: (s: JobSubmitEvent) => void;
}

type ProviderComboboxOption = ComboboxOption & {
  provider?: ProviderRegistration;
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      '& div[role="radiogroup"] label span:first-child': {
        width: '32px',
        height: '32px',
        borderRadius: '16px',
        '&:hover': {
          backgroundColor: theme.palette.background.b4,
        },
      },
    },
  }),
);

export const RunDialog: React.FC<RunDialogProps> = (props) => {
  const { job } = props;
  const [open, setOpen] = React.useState<boolean>(true);
  const [providerOptions, setProviderOptions] = React.useState<ProviderComboboxOption[]>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<
    ProviderComboboxOption | undefined
  >();
  const classes = useStyles(props);
  const { loading, data } = useRestApi<ListProvidersResponseBody>(
    apiUrl('lct', `tenants/${props.tenantId}/jobManager/jobs/${job.jobId}/providers`),
  );

  React.useEffect(() => {
    if (!open) {
      props.closeDialog();
    }
  }, [open, props]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const providers: ProviderRegistration[] = Object.values(data?.providers ?? {});
    const providerOpts = providers.map((provider: ProviderRegistration) => ({
      label: provider.displayName ?? `${provider.region} ${provider.deployment ?? ''}`,
      value: provider.providerId,
      provider,
    }));
    setProviderOptions(providerOpts);
  }, [data]);

  const submitJob = (s: ISubmitEvent<any>) => {
    props.submitJob({
      ...s,
      selectedProvider: selectedProvider?.provider,
    });
  };

  return (
    <Dialog id="run-dialog" open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{job.jobName}</DialogTitle>
      <DialogContent>
        <Combobox
          floatingLabel
          id="combobox-single"
          label="Select a Provider"
          placeholder="Default Provider"
          strings={{
            announceOptionSelected(option: ComboboxOption): string {
              return `${option.label}, selected.`;
            },
            announceOptionDeselected(option: ComboboxOption): string {
              return `${option.label}, deselected.`;
            },
            announceValueCleared: 'All values deselected.',
            announceSearchResults(count: number, searchString: string): string {
              return `${count} results found for "${searchString}". Use up and down arrow keys to navigate. Press Enter to select.`;
            },
            clearButtonLabel: 'Clear',
            searchLabel: 'Search',
            noResults(searchString: string): ReactNode {
              return `No results found for "${searchString}"`;
            },
          }}
          options={providerOptions}
          onChange={setSelectedProvider}
          isLoading={loading}
          isClearable={true}
        />
        <Form
          schema={job.inputSchema}
          onSubmit={submitJob}
          uiSchema={job.inputUiSchema}
          className={classes.form}
        />
      </DialogContent>
    </Dialog>
  );
};
