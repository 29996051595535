import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, createStyles, makeStyles } from '@bb-ui/react-library/dist/components/styles';
import { CardHeader } from '@bb-ui/react-library/dist/components/CardHeader';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { List } from '@bb-ui/react-library/dist/components/List';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { PageCard } from 'components/PageCard';
import { useParams } from 'react-router-dom';
import { useRestApi } from 'hooks/useRestApi';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { apiUrl } from '../../../utils/apiUrl';
import {
  IdentityProviderAuth0Connection,
  IdentityProviderAuth0ConnectionError,
} from '../TenantIdp.types';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ConnectSamlIdpDialog } from '../../Tenant/TenantIdps/ConnectSamlIdpDialog';
import { SamlProvisionersPost } from '../../Tenant/TenantIdps/TenantIdps.context';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(2, 0, 0, 0),
    },
    cardHeader: {
      padding: theme.spacing(0, 0, 4, 0),
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    cardHeaderSecondary: {
      padding: theme.spacing(0, 0, 3, 0),
    },
    cardHeaderAction: {
      marginRight: 0,
    },
    cardHeaderActionControl: {
      marginLeft: theme.spacing(1.5),
    },
    listText: {
      paddingLeft: 0,
    },
    listTextPrimary: {
      marginBottom: theme.spacing(1),
    },
    badge: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      padding: theme.spacing(0, 1, 0),
      borderRadius: 999,
      width: 'fit-content',
      fontWeight: 'bold',
    },
    key: {
      fontFamily: 'monospace',
    },
  }),
);

export interface IdpConnectionCardProps {
  idpConnectionName?: string;
}

export const IdpConnectionCard: React.FunctionComponent<IdpConnectionCardProps> = (props) => {
  const { idpConnectionName } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { tenantId } = useParams<{ tenantId: string }>();

  const {
    data,
    error: fetchError,
    loading: loadingConnection,
    fetch,
  } = useRestApi<IdentityProviderAuth0Connection, IdentityProviderAuth0ConnectionError>(
    apiUrl('sso', `tenants/${tenantId}/authBrokerProvisioner/connections/${idpConnectionName}`),
    { manual: true },
  );

  const {
    loadingRequests,
    succeededRequests,
    clearSucceededRequests,
    failedRequests,
    clearFailedRequests,
    doPatch,
  } = useRestApi<IdentityProviderAuth0Connection, IdentityProviderAuth0ConnectionError>(
    apiUrl('sso', `tenants/${tenantId}/authBrokerProvisioner/connections`),
    { manual: true },
  );
  const [patchError, setPatchError] = React.useState<
    IdentityProviderAuth0ConnectionError | undefined
  >();
  const loading =
    loadingConnection ||
    loadingRequests ||
    succeededRequests.length > 0 || // we need to add this to account for the render between PATCH and GET
    failedRequests.length > 0; // we need to add this to account for the render between PATCH and GET

  React.useEffect(() => {
    if (idpConnectionName) {
      fetch();
    }
  }, [idpConnectionName, fetch]);

  const [editConnectionDialogOpen, setEditConnectionDialogOpen] = React.useState(false);

  const onEditAuth0Connection = (body: SamlProvisionersPost) => {
    // Find out which values have changed and transform to the different PATCH request format.
    // Ideally we would have the same format for POST and PATCH APIs, but from historical reasons, it's like this:
    const {
      displayName,
      mapping: { user_id, groups },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      authBrokerConnectionType,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      authBroker,
      ...metadata
    } = body;
    const mappingChanged =
      user_id !== data?.options.mapping?.user_id || groups !== data?.options.mapping?.groups;

    const connectionDelta: Partial<IdentityProviderAuth0Connection> = {
      ...(displayName !== data?.displayName ? { displayName } : {}),
      options: {
        ...metadata, // always patch metadata, - URL contents may have changed, file too long to compare.
        ...(mappingChanged ? { mapping: { user_id, groups } } : {}),
      },
    };
    doPatch(idpConnectionName, connectionDelta);
    setEditConnectionDialogOpen(false);
  };

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      fetch();
      clearSucceededRequests();
    }
  }, [succeededRequests, clearSucceededRequests, fetch]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      fetch();
      setPatchError(failedRequests[0].error.response?.data);
      clearFailedRequests();
    }
  }, [failedRequests, clearFailedRequests, fetch]);

  const onEditConnection = () => {
    setPatchError(undefined);
    setEditConnectionDialogOpen(true);
  };

  return (
    <PageCard data-testid="idp-connection-card">
      <CardHeader
        className={classes.cardHeaderSecondary}
        classes={{ action: classes.cardHeaderAction }}
        title={t('tenantIdp.auth0Connection.title')}
        titleTypographyProps={{ component: 'h3', variant: 'h2' } as any} // any: component expects 'span' | undefined
        action={
          data &&
          data.type === 'SAML' && (
            <IconButton className={classes.cardHeaderActionControl} onClick={onEditConnection}>
              <Edit />
            </IconButton>
          )
        }
      />
      <CardContent className={classes.cardContent}>
        {data && !loading && !patchError && (
          <List>
            <ListItem disableGutters>
              <ListItemText
                className={classes.listText}
                classes={{ primary: classes.listTextPrimary }}
                primary={t('tenantIdp.auth0Connection.name')}
                secondary={idpConnectionName}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                className={classes.listText}
                classes={{ primary: classes.listTextPrimary, secondary: classes.badge }}
                primary={t('tenantIdp.auth0Connection.type')}
                secondary={t(`idpCard.connectionType.${data.type}`)}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                className={classes.listText}
                classes={{ primary: classes.listTextPrimary }}
                primary={t('tenantIdp.auth0Connection.displayName')}
                secondary={data.displayName}
              />
            </ListItem>
            {data.options?.mapping?.user_id && (
              <ListItem disableGutters>
                <ListItemText
                  className={classes.listText}
                  classes={{ primary: classes.listTextPrimary, secondary: classes.key }}
                  primary={t('tenantIdp.auth0Connection.userAttribute')}
                  secondary={data.options.mapping.user_id}
                />
              </ListItem>
            )}
            {data.options?.mapping?.groups && (
              <ListItem disableGutters>
                <ListItemText
                  className={classes.listText}
                  classes={{ primary: classes.listTextPrimary, secondary: classes.key }}
                  primary={t('tenantIdp.auth0Connection.groupsAttribute')}
                  secondary={data.options.mapping.groups}
                />
              </ListItem>
            )}
            {data.options?.metadataUrl && (
              <ListItem disableGutters>
                <ListItemText
                  className={classes.listText}
                  classes={{ primary: classes.listTextPrimary }}
                  primary={t('tenantIdp.auth0Connection.metadataUrl')}
                  secondary={
                    <Link href={data.options.metadataUrl}>{data.options.metadataUrl}</Link>
                  }
                />
              </ListItem>
            )}
          </List>
        )}
        {loading && <LoadingIndicator data-testid="idp-connection-loading" />}
        {fetchError && (
          <ErrorMessage
            data-testid="idp-connection-fetch-error"
            title={t('global.fetchError')}
            message={fetchError.message}
            variant="block"
          />
        )}
        {patchError && !loading && (
          <ErrorMessage
            data-testid="idp-connection-patch-error"
            title={t('tenantIdp.auth0Connection.patchError')}
            message={patchError.details}
            variant="block"
          />
        )}
      </CardContent>
      {editConnectionDialogOpen && data && (
        <ConnectSamlIdpDialog
          open={true}
          connection={data}
          onClose={() => setEditConnectionDialogOpen(false)}
          onPost={onEditAuth0Connection}
        />
      )}
    </PageCard>
  );
};
