/**
 * Use setInterval with Hooks in a declarative way.
 *
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */

import * as React from 'react';

export function useInterval(callback: React.EffectCallback, delay: number | null) {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export default useInterval;
