import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';

const styles = (theme: Theme) => createStyles({
  metadataField: {
    margin: theme.spacing(2, 0, 1),
    height: 275,
  },
  metadataInput: {
    fontFamily: 'monospace',
  },
  dialogTitle: {
    '& h1': {
      minWidth: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  dialogSubtitle: {
    color: theme.palette.secondary.contrastText,
    marginBottom: theme.spacing(2),
  },
  confirmDiscardTitle: {
    marginBottom: theme.spacing(4),
    alignItems: 'start',
  },
});

export const useStyles = makeStyles(styles);

export interface TenantLicenseMetadataDialogProps extends DialogProps {
  onClose: () => void;
  onSave: (result: Record<string, any>) => void;
  licenseName: string;
  initialData: Record<string, any>;
}

export const TenantLicenseMetadataDialog: React.FunctionComponent<TenantLicenseMetadataDialogProps> = (props) => {
  const { id, open, licenseName, onClose, onSave, initialData } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);

  const initialString = React.useMemo(() => JSON.stringify(initialData, undefined, 2), [initialData]);

  const [metadataString, setMetadataString] = React.useState<string>(initialString);
  const [parseErrorMessage, setParseErrorMessage] = React.useState<string | undefined>(undefined);

  const [confirmDiscard, setConfirmDiscard] = React.useState(false);
  const closeConfirmDiscard = () => setConfirmDiscard(false);
  const discardButtonRef = React.useRef<HTMLButtonElement>(null);

  const close = () => {
    if (initialString !== metadataString) {
      setConfirmDiscard(true);
    } else {
      onClose();
    }
  };

  const checkValidity = (metadata: any | null) => {
    if (metadata === null) {
      setParseErrorMessage(t('tenantLicenses.editMetadataDialog.nullError'));
      return false;
    }

    if (typeof metadata !== 'object') {
      setParseErrorMessage(t('tenantLicenses.editMetadataDialog.nonObjectValueError'));
      return false;
    }

    for (const [key, value] of Object.entries(metadata)) {
      if (key.length === 0) {
        setParseErrorMessage(t('tenantLicenses.editMetadataDialog.invalidKeyError', { key }));
        return false;
      }

      if (typeof value !== 'string') {
        setParseErrorMessage(t('tenantLicenses.editMetadataDialog.invalidValueError', { key, valueType: typeof value }));
        return false;
      }
    }

    return true;
  };

  const save = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let result: Record<string, any>;
    try {
      result = JSON.parse(metadataString);
    } catch (e) {
      setParseErrorMessage((e as Error).message);
      return;
    }

    if (checkValidity(result)) {
      onSave(result);
      onClose();
    }
  };

  return (
    <>
      <Dialog
        id={id}
        open={open && !confirmDiscard}
        onClose={close}
        maxWidth="lg"
        aria-labelledby={`${id}-title`}
        aria-describedby={`${id}-input-label`}
      >
        <DialogTitle className={classes.dialogTitle} onClose={close} id={`${id}-title`}>{licenseName}</DialogTitle>
        <form noValidate onSubmit={save} data-testid="tenant-licenses-edit-metadata-form">
          <DialogContent>
            <TextField
              className={classes.metadataInput}
              error={!!parseErrorMessage}
              helperText={parseErrorMessage ?? t('tenantLicenses.editMetadataDialog.example')}
              id="edit-metadata-input"
              label={t('tenantLicenses.editMetadataDialog.insertJson')}
              onChange={(event) => setMetadataString(event.target.value)}
              value={metadataString}
              fullWidth
              multiline
              rows={15}
              InputProps={{ classes: { input: classes.metadataInput } }}
              InputLabelProps={{ className: classes.dialogSubtitle }}
            />
          </DialogContent>
          <DialogActions>
            <DefaultButton onClick={close}>{t('global.cancel')}</DefaultButton>
            <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        id={`${id}-confirm-discard`}
        // work around to handle keyboard focus trap correctly in discard dialog
        // TODO: remove once handled in Bb-UI Dialog component
        onEntered={() => discardButtonRef.current && discardButtonRef.current.focus()}
        onClose={closeConfirmDiscard}
        open={confirmDiscard}
        aria-labelledby={`${id}-confirm-discard-title`}
        aria-describedby={`${id}-confirm-discard-title`}
      >
        <DialogTitle id={`${id}-confirm-discard-title`} className={classes.confirmDiscardTitle} onClose={closeConfirmDiscard}>
          {t('tenantLicenses.editMetadataDialog.confirmDiscardChanges')}
        </DialogTitle>
        <DialogActions>
          <DefaultButton
            onClick={closeConfirmDiscard}
            data-testid={`${id}-resume-edit-button`}
          >
            {t('tenantLicenses.editMetadataDialog.resumeEdit')}
          </DefaultButton>
          <PrimaryButton
            ref={discardButtonRef}
            onClick={onClose}
            data-testid={`${id}-confirm-discard-button`}
          >
            {t('tenantLicenses.editMetadataDialog.discard')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantLicenseMetadataDialog;
