/**
 * Component providing reusable Search & Sort & Page table layout.
 */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { SortableTable } from '@bb-ui/react-library/dist/components/SortableTable';
import { SearchBox } from '@bb-ui/react-library/dist/components/SearchBox';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Pagination } from 'components/Pagination';
import { PaginatedTableProps } from './PaginatedTableTypes';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  searchBox: {
    maxWidth: 400,
  },
  textField: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginBottom: 0,
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
  },
  pagination: {
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  noMatchContainer: {
    display: 'flex',
    padding: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ITEMS_PER_PAGE = [10, 25, 50];

export const PaginatedTable = <T extends Record<string, any> | string | number>(
  props: PaginatedTableProps<T>,
) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    searchBoxProps,
    getSortChangedAriaMessage,
    onSortChanged,
    sortableTableProps,
    paginationProps,
    perPageProps,
    sortedData,
    noMatchesMessage = (filter: string) => t('global.paginatedTable.noMatches', { searchExpression: filter }),
    renderHead,
    renderRow,
    search,
  } = props;

  // Apply default per-page value and options
  const { value: perPageInitial, options: perPageOptions } = {
    value: ITEMS_PER_PAGE[0],
    options: ITEMS_PER_PAGE,
    ...perPageProps,
  };

  // Pagination state and methods
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(perPageInitial);

  function resetPage() {
    if (page > 0) {
      setPage(0);
    }
  }

  function handlePerPageOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    resetPage();
    setItemsPerPage(Number(event.target.value));
  }

  // Filtering state and methods
  const [filter, setFilter] = React.useState('');

  const handleSearch = debounce(
    (value: string) => {
      resetPage();
      setFilter(value);
    },
    250,
    { maxWait: 500 },
  );

  const filteredSortedData = React.useMemo(
    () => (search && filter.length > 0 ? sortedData.filter(search(filter)) : sortedData),
    [filter, search, sortedData],
  );

  return (
    <>
      <div className={classes.toolbar}>
        {search && (
          <>
            <SearchBox
              className={classes.searchBox}
              label={t('global.paginatedTable.searchAriaLabel')}
              inputId="paginated-table-search-input"
              {...searchBoxProps}
              onChange={handleSearch}
            />
          </>
        )}
        <TextField
          select
          className={classes.textField}
          id="paginated-table-items-per-page"
          label={t('global.itemsPerPage')}
          value={itemsPerPage}
          onChange={handlePerPageOnChange}
          SelectProps={{ native: true }}
          InputLabelProps={{ className: classes.label }}
          data-testid="paginated-table-items-per-page"
        >
          {perPageOptions.map((perPage) => (
            <option key={perPage} value={perPage}>
              {String(perPage)}
            </option>
          ))}
        </TextField>
      </div>
      {filteredSortedData.length > 0 ? (
        <SortableTable
          {...sortableTableProps}
          id="paginated-table-sortable-table"
          getAriaSortMessage={getSortChangedAriaMessage}
          onSortChanged={onSortChanged}
        >
          {renderHead()}
          <TableBody>
            {filteredSortedData
              .slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage)
              .map(renderRow)}
          </TableBody>
        </SortableTable>
      ) : (
        <div
          role="status"
          className={classes.noMatchContainer}
          data-testid="paginated-table-no-match-message"
        >
          <Typography>
            {sortedData.length > 0 ? noMatchesMessage(filter) : t('global.paginatedTable.noData')}
          </Typography>
        </div>
      )}
      <Pagination
        id="paginated-table-pagination"
        className={classes.pagination}
        page={page}
        count={filteredSortedData.length}
        itemsPerPage={itemsPerPage}
        onChangePage={setPage}
        previousButtonLabel={t('global.pagination.previousButtonAriaLabel')}
        nextButtonLabel={t('global.pagination.nextButtonAriaLabel')}
        pageSelectLabel={t('global.pagination.pageSelectLabel')}
        pageSelectAriaLabel={({ page, pages }: { page: number; pages: number }) => t('global.pagination.pageSelectAriaLabel', { page, pages })
        }
        ofPages={({ pages }: { pages: number }) => t('global.pagination.ofPages', { pages })}
        {...paginationProps}
      />
    </>
  );
};

export default PaginatedTable;
