import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Picker, PickerLiveRegion, PickerProps } from '@bb-ui/react-library/dist/components/Picker';

export const PickerI18n: React.FunctionComponent<PickerProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { theme, ...other } = props;
  const { t } = useTranslation();

  return (
    <Picker
      ariaLiveMessages={{
        inputFocus: ({ label }) => t('global.pickerComponent.inputFocus', { label }),
        inputFocusIsSearchable: t('global.pickerComponent.inputFocusIsSearchable'),
        inputFocusIsMulti: t('global.pickerComponent.inputFocusIsMulti'),
        tagInstructions: t('global.pickerComponent.tagInstructions'),
        tagFocus: ({ label, current, total }) => t('global.pickerComponent.tagFocus', { label, current, total }),
        tagRemove: ({ label }) => t('global.pickerComponent.tagRemove', { label }),
        tagCreate: ({ label }) => t('global.pickerComponent.tagCreate', { label }),
        menuInstructions: t('global.pickerComponent.menuInstructions'),
        optionFocus: ({ label, current, total }) => t('global.pickerComponent.optionFocus', { label, current, total }),
        optionSelect: ({ label }) => t('global.pickerComponent.optionSelect', { label }),
        optionsAvailable: ({ count }) => t('global.pickerComponent.optionsAvailable', { count }),
        searchResults: ({ count, value }) => t('global.pickerComponent.searchResults', { count, value }),
        searchResultsIsCreatable: ({ count, value }) => t('global.pickerComponent.searchResultsIsCreatable', { count, value }),
      } as PickerLiveRegion}
      {...other}
    />
  );
};

export default PickerI18n;
