/**
 * Use FileReader with Hooks in a declarative way.
 */
import * as React from 'react';

export interface ReadOptions {
  /**
   * Method name to be used for reading the file
   */
  method?: keyof Pick<FileReader, 'readAsArrayBuffer' | 'readAsBinaryString' | 'readAsDataURL' | 'readAsText'>;
}

export const useFileReader = (file?: File, options?: ReadOptions) => {
  const { method = 'readAsText' } = options ?? {};
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<DOMException | null>(null);
  const [data, setData] = React.useState<string | ArrayBuffer | null>(null);

  const fileReaderRef = React.useRef<FileReader>();

  React.useEffect(() => {
    // abort last read to not have two conflicting reads
    if (fileReaderRef.current && fileReaderRef.current.readyState === FileReader.LOADING) {
      fileReaderRef.current.abort();
    }

    const fileReader = new FileReader();
    fileReaderRef.current = fileReader;
    setData(null);
    setError(null);

    if (file) {
      fileReader.onloadend = () => {
        setLoading(false);
        setData(fileReader.result);
      };
      fileReader.onerror = () => {
        setLoading(false);
        setError(fileReader.error);
      };

      // start reading the file
      setLoading(true);
      fileReader[method](file);
    }
  }, [file, method]);

  return { data, error, loading };
};

export default useFileReader;
