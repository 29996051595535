import {
  FeatureFlagDefinitionData,
  FeatureFlagVisibility,
  IFeatureFlagValueData,
  TenantFeatureFlagsUtils,
} from '@bb-config-ui/feature-flags';
import {
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  PrimaryButton,
  Radio,
  RadioGroup,
  Typography,
} from '@bb-ui/react-library';
import { FieldsetProps, LegendProps } from '@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TenantData } from 'App.types';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  editHeadingText: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    alignItems: 'start',
  },
  radioButton: {
    marginLeft: theme.spacing(-1),
  },
}));

export interface ITenantFeatureFlagEditDialogProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  tenant: Partial<TenantData>;
  featureFlagName: string;
  flagDefinition: FeatureFlagDefinitionData;
  flagValue?: IFeatureFlagValueData;
  updateFeatureFlagValue: (flagKey: string, value?: string, locked?: boolean) => void;
  updateFeatureFlagDefinition: (flagKey: string, defaultValue?: string, visibility?: FeatureFlagVisibility) => void;
}

export const TenantFeatureFlagDialog: React.FC<ITenantFeatureFlagEditDialogProps> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const { isDialogOpen, dialogToggle, featureFlagName, tenant, flagDefinition, flagValue, updateFeatureFlagValue, updateFeatureFlagDefinition } = props;
  const tenantId = tenant.id;
  const { isVisible: initialIsVisible, visibilityModifiable } = TenantFeatureFlagsUtils.getVisibility(flagDefinition.visibility, tenantId!, tenant.region!, tenant.fleetId);
  const initialFlagValue = flagValue?.value ?? flagDefinition.defaultValue;
  const initialLockedValue = flagValue?.locked ?? false;
  const [currentFlagValue, setValue] = React.useState<string>(initialFlagValue);
  const [currentLockedValue, setCurrentLockedValue] = React.useState<boolean>(initialLockedValue);
  const [currentIsVisible, setCurrentIsVisible] = React.useState<boolean>(initialIsVisible);

  React.useEffect(() => {
    setValue(flagValue?.value ?? flagDefinition.defaultValue);
    setCurrentLockedValue(flagValue?.locked ?? false);
    const { isVisible } = TenantFeatureFlagsUtils.getVisibility(flagDefinition.visibility, tenant.id!, tenant.region!);
    setCurrentIsVisible(isVisible);
  }, [flagDefinition, flagValue, tenant]);

  function handleOnClose() {
    dialogToggle(false);
  }

  function handleOnChangeFlagStatus(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handleOnChangeAdminPermissions(event: React.ChangeEvent<HTMLInputElement>) {
    switch (event.target.value) {
      case "None": {
        setCurrentIsVisible(false);
        break;
      }
      case "CanView": {
        setCurrentLockedValue(true);
        setCurrentIsVisible(true);
        break;
      }
      case "CanEdit": {
        setCurrentLockedValue(false);
        setCurrentIsVisible(true);
        break;
      }
      default: {
        throw new Error(`Unexpected target value ${event.target.value}`);
      }
    }
  }

  function handleOnSave() {
    if (currentFlagValue !== initialFlagValue || currentLockedValue !== initialLockedValue) {
      updateFeatureFlagValue(flagDefinition.flagKey, currentFlagValue, currentLockedValue);
    }
    if (initialIsVisible !== currentIsVisible) {
      const newVisibility: FeatureFlagVisibility = TenantFeatureFlagsUtils.getNewTenantVisibility(flagDefinition.visibility, tenantId!, currentIsVisible);
      updateFeatureFlagDefinition(flagDefinition.flagKey, flagDefinition.defaultValue, newVisibility);
    }
    dialogToggle(false);
  }
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      data-testid="tenant-feature-flag-dialogue"
    >
      <DialogTitle className={classes.dialogTitle} onClose={handleOnClose} id="tenant-ff-dialog-title">
        {t('tenantFeatureFlags.tenantFeatureFlagTitleDialog', { featureFlagName, tenantName: tenant?.name })}
      </DialogTitle>
      <DialogContent id="tenant-feature-flag-dialogue-content">
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <Typography className={classes.editHeadingText} variant="h4"
            id="tenant-feature-flag-dialogue-flag-status-subtitle"
          >
            {t('tenantFeatureFlags.featureStatus')}
          </Typography>
          <RadioGroup
            hasCustomLegend
            id="tenant-feature-flag-dialogue-flag-status-options"
            name="flag-edit-status"
            onChange={handleOnChangeFlagStatus}
          >
            <FormControlLabel className={classes.radioButton} checked={currentFlagValue === "false"} value="false" control={<Radio />} label={t('global.off')} />
            <FormControlLabel className={classes.radioButton} checked={currentFlagValue === "true"} value="true" control={<Radio />} label={t('global.on')} />
          </RadioGroup>
        </Fieldset>
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <Typography className={classes.editHeadingText} variant="h4"
            id="tenant-feature-flag-dialogue-permissions-subtitle"
          >
            {t('featureFlagGeneral.clientAdminPermissions')}
          </Typography>
          <RadioGroup
            hasCustomLegend
            id="tenant-feature-flag-dialogue-permissions-options"
            name="flag-edit-status"
            onChange={handleOnChangeAdminPermissions}
          >
            <FormControlLabel
              className={classes.radioButton}
              disabled={!visibilityModifiable}
              checked={!currentIsVisible}
              value="None"
              control={<Radio />}
              label={t('featureFlagGeneral.none')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentIsVisible && currentLockedValue}
              value="CanView"
              control={<Radio />}
              label={t('featureFlagGeneral.canView')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentIsVisible && !currentLockedValue}
              value="CanEdit"
              control={<Radio />}
              label={t('featureFlagGeneral.canEdit')}
            />
          </RadioGroup>
        </Fieldset>
      </DialogContent>
      <DialogActions id="tenant-feature-flag-dialogue-actions">
        <DefaultButton id="tenant-feature-flag-dialogue-cancel-btn" onClick={handleOnClose}>{t('global.cancel')}</DefaultButton>
        <PrimaryButton id="tenant-feature-flag-dialogue-submit-btn"
          onClick={handleOnSave}
        >
          {t('global.save')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
