import * as React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Theme,
} from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';

export interface SiteInformationItemProps {
  title: string;
  value?: string | number;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    border: 'none',
    display: 'grid',
    gridSpacing: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '1px',
  },
  cardContent: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSizeSmall,
    textAlign: 'right',
  },
  cardHeader: {
    fontSize: theme.typography.fontSizeSmall,
    fontWeight: theme.typography.fontWeightBold,
  },
  unsetValue: {
    fontStyle: 'italic',
  },
}));

export const LearnSiteInformationItem: React.FC<SiteInformationItemProps> = (props) => {
  const { title, value } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={title} data-testid="learn-site-info-item-title" />
      <CardContent className={classes.cardContent} data-testid="learn-site-info-item-content">
        {value ?? <span className={classes.unsetValue}>{t('learnSiteInformation.unset')}</span>}
      </CardContent>
    </Card>
  );
};
