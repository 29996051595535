import React from 'react';
import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Divider,
  CardHeader,
  CardContent,
  Skeleton,
} from '@bb-ui/react-library';

export interface JobCardListProps {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  headerAction?: React.ReactNode;
  loading?: boolean;
}

export const JobCardList: React.FC<JobCardListProps> = (props) => (
  <Box>
    <Card raised>
      <CardHeader title={props.title} action={props.headerAction} subheader={props.subtitle} />
      <CardContent>
        <List>
          {props.loading ? (
            <div>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </div>
          ) : (
            props.children
          )}
        </List>
        {props.actions}
      </CardContent>
    </Card>
  </Box>
);
