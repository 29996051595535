import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogHeader: {
    minHeight: theme.spacing(60),
    textAlign: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginTop: theme.spacing(1),
  },
}));

export interface FeatureFlagValuesFollowupDialogProps {
  isDialogOpen: boolean;
}

export const FeatureFlagValuesFollowupDialog: React.FC<FeatureFlagValuesFollowupDialogProps> = (props) => {
  const classes = useStyles(props);
  const { isDialogOpen } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={isDialogOpen}
      data-testid="feature-flags-values-followup-dialog"
    >
      <DialogTitle className={classes.dialogHeader} hideCloseButton id="feature-flags-region-values-followup-dialog-title">
        <>
          <Typography variant="h2" component="p" gutterBottom>
            {t('featureFlagDefinitionEditGeneral.updateRecordsLoadingDescription')}
          </Typography>

          <Typography variant="h2" component="p" gutterBottom>
            {t('global.pleaseWait')}
          </Typography>
        </>
        <>
          <CircularProgress
            id="feature-flags-region-values-followup-dialog-spinner"
            className={classes.spinner}
            ariaLabel={t('featureFlagDefinitionRegions.circularProgress') ?? ''}
            size="xlarge"
          />
        </>
      </DialogTitle>
    </Dialog>
  );
};
