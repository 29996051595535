import { JSONSchema7 } from 'json-schema';

export const schemaTemplate: JSONSchema7 = {
  description:
    'This domain lacks a predefined schema. The provided template serves as a basis for creating a custom configuration schema.',
  type: 'object',
  properties: {
    property1: {
      type: 'string',
      default: '',
    },
    property2: {
      type: 'number',
      default: 0,
    },
    property3: {
      type: 'boolean',
      default: false,
    },
  },
  required: [],
};

export const uiSchemaTemplate = {
  property1: {
    'ui:placeholder': 'text',
  },
  property2: {
    'ui:description': 'number',
  },
  property3: {
    'ui:help': 'checkbox',
  },
};
