import { PrimaryButton } from '@bb-ui/react-library';
import React from 'react';
import { Refresh } from '@bb-ui/icons/dist/medium';
import { useConfigurationContext } from 'contexts/ConfigurationContext';

export const ConfigurationRefreshButton: React.FC = () => {
  const { fetchAll } = useConfigurationContext();

  return (
    <PrimaryButton onClick={fetchAll} startIcon={<Refresh />}>
      Refresh
    </PrimaryButton>
  );
};
