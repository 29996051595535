// Mobile config landing page
import React, { useState } from 'react';
import { Typography, PrimaryButton, createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageCard } from 'components/PageCard';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { CardHeader } from '@bb-ui/react-library/dist/components/CardHeader';
import { useTenantContext } from 'contexts/TenantContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { MobileConfigurationDetails } from './MobileConfigurationDetails';
import { urlPattern } from './MobileConstants';
import { MobileDetails, useTenantMobile } from './useTenantMobile';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeaderSecondary: {
      padding: theme.spacing(0, 0, 3, 0),
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    cardHeaderAction: {
      marginRight: 0,
    },
    cardHeaderActionControl: {
      marginRight: theme.spacing(1.5),
    },
  }),
);

export const MobileConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const tenantId = tenant?.id;

  const { enqueueSnackbar } = useSnackbar();

  const {
    doPatch,
    doPost,
    doDelete,
    data: dataMobile,
    error: errorMobile,
    loading: loadingMobile,
    isSnackbarVisible,
    setIsSnackbarVisible,
  } = useTenantMobile({
    tenantId,
  });
  const classes = useStyles();

  const [isConfigUpdated, setIsConfigUpdated] = useState(true);
  const [newChildrenInfoArray, setNewChildrenInfoArray] = useState<MobileDetails[]>([]);
  const [existingChildrenInfoArray, setExistingChildrenInfoArray] = useState<MobileDetails[]>([]);
  const [deleteChildrenInfoArray, setDeleteChildrenInfoArray] = useState<MobileDetails[]>([]);

  // Filter items based on the condition "domain": "mobile"
  const filteredMobileDetails = dataMobile?.items.filter((item) => item.domain === 'mobile');

  // For parent details
  const [updatedDetails, setUpdatedDetails] = useState<MobileDetails | undefined>(undefined);
  const [isModifiedFields, setIsModifiedFields] = useState(false);

  const updateParentMobileDetails = (newMobileDetails: MobileDetails, isModified: boolean) => {
    setIsModifiedFields(isModified);
    if (isModified) {
      setIsConfigUpdated(false);
      setUpdatedDetails(newMobileDetails);
    }
  };

  React.useEffect(() => {
    if (loadingMobile) {
      // Reset the state of arrays
      setNewChildrenInfoArray([]);
      setExistingChildrenInfoArray([]);
      setDeleteChildrenInfoArray([]);
      setIsModifiedFields(false);
      setIsConfigUpdated(true);
    }
  }, [loadingMobile]);

  // callback to get the child details which are edited and update the array to be sent to the API
  const updateMobileDetailsArray = (
    setArray: React.Dispatch<React.SetStateAction<MobileDetails[]>>,
    newMobileDetails: MobileDetails,
    counter: number,
    deleteChild: boolean,
  ) => {
    setArray((prevChildrenInfoArray) => {
      const updatedArray = [...prevChildrenInfoArray];
      const existingIndex = updatedArray.findIndex(
        (item) => item.domain === newMobileDetails.domain,
      );

      if (existingIndex !== -1) {
        // If the element with the same identifier exists, update it
        if (deleteChild) {
          // If deleteChild is true, remove the element from the array
          updatedArray.splice(existingIndex, 1);
        } else {
          updatedArray[existingIndex] = newMobileDetails;
        }
      } else if (!deleteChild) {
        // If the element with the same identifier doesn't exist, insert it at the specified counter
        updatedArray.splice(counter, 0, newMobileDetails);
      }
      setIsConfigUpdated(false);
      return updatedArray;
    });
  };

  // delete filter
  const deleteChildrenArray = (counter: number, newMobileDetails: MobileDetails) => {
    setDeleteChildrenInfoArray((prevDeleteChildrenInfoArray) => {
      const updatedArray = prevDeleteChildrenInfoArray ? [...prevDeleteChildrenInfoArray] : [];

      // Insert the newMobileDetails at the specified counter
      updatedArray.splice(counter, 0, newMobileDetails);

      setIsConfigUpdated(false);
      return updatedArray;
    });
  };

  // callback to get the child details which are edited and update the array to be sent to the API
  const updateChildMobileDetails = (
    newMobileDetails: MobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => {
    if (newChild) {
      // If the child is new, add it to the newChildrenInfoArray
      updateMobileDetailsArray(setNewChildrenInfoArray, newMobileDetails, counter, deleteChild);
    } else {
      // If the child is existing and if it is modified, add it to the existingChildrenInfoArray
      updateMobileDetailsArray(
        setExistingChildrenInfoArray,
        newMobileDetails,
        counter,
        deleteChild,
      );
      if (deleteChild) {
        deleteChildrenArray(counter, newMobileDetails);
      }
    }
  };

  const isSaveButtonDisabled = () => {
    const errorChilds = existingChildrenInfoArray
      .concat(newChildrenInfoArray)
      .filter(
        (child) =>
          child.configuration.name.trim().length === 0 ||
          child.configuration.name.trim().length > 1000 ||
          child.configuration.userName.trim().length > 1000 ||
          !urlPattern.test(child.configuration.b2Url),
      );
    return !!(
      errorChilds.length > 0 ||
      (updatedDetails && updatedDetails.configuration.notes.trim().length > 1000)
    );
  };

  let mobileContent = <LoadingIndicator data-testid="loading-indicator" />;

  function save() {
    // Update children
    existingChildrenInfoArray.forEach((item) => {
      const childConfiguration = { configuration: item.configuration };
      doPatch(item.domain, childConfiguration);
    });

    // Add new children
    newChildrenInfoArray.forEach((item) => {
      const childConfiguration = { configuration: item.configuration, domain: item.domain };
      doPost(childConfiguration);
    });

    // update the parent details
    if (isModifiedFields) {
      const parentConfiguration = { configuration: updatedDetails?.configuration };
      doPatch('mobile', parentConfiguration);
    }

    // delete Childs
    deleteChildrenInfoArray.forEach((item) => {
      doDelete(item.domain);
    });

    if (isConfigUpdated) {
      enqueueSnackbar('Configuration details updated.', { variant: 'info' });
    } else if (isSnackbarVisible) {
      // reset snackbar state
      setIsSnackbarVisible(false);
    }
  }

  // If the data is loaded and there are no errors, render the page
  if (!loadingMobile && (filteredMobileDetails?.length ?? 0) > 0) {
    mobileContent = (
      <PageCard data-testid="mobile-display-card">
        <>
          <CardHeader
            className={classes.cardHeaderSecondary}
            title={filteredMobileDetails?.[0]?.configuration.name}
            titleTypographyProps={{ component: 'h3', variant: 'h2', color: 'textPrimary' } as any}
            data-testid="school-name"
            action={
              <>
                <PrimaryButton
                  onClick={() => save()}
                  className={classes.cardHeaderActionControl}
                  data-testid="save-display-edit"
                  disabled={isSaveButtonDisabled()}
                >
                  {t('mobileConfiguration.save')}
                </PrimaryButton>
              </>
            }
          />
          <CardContent>
            <MobileConfigurationDetails
              updateMobileDetails={updateParentMobileDetails}
              updateChildDetails={updateChildMobileDetails}
              mobileprops={dataMobile}
            />
          </CardContent>
        </>
      </PageCard>
    );
  } else if (!loadingMobile && errorMobile) {
    // If the data is loaded and there are errors, render the error message
    mobileContent = (
      <Typography data-testid="mobile-page-loading-error">{errorMobile.message}</Typography>
    );
  } else if (!loadingMobile && (filteredMobileDetails?.length ?? 0) === 0) {
    // If the data is loaded and it is empty and there are no errors, render the error message
    mobileContent = (
      <Typography data-testid="mobile-page-loading-error">
        A mobile tenant for this account does not exist. Check the information and try again.
      </Typography>
    );
  }

  return <>{mobileContent}</>;
};
