import * as React from 'react';
import { Theme, createStyles, makeStyles } from '@bb-ui/react-library/dist/components/styles';
import { useTranslation } from 'react-i18next';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { Radio } from '@bb-ui/react-library/dist/components/Radio';
import { RadioGroup } from '@bb-ui/react-library/dist/components/RadioGroup';
import { FormLabel } from '@bb-ui/react-library/dist/components/FormLabel';
import { FormControl } from '@bb-ui/react-library/dist/components/FormControl';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { FormControlProps } from '@material-ui/core/FormControl';
import { FormLabelProps } from '@material-ui/core/FormLabel';
import { useTenantContext } from 'contexts/TenantContext';
import { LearnConnectorProvisionersPost } from './TenantIdps.context';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  radioGroup: {
    paddingLeft: theme.spacing(0.5),
  },
  select: {
    margin: theme.spacing(3, 0),
  },
  selectInput: {
    paddingLeft: theme.spacing(1),
  },
}));

export interface ConnectLearnIdpsDialogProps extends DialogProps {
  onClose: () => void;
  onPost: (body: LearnConnectorProvisionersPost) => void;
}

export interface FieldsetProps extends FormControlProps {
  component?: 'fieldset';
}

export interface LegendProps extends FormLabelProps {
  component?: 'legend';
}

export const ConnectLearnIdpsDialog: React.FunctionComponent<ConnectLearnIdpsDialogProps> = (props) => {
  const { id, open, onClose, onPost } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const [connectionType, setConnectionType] = React.useState<LearnConnectorProvisionersPost['authBrokerConnectionType'] | undefined>('LearnConnector');

  function handleConnectionTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    setConnectionType(event.target.value as LearnConnectorProvisionersPost['authBrokerConnectionType']);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (!connectionType) {
      return;
    }

    onPost({
      hostname: tenant!.hostname!,
      authBroker: 'Auth0',
      authBrokerConnectionType: connectionType,
      userAttribute: '$.user_id',
    });
  }

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      data-testid="connect-idps-dialog"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle onClose={onClose} id={`${id}-title`}>{t('tenantConnectLearnIdpsDialog.title')}</DialogTitle>
        <DialogContent>
          <Fieldset component="fieldset">
            <Legend component="legend">{t('tenantConnectLearnIdpsDialog.connectionType')}</Legend>
            <RadioGroup
              hasCustomLegend
              className={classes.radioGroup}
              onChange={handleConnectionTypeChange}
              value={connectionType}
              aria-label={t('tenantConnectLearnIdpsDialog.connectionType')}
              name="connectionType"
            >
              <FormControlLabel value="LearnConnector" control={<Radio />} label={t('tenantConnectLearnIdpsDialog.fndsOnly')} />
              <FormControlLabel value="LearnConnectorFullAccess" control={<Radio />} disabled label={t('tenantConnectLearnIdpsDialog.fndsAndLearn')} />
            </RadioGroup>
          </Fieldset>
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid={`cancel-${id}`} onClick={onClose}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton data-testid={`submit-${id}`} disabled={!connectionType} onClick={handleSubmit} type="submit">{t('tenantConnectLearnIdpsDialog.startConnection')}</PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConnectLearnIdpsDialog;
