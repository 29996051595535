// add or view descendants view with name, b2 url textfields and toggle button
import React, { useState, useEffect, useCallback } from 'react';
import {
  CardHeader,
  CardContent,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@bb-ui/react-library';
import { Trash } from '@bb-ui/icons/dist/small/Trash';
import { Attention } from '@bb-ui/icons/dist/small/Attention';
import { useTranslation } from 'react-i18next';
import { Toggle } from './Toggle';
import { urlPattern } from './MobileConstants';
import { MobileDetails, MobileDetailsResponse } from './useTenantMobile';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  descendantHeaderSecondary: {
    display: 'inline-flex',
    padding: theme.spacing(5, 0, 3, 0),
    marginRight: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  leftCardContent: {
    padding: theme.spacing(0),
    flex: 1,
  },
  rightCardContent: {
    padding: theme.spacing(0),
    flex: 1,
  },
  cardRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputField: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'justify',
    padding: theme.spacing(0, 0, 2, 0),
  },
  errorDisplay: {
    color: theme.palette.error.main,
    fontStyle: 'italic',
  },
  trashIcon: {
    cursor: 'pointer',
  },
  attentionIcon: {
    margin: theme.spacing(0, 0.5, 0, 0),
    padding: theme.spacing(0, 0.4, 0, 0),
  },
}));

interface Props {
  counter: number;
  mobileprops?: MobileDetails;
  mobileResponse?: MobileDetailsResponse;
  updateChildDetails: (
    newMobileChildDetails: MobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => void;
}

export const Descendant: React.FC<Props> = ({
  counter,
  mobileprops,
  mobileResponse,
  updateChildDetails,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // handle the visibility of the descendant for delete functionality
  const [isVisible, setIsVisible] = useState(true);

  // This is used to filter the item objects which are updated
  const [isModifiedFields, setIsModifiedFields] = useState(false);

  // Filter items based on the condition "domain"
  const filteredMobileDetails = mobileResponse?.items.filter((item) => item.domain === 'mobile');
  const filteredMobileChildDetails = mobileResponse?.items.filter(
    (item) => item.domain !== 'mobile',
  );
  // Get the first item from the filtered array to display new child details
  const [mobileChildDetails] = useState<MobileDetails | undefined>(
    filteredMobileChildDetails && filteredMobileChildDetails.length > 0 ?
      filteredMobileChildDetails?.[0] :
      filteredMobileDetails?.[0],
  );
  const [schoolDetails] = useState<MobileDetails | undefined>(mobileprops);
  const [fields, setFields] = useState({
    name: schoolDetails?.configuration.name || '',
    b2Url: schoolDetails?.configuration.b2Url || '',
    userName: schoolDetails?.configuration.userName || '',
  });
  const [forceWebLogin, setForceWebLogin] = useState<string>(
    schoolDetails?.configuration.forceWebLogin || '0',
  );

  // Check length of the name
  const isValidName = (name: string) => {
    if (name.trim().length === 0) {
      return t('mobileConfiguration.childNameEmptyError');
    }
    if (name.trim().length > 1000) {
      return t('mobileConfiguration.textLimitError');
    }
    return '';
  };

  // Check if the URL is valid
  const isValidUrl = (url: string) => {
    if (!urlPattern.test(url)) {
      return t('mobileConfiguration.validUrlError');
    }
    return '';
  };

  // handle the helperText for the name, b2Url and userName fields
  const [showErrors, setShowErrors] = useState({ name: true, b2Url: true, userName: false });
  const helperTextForName = isValidName(fields.name);
  const helperTextForValidUrl = isValidUrl(fields.b2Url);
  const helperTextForUserName =
    fields.userName.trim().length > 1000 ? t('mobileConfiguration.textLimitError') : '';

  // Update the name, b2 url and username fields
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [event.target.name]:
        event.target.value.length <= 1000 ? event.target.value : event.target.value.slice(0, 1001),
    });
    if (event.target.name === 'name') {
      setShowErrors({
        ...showErrors,
        name: event.target.value.trim().length === 0 || event.target.value.length > 1000,
      });
    } else if (event.target.name === 'b2Url') {
      setShowErrors({
        ...showErrors,
        b2Url: !urlPattern.test(event.target.value) || event.target.value.trim().length === 0,
      });
    } else if (event.target.name === 'userName') {
      setShowErrors({
        ...showErrors,
        userName: event.target.value.length > 1000,
      });
    }
    setIsModifiedFields(true);
  };

  // Get the toggle value of newly added descendant
  const addDescendantToggleUpdate = (newToggleValue: string) => {
    setForceWebLogin(newToggleValue);
  };

  // Get the toggle value of existing descendant
  const updateParentMobileDetails = (forceWebLogin: string) => {
    setForceWebLogin(forceWebLogin);
    setIsModifiedFields(true);
  };

  // login url
  const loginUrl = (url: string) => {
    if (url.endsWith('/')) {
      // If it ends with a slash, just append 'customAuthDevice'
      return `${url}customAuthDevice`;
    }
    // If it doesn't end with a slash, append '/customAuthDevice'
    return `${url}/customAuthDevice`;
  };

  const getUpdatedDetails = useCallback(
    (details: any, newChild: boolean) => ({
      ...details,
      configuration: {
        ...details?.configuration,
        // Update specific fields within the configuration
        name: fields.name,
        b2Url: fields.b2Url,
        userName: fields.userName,
        loginUrl: loginUrl(fields.b2Url),
        forceWebLogin,
      },
      domain: newChild ? `mobilechild-${counter}` : details.domain,
    }),
    [fields.name, fields.b2Url, fields.userName, forceWebLogin, counter],
  );

  useEffect(() => {
    if (mobileChildDetails) {
      // new child details
      const updatedMobileChildDetails = getUpdatedDetails(mobileChildDetails, true);
      updateChildDetails(updatedMobileChildDetails, counter, true, false);
    } else if (schoolDetails && isModifiedFields) {
      // modified existing childs
      const updatedMobileDetails = getUpdatedDetails(schoolDetails, false);
      updateChildDetails(updatedMobileDetails, counter, false, false);
    }
  }, [
    mobileChildDetails,
    schoolDetails,
    isModifiedFields,
    counter,
    getUpdatedDetails,
    updateChildDetails,
  ]);

  // Delete functionality
  const handleDelete = () => {
    const updatedMobileDetails = getUpdatedDetails(
      schoolDetails || mobileChildDetails,
      !schoolDetails,
    );
    updateChildDetails(updatedMobileDetails, counter, !schoolDetails, true);
    setIsVisible(false);
  };
  if (!isVisible) {
    return null;
  }
  return (
    <>
      <CardHeader
        className={classes.descendantHeaderSecondary}
        data-testid="new-descendant"
        title={`Descendant: ${counter}`}
        titleTypographyProps={{ component: 'h3', variant: 'h2', color: 'textPrimary' } as any}
      />
      <Trash
        className={classes.trashIcon}
        data-testid={`trash-icon-${counter}`}
        onClick={handleDelete}
      />
      <div className={classes.cardContent} data-testid="descendant">
        <CardContent className={classes.rightCardContent}>
          <div className={classes.inputField}>
            <TextField
              name="name"
              helperText={
                <Typography variant="caption" className={classes.errorDisplay}>
                  {(helperTextForName.length !== 0 || fields.name.trim().length === 0) && (
                    <Attention
                      className={classes.attentionIcon}
                      data-testid={`child-name-error-icon-${counter}`}
                    />
                  )}
                  {showErrors.name && helperTextForName}
                </Typography>
              }
              label={t('mobileConfiguration.name')}
              value={fields.name}
              onChange={handleFieldChange}
              fullWidth
              data-testid="name"
            />
          </div>
          <Toggle
            isFromChildren={true}
            data-testid="toggle-switch"
            mobileProps={schoolDetails}
            updateMobileDetails={updateParentMobileDetails}
            updateToggleValue={addDescendantToggleUpdate}
          />
        </CardContent>
        <CardContent className={classes.leftCardContent}>
          <div className={classes.cardRow}>
            <div className={classes.inputField}>
              <TextField
                name="b2Url"
                helperText={
                  <Typography variant="caption" className={classes.errorDisplay}>
                    {(helperTextForValidUrl.length !== 0 || fields.b2Url.trim().length === 0) && (
                      <Attention
                        className={classes.attentionIcon}
                        data-testid={`b2url-error-icon-${counter}`}
                      />
                    )}
                    {showErrors.b2Url && helperTextForValidUrl}
                  </Typography>
                }
                label={t('mobileConfiguration.b2Url')}
                value={fields.b2Url}
                onChange={handleFieldChange}
                fullWidth
                data-testid="b2-url"
              />
            </div>
            <div className={classes.inputField}>
              <TextField
                name="userName"
                helperText={
                  <Typography variant="caption" className={classes.errorDisplay}>
                    {helperTextForUserName.length !== 0 && (
                      <Attention
                        className={classes.attentionIcon}
                        data-testid={`username-error-icon-${counter}`}
                      />
                    )}
                    {showErrors.userName && helperTextForUserName}
                  </Typography>
                }
                label={t('mobileConfiguration.userName')}
                value={fields.userName}
                onChange={handleFieldChange}
                fullWidth
                data-testid="user-name"
              />
            </div>
          </div>
        </CardContent>
      </div>
    </>
  );
};
