import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  createStyles,
  makeStyles,
  Card,
  PrimaryButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  DefaultButton,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useSnackbar } from 'hooks/useSnackbar';
import Form from '@rjsf/material-ui';
import { ControlledEditor } from '@monaco-editor/react';

const defaultSchemaDescription =
  'Default Schema. This is a key value map that allows any property name to map to any string. Tightening of the schema is highly recommended';

const schemaTemplate = {
  description:
    'This domain lacks a predefined schema. The provided template serves as a basis for creating a custom configuration schema.',
  type: 'object',
  properties: {
    property1: {
      type: 'string',
      default: '',
    },
    property2: {
      type: 'number',
      default: 0,
    },
    property3: {
      type: 'boolean',
      default: false,
    },
  },
  required: [],
};

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialogTitle: {
      '&.bb-dialog-title': {
        borderBottom: 'none',
        paddingBottom: `${theme.spacing(1)}px`,
      },
    },
    editorTitle: {
      paddingTop: `${theme.spacing(1)}px`,
      paddingLeft: `${theme.spacing(1)}px`,
      paddingBottom: `${theme.spacing(1)}px`,
    },
    dialogDescription: {
      '&.bb-dialog-content': {
        paddingTop: 0,
        minHeight: '100%',
        paddingBottom: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    dialogContent: {
      '&.bb-dialog-content': {
        padding: 0,
        display: 'flex',
        overflow: 'hidden',
        minHeight: '15rem',
        justifyContent: 'center',
      },
    },
    schemaEditorContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '20px',
      flex: 1,
    },
    livePreviewContainer: {
      width: '50%',
      minHeight: '15rem',
      height: 'fit-content',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-end',
      padding: theme.spacing(5),
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.b4,
    },
    livePreviewTitle: {
      alignSelf: 'center',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    livePreviewCard: {
      overflow: 'auto',
      borderRadius: '5px',
      padding: theme.spacing(2),
    },
    livePreview: {
      '& button[type="submit"]': {
        display: 'none',
      },
      '& button[type="button"]': {
        display: 'none',
      },
    },
    confirmDialog: {
      '& button[aria-label="Close"]': {
        display: 'none',
      },
    },
  }),
);

export interface EditDomainSchemaDialogProps extends DialogProps {
  domain: string | undefined;
  onClose: () => void;
}

export const EditDomainSchemaDialog: React.FunctionComponent<EditDomainSchemaDialogProps> = (
  props,
) => {
  const { id, open, onClose, domain } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles(props);
  const url = apiUrl('config', `capabilityConfigs/schemas/${domain}`);
  const {
    data: domainSchema,
    loading: loadingDomainSchema,
    error,
    fetch,
    doPatch,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi(url);
  const [schema, setSchema] = React.useState(domainSchema?.schema);
  const [uiSchema, setUiSchema] = React.useState(domainSchema?.uiSchema);
  const [formHeight, setFormHeight] = React.useState('auto');
  const [isDefaultSchema, setIsDefaultSchema] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const formRef = React.useRef(null); // Dynamically set the height of the form preview when the schema changes using the livePreviewContainer div formRef
  const succeededRequestsLength = succeededRequests?.length;
  const failedRequestsLength = failedRequests?.length;

  React.useEffect(() => {
    setSchema(domainSchema?.schema);
    setUiSchema(domainSchema?.uiSchema || {});

    setIsDefaultSchema(domainSchema?.schema?.description === defaultSchemaDescription);
    if (isDefaultSchema) {
      setSchema(schemaTemplate);
    }
  }, [domainSchema, isDefaultSchema]);

  React.useEffect(() => {
    if (formRef.current) {
      setFormHeight(`${(formRef.current as HTMLElement).offsetHeight}px`);
    }
  }, [schema, uiSchema]);

  const close = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleEditorChange = (ev: any, value: any): any => {
    try {
      const newSchema = JSON.parse(value);
      setSchema(newSchema);
    } catch (error) {
      console.error('Invalid JSON schema:', error);
    }
  };

  const handleUiSchemaChange = (ev: any, value: any): any => {
    try {
      const newUiSchema = value.trim() ? JSON.parse(value) : {};
      setUiSchema(newUiSchema);
    } catch (error) {
      console.error('Invalid JSON schema:', error);
    }
  };

  const updateSchema = () => {
    doPatch('', {
      schema,
      uiSchema,
    });
  };

  React.useEffect(() => {
    if (succeededRequests?.length > 0) {
      setOpenConfirmDialog(false);
      enqueueSnackbar(t('editConfigurationDomainSchema.updateSuccess'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    succeededRequests,
    succeededRequestsLength,
    t,
    domain,
  ]);

  React.useEffect(() => {
    if (failedRequests?.length > 0) {
      setOpenConfirmDialog(false);
      enqueueSnackbar(
        t('editConfigurationDomainSchema.updateFailed', {
          message: failedRequests[0].error.message,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [clearFailedRequests, close, enqueueSnackbar, failedRequests, failedRequestsLength, t]);

  return (
    <>
      {!loadingDomainSchema ? (
        <>
          <Dialog
            id={id}
            open={open}
            key={domain}
            onClose={close}
            aria-labelledby={`${id}-title`}
            aria-describedby={`${id}-description`}
            maxWidth="lg"
          >
            <DialogTitle onClose={close} id={`${id}-title`} className={classes.dialogTitle}>
              {`${t('editConfigurationDomainSchema.editSchema')} - [${domain}]`}
            </DialogTitle>
            <DialogContent className={classes.dialogDescription}>
              <Typography variant="body2" gutterBottom>
                {t('editConfigurationDomainSchema.description')}
              </Typography>
            </DialogContent>
            <DialogContent className={classes.dialogContent}>
              <div className={classes.schemaEditorContainer}>
                <Typography className={classes.editorTitle}>JSON Editor</Typography>
                <ControlledEditor
                  height="100%"
                  width="100%"
                  language="json"
                  onChange={handleEditorChange}
                  value={JSON.stringify(schema, null, 2)}
                  options={{
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    hideCursorInOverviewRuler: true,
                    overviewRulerLanes: 0,
                  }}
                />
                <Typography className={classes.editorTitle}>UI Schema Editor</Typography>
                <ControlledEditor
                  height="100%"
                  width="100%"
                  language="json"
                  onChange={handleUiSchemaChange}
                  value={JSON.stringify(uiSchema, null, 2)}
                  options={{
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    hideCursorInOverviewRuler: true,
                    overviewRulerLanes: 0,
                  }}
                />
              </div>
              <div
                className={classes.livePreviewContainer}
                ref={formRef}
                style={{
                  minHeight: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h3" gutterBottom className={classes.livePreviewTitle}>
                  {t('editConfigurationDomainSchema.jsonSchemaPreview')}
                </Typography>
                <Card
                  raised
                  className={classes.livePreviewCard}
                  style={{ overflow: 'auto', maxHeight: '500px' }}
                >
                  <Form className={classes.livePreview} schema={schema} uiSchema={uiSchema} />
                </Card>
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton onClick={() => setOpenConfirmDialog(true)}>
                {t('editConfigurationDomainSchema.updateJsonSchema')}
              </PrimaryButton>
            </DialogActions>
          </Dialog>

          {/* ConfirmDialog */}
          <Dialog
            id={`${id}-confirm-dialog`}
            open={openConfirmDialog}
            className={classes.confirmDialog}
          >
            <DialogTitle>{t('editConfigurationDomainSchema.confirmUpdateTitle')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('editConfigurationDomainSchema.confirmUpdateMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <DefaultButton onClick={() => setOpenConfirmDialog(false)}>
                {t('global.cancel')}
              </DefaultButton>
              <PrimaryButton onClick={updateSchema}>{t('global.yes')}</PrimaryButton>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditDomainSchemaDialog;
