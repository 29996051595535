import { AddCircle } from '@bb-ui/icons/dist/medium/AddCircle';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateLearnClientDialog } from 'dialogs/CreateLearnClientDialog';

export const AddLearnClientButton: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [groupDialogOpen, setGroupDialogOpen] = React.useState(false);

  return (
    <>
      <PrimaryButton
        startIcon={<AddCircle />}
        data-testid="header-add-button"
        onClick={() => setGroupDialogOpen(true)}
      >
        {t('learnClients.newClientButton')}
      </PrimaryButton>
      <CreateLearnClientDialog
        id="create-learn-client-dialog"
        onClose={() => setGroupDialogOpen(false)}
        open={groupDialogOpen}
      />
    </>
  );
};
