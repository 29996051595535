import React from 'react';
import { Box, Card, CardContent, CardHeader, Skeleton } from '@bb-ui/react-library';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';

export interface JobCardProps {
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
  headerAction?: React.ReactNode;
}

export const JobCard: React.FC<JobCardProps> = (props) => {
  const { loading } = useJobExecutionContext();
  return (
    <Box>
      <Card raised>
        <CardHeader title={props.title} action={props.headerAction} />
        <CardContent>
          {loading ? (
            <div>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </div>
          ) : (
            props.content
          )}
        </CardContent>
        {props.actions}
      </Card>
    </Box>
  );
};
