import React from 'react';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { ListItemIcon } from '@bb-ui/react-library';
import { Account, Information } from '@bb-ui/icons/dist/medium';

import { useJobExecutionContext } from '../../../contexts/JobExecutionContext';
import { JobCardList } from './basic/JobCardList';

export const RegistrationBox: React.FC = () => {
  const { execution, loading } = useJobExecutionContext();
  return (
    <JobCardList
      title="Job Information"
      subtitle={execution?.jobId ? `Job ID: ${execution?.jobId}` : undefined}
      loading={loading}
    >
      <ListItem>
        <ListItemIcon>
          <Information />
        </ListItemIcon>
        <ListItemText
          primary={execution?.jobConfig.jobName}
          secondary={execution?.jobConfig.jobDescription}
        />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Account />
        </ListItemIcon>
        <ListItemText
          primary="Contact"
          secondary={
            <text>
              {' '}
              This job is provided by <strong>Team {execution?.jobConfig.owner.team}</strong>. The
              email address on file is{' '}
              <a href={`mailto: ${execution?.jobConfig.owner.email}`}>
                {' '}
                {execution?.jobConfig.owner.email}
              </a>
            </text>
          }
        />
      </ListItem>
    </JobCardList>
  );
};
