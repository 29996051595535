import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import {
  useFeatureFlagDefinitions,
  FeatureFlagDefinitionData,
  IFeatureFlagLocalizedLabel,
} from '@bb-config-ui/feature-flags';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from 'components/PageTemplate';
import { PaginatedTable } from 'components/PaginatedTable';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { useAuthContext } from 'contexts/AuthContext';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    maxWidth: '90%',
    marginTop: theme.spacing(1),
  },
  searchBox: {
    margin: theme.spacing(1),
  },
}));

interface IconButtonLinkProps extends IconButtonProps {
  component?: React.ElementType;
  to?: string;
}

export const FeatureFlagList: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation('home');
  const localLanguage = i18n.language ? i18n.language.replace('-', '_') : 'en_US';

  const classes = useStyles(props);
  const IconButtonLink = IconButton as React.ElementType<IconButtonLinkProps>;
  const { idToken } = useAuthContext();
  const parseIdToken: string = idToken ?? '';
  const { error, loading, flagDefinitions } = useFeatureFlagDefinitions({
    accessToken: parseIdToken,
  });

  const findLabelByLocale = (labels: IFeatureFlagLocalizedLabel[]) => {
    let labelWithLanguage = labels?.find((data) => data.locale === localLanguage);

    if (!labelWithLanguage) {
      labelWithLanguage = labels?.find((data) => data.locale === 'en_US');
    }

    return labelWithLanguage ? labelWithLanguage?.label : '';
  };

  const flagDefinitionsLabel = flagDefinitions?.map((element, index) => {
    const data = {
      index,
      data: findLabelByLocale(element?.labels),
    };
    return data;
  });

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedFeatureFlags = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    // feature flag label alphabetical sort
    if (sortColumnId === 'featureFlag') {
      const sortFlagDefinitionLabel = flagDefinitionsLabel?.sort(
        (l1, l2) => orderSign * l1.data!.localeCompare(l2.data!)
      );
      return sortFlagDefinitionLabel?.map((element) => {
        const parseFlagDefinition: FeatureFlagDefinitionData[] = flagDefinitions ?? [];
        return parseFlagDefinition[element.index];
      });
    }

    return flagDefinitions;
  }, [flagDefinitions, flagDefinitionsLabel, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`featureFlagList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc' ?
        t('global.paginatedTable.ascending') :
        t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  const search =
    (filter: string) => ({ labels }: FeatureFlagDefinitionData) => {
      const label = findLabelByLocale(labels);
      return label ? label.toLowerCase().includes(filter.toLowerCase()) : false;
    };

  let content: React.ReactElement = <></>;

  if (error) {
    content = (
      <ErrorMessage
        data-testid="fnds-feature-flag-list-error"
        title={t('featureFlagList.loadError')}
        message={error.message}
        variant="block"
      />
    );
  } else if (loading) {
    content = <LoadingIndicator data-testid="fnds-feature-flag-list-init" />;
  } else if (flagDefinitions && flagDefinitions.length > 0) {
    content = (
      <div data-testid="feature-flags-definition-data">
        <Typography variant="h2">{t('featureFlagList.pageTitle')}</Typography>
        <Typography className={classes.searchBox} variant="body1">
          {t('featureFlagList.lengthData', { lengthFeatureFlags: flagDefinitions.length })}
        </Typography>
        <PaginatedTable
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('featureFlagList.searchLabel') }}
          sortedData={sortedFeatureFlags ?? []}
          search={search}
          noMatchesMessage={(searchExpression) => t('featureFlagList.noMatch', { searchExpression })
          }
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="feature-flags-table-header-feature-flag_label"
                  columnId="featureFlag"
                  tooltip={t('featureFlagList.sortByFeatureFlagLabel')}
                  tableCellProps={{ colSpan: 2 }}
                >
                  {t('featureFlagList.featureFlag')}
                </SortableTableHeaderCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(data: FeatureFlagDefinitionData, index) => (
            <TableRow
              key={index}
              aria-rowindex={index + 1}
              data-testid={`feature-flag-table-row-${index}`}
              role="row"
            >
              <TableCell
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="feature-flags-table-header-feature-flag_label"
                role="cell"
              >
                <Typography variant="h3">{findLabelByLocale(data?.labels)}</Typography>
                <Typography variant="body2" className={classes.description}>
                  {data?.internalDescription}
                </Typography>
              </TableCell>

              <TableCell
                aria-colindex={2}
                tabIndex={-1}
                aria-describedby="feature-flags-table-header-feature-flag_label"
                role="cell"
              >
                <IconButtonLink
                  aria-label={t('featureFlagList.moreInformation')}
                  component={Link}
                  to={`/feature-flags/${data?.flagKey}`}
                >
                  <Typography component="span" variant="inherit">
                    {t('global.view')}
                  </Typography>
                  <ChevronRight />
                </IconButtonLink>
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    );
  } else {
    content = (
      <Typography data-testid="fnds-feature-flag-list-no-data">
        {t('featureFlagList.noData')}
      </Typography>
    );
  }

  return (
    <PageTemplate
      showTabs
      title={t('featureFlagList.pageTitle')}
      data-testid="feature-flag-list-page"
    >
      {content}
    </PageTemplate>
  );
};
