// Unlike TenantIdp, this lists all IDPs for a tenant-- TenantIdp shows detail
// on a specific one.

import { Add } from '@bb-ui/icons/dist/small/Add';
import {
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Menu,
  MenuItem,
  PrimaryButton,
  Typography,
} from '@bb-ui/react-library';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageCard } from 'components/PageCard';
import { TooltipIconButton } from 'components/TooltipIconButton';
import { useTenantContext } from 'contexts/TenantContext';
import { useAuthorization } from 'hooks/useAuthorization';
import { IdpCard } from 'pages/Tenant/TenantIdps/IdpCard';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConnectAzureADIdpDialog from './ConnectAzureADIdpDialog';
import ConnectLearnIdpsDialog from './ConnectLearnIdpsDialog';
import ConnectSamlIdpDialog from './ConnectSamlIdpDialog';
import { ProvisioningStatus } from './ProvisioningStatus';
import { AzureADProvisionersPost, LearnConnectorProvisionersPost, SamlProvisionersPost, TenantIdpsContextProvider, useTenantIdpsContext } from './TenantIdps.context';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  noIdps: {
    margin: theme.spacing(4, 0),
    textAlign: 'center',
  },
  idpItem: {
    padding: 0,
  },
  sectionDivider: {
    color: theme.palette.background.b6,
  },
  provisioningHeader: {
    marginTop: theme.spacing(4),
  },
  menuItem: {
    width: '200px',
  },
  dialogTitle: {
    alignItems: 'start',
  },
}));

export interface TenantIdpsProps {
  tenantId: string;
}

export const TenantIdps: React.FunctionComponent<TenantIdpsProps> = (props) => {
  const { tenantId } = props;
  const { tenant } = useTenantContext();
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    error,
    provisioningDoPost,
    identityProviders,
    idpsLoading,
    authBrokerProvisioners,
    authBrokerProvisionersLoading,
    provisioningStatuses,
  } = useTenantIdpsContext();
  const [idpsInitialLoading, setIdpsInitialLoading] = React.useState(true);
  const [learnConnectorDialogOpen, setLearnConnectorDialogOpen] = React.useState(false);
  const [samlDialogOpen, setSamlDialogOpen] = React.useState(false);
  const [azureADDialogOpen, setAzureADDialogOpen] = React.useState(false);
  const [idpTypeMenuAnchor, setIdpTypeMenuAnchor] = React.useState<HTMLElement>();
  const hasIdps = identityProviders && identityProviders.length > 0;
  const hasLearnConnectorIdp = !!identityProviders?.find(idp => idp.type === 'LearnConnector');

  const pendingProvisioners = authBrokerProvisioners?.filter(({ provisioningId }) => {
    const status = provisioningStatuses[provisioningId];
    return status && (status !== 'SUCCEEDED');
  });
  const hasPendingProvisioners = !!pendingProvisioners && pendingProvisioners.length > 0;

  function handlePost(body: LearnConnectorProvisionersPost | SamlProvisionersPost | AzureADProvisionersPost) {
    if (!provisioningDoPost) {
      throw new Error('provisioningDoPost is undefined');
    }
    setSamlDialogOpen(false);
    setAzureADDialogOpen(false);
    setLearnConnectorDialogOpen(false);
    provisioningDoPost(body);
  }

  React.useEffect(
    () => setIdpsInitialLoading(prevInitialLoading => prevInitialLoading && (idpsLoading || authBrokerProvisionersLoading)),
    [idpsLoading, authBrokerProvisionersLoading],
  );
  if (idpsInitialLoading) {
    return <LoadingIndicator data-testid="fnds-tenant-idps-init" />;
  }
  if (error) {
    return <ErrorMessage title={t('tenantIdps.loadError')} message={error.message} data-testid="tenant-idps-error" />;
  }

  const canAddIdp = hasPermission('identity-provider', 'create');

  const openLearnConnectorDialog = () => {
    setLearnConnectorDialogOpen(true);
    setIdpTypeMenuAnchor(undefined);
  };
  const closeLearnConnectorDialog = () => {
    setLearnConnectorDialogOpen(false);
  };

  const openSamlDialog = () => {
    setSamlDialogOpen(true);
    setIdpTypeMenuAnchor(undefined);
  };

  const openAzureADDialog = () => {
    setAzureADDialogOpen(true);
    setIdpTypeMenuAnchor(undefined);
  };

  return (
    <div data-testid="tenant-idps-page">
      <PageCard variant="section">
        <CardHeader
          title={t('tenantIdps.pageTitle')}
          titleTypographyProps={{
            color: 'textSecondary',
            component: 'h3' as any,
            variant: 'h4',
          }}
          action={
            canAddIdp && (
              <TooltipIconButton
                label={t('tenantIdps.addIdp')}
                aria-label={t('tenantIdps.addIdp')}
                aria-controls={
                  idpTypeMenuAnchor ? 'tenantIdps-idp-type-menu' : undefined
                }
                aria-expanded={!!idpTypeMenuAnchor}
                aria-haspopup="menu"
                onClick={({ currentTarget }) => setIdpTypeMenuAnchor(currentTarget)
                }
                data-testid="tenantIdps-add-idp-button"
              >
                <Add />
              </TooltipIconButton>
            )
          }
          className={classes.cardHeader}
        />

        <Menu
          anchorEl={idpTypeMenuAnchor}
          id="tenantIdps-idp-type-menu"
          onClose={() => setIdpTypeMenuAnchor(undefined)}
          open={!!idpTypeMenuAnchor}
          MenuListProps={{ 'aria-label': t('tenantIdps.idpTypeMenuAriaLabel') }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          data-testid="tenantIdps-idp-type-menu"
        >
          <MenuItem
            className={classes.menuItem}
            button
            onClick={openAzureADDialog}
            data-testid="tenantIdps-idp-type-menu-azure-ad"
          >
            {t('tenantIdps.azureAD')}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            button
            onClick={openLearnConnectorDialog}
            data-testid="tenantIdps-idp-type-menu-learn"
          >
            {t('tenantIdps.learnConnector')}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            button
            onClick={openSamlDialog}
            data-testid="tenantIdps-idp-type-menu-saml"
          >
            {t('tenantIdps.saml')}
          </MenuItem>
        </Menu>
        {hasIdps ? (
          <List disablePadding data-testid="tenant-idps-cards">
            {identityProviders!.map((idp, index, array) => (
              <ListItem
                key={idp.id}
                divider={index < array.length - 1}
                className={classes.idpItem}
              >
                <IdpCard {...idp} to={`/tenants/${tenantId}/auth/${idp.id}`} />
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.noIdps} role="status">
            {t('tenantIdps.noIdps')}
          </div>
        )}
        {hasPendingProvisioners && (
          <>
            <List disablePadding data-testid="tenant-idps-processing-items">
              {pendingProvisioners!.map(
                (
                  {
                    tenantId,
                    provisioningId,
                    providerDisplayName,
                    authBrokerConnectionType,
                  },
                  index,
                  array,
                ) => (
                  <ListItem
                    key={provisioningId}
                    divider={index < array.length - 1}
                    className={classes.idpItem}
                  >
                    <ProvisioningStatus
                      key={provisioningId}
                      tenantId={tenantId}
                      provisioningId={provisioningId}
                      displayName={providerDisplayName}
                      type={authBrokerConnectionType}
                      status={provisioningStatuses[provisioningId]}
                    />
                  </ListItem>
                ),
              )}
            </List>
          </>
        )}
      </PageCard>

      <ConnectLearnIdpsDialog
        id="learn-connector-dialog"
        open={learnConnectorDialogOpen && !hasLearnConnectorIdp}
        onClose={() => setLearnConnectorDialogOpen(false)}
        onPost={handlePost}
      />
      <Dialog
        open={learnConnectorDialogOpen && hasLearnConnectorIdp}
        id="has-learn-idp-message-dialog"
        onClose={closeLearnConnectorDialog}
      >
        <DialogTitle
          className={classes.dialogTitle}
          onClose={closeLearnConnectorDialog}
        >
          {t('tenantIdps.tenantAlreadyHasLearnIdp', {
            tenantName: tenant?.name,
          })}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('tenantIdps.oneLearnIdpPerTenant')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={closeLearnConnectorDialog}>
            {t('global.goBack')}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
      {samlDialogOpen && (
        <ConnectSamlIdpDialog
          id="saml-dialog"
          open={samlDialogOpen}
          onClose={() => setSamlDialogOpen(false)}
          onPost={handlePost}
        />
      )}
      {azureADDialogOpen && (
        <ConnectAzureADIdpDialog
          id="azure-ad-dialog"
          open={azureADDialogOpen}
          onClose={() => setAzureADDialogOpen(false)}
          onPost={handlePost}
        />
      )}
    </div>
  );
};

export const TenantIdpsWithContext = () => {
  const params: { tenantId: string } = useParams();
  const { tenantId } = params;

  return (
    <TenantIdpsContextProvider tenantId={tenantId!}>
      <TenantIdps tenantId={tenantId!} />
    </TenantIdpsContextProvider>
  );
};

export default TenantIdpsWithContext;
