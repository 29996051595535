import * as React from 'react';
import { Card, CardContent, CardHeader, Theme } from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface TenantInformationItemProps {
  title: string;
  value?: string;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    border: 'none',
    display: 'grid',
    gridSpacing: theme.spacing(2),
    gridTemplateColumns: '1fr 1fr',
    marginBottom: '1px',
  },
  cardContent: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSizeSmall,
    textAlign: 'right',
  },
  cardHeader: {
    fontSize: theme.typography.fontSizeSmall,
    fontWeight: theme.typography.fontWeightBold,
  },
  unsetValue: {
    fontStyle: 'italic',
  },
}));

export const TenantInformationItem: React.FC<TenantInformationItemProps> = (
  props,
) => {
  const { title, value } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader title={title} data-testid="tenant-info-item-title" />
      <CardContent className={classes.cardContent} data-testid="tenant-info-item-content">
        {value ?? <span className={classes.unsetValue}>{t('tenantInformation.unset')}</span>}
      </CardContent>
    </Card>
  );
};
