import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { JobFormCard } from './basic/JobFormCard';

export const JobOutputBox: React.FC = () => {
  const { execution } = useJobExecutionContext();

  return (
    <JobFormCard
      title="Job Output"
      schema={execution?.jobConfig.outputSchema ?? {}}
      uiSchema={execution?.jobConfig.outputUiSchema}
      formData={execution?.jobOutput}
    />
  );
};
