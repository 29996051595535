import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@bb-ui/react-library/dist/components/styles';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { Tooltip } from '@bb-ui/react-library/dist/components/Tooltip';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { TenantLicenseMapping, TenantLicenseMappingRequestType } from './useTenantLicenseMappings.types';

export const useStyles = makeStyles(() => createStyles({
  tooltip: {
    maxWidth: 125,
    justifyContent: 'center',
  },
  switchLabel: {
    minWidth: 30,
  },
}));

export interface TenantLicenseActiveControlProps {
  id: string;
  mapping?: TenantLicenseMapping;
  disabled: boolean;
  onActiveChange: (active: boolean) => void;
}

export const TenantLicenseActiveControl: React.FunctionComponent<TenantLicenseActiveControlProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { id, mapping, disabled, onActiveChange } = props;
  const active = !!(mapping?.active);

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onActiveChange(checked);
  };

  const actionRequestType: () => TenantLicenseMappingRequestType = (() => {
    if (!mapping) return 'add';
    return active ? 'deactivate' : 'activate';
  });

  const switchLabel = () => {
    if (mapping?.isPending) {
      return <LoadingIndicator data-testid={`tenant-licenses-active-pending-indicator-${id}`} size="small" />;
    }
    return active ? t('global.yes') : t('global.no');
  };

  return (
    <FormControlLabel
      htmlFor={`tenant-licenses-active-control-${id}`}
      control={
        <Tooltip classes={{ tooltip: classes.tooltip }} title={t<string>(`tenantLicenses.actionHints.${actionRequestType()}`)}>
          <Switch
            id={`tenant-licenses-active-control-${id}`}
            onChange={handleActiveChange}
            checked={active}
            disabled={disabled}
          />
        </Tooltip>}
      label={<div className={classes.switchLabel}>{switchLabel()}</div>}
    />
  );
};

export default TenantLicenseActiveControl;
