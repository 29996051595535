// Non-React functions for working with GroupMapping and GroupMappings types.

import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from 'utils/apiUrl';
import {
  FoundationsGroup,
  GroupMapping,
  GroupMappings,
} from './GroupMapping.types';

// Creates a GroupMappings value from an array of possible GroupMapping and a
// particular Foundations group.

export function normalizeGroupMapping(
  source: GroupMapping[],
  fndsGroup: FoundationsGroup,
): GroupMappings {
  return {
    fndsGroup,
    mappings: source
      .filter((item) => item.fndsGroupId === fndsGroup.id)
      .map((item) => ({
        id: item.id,
        fndsGroupId: fndsGroup.id,
        idpGroupName: item.idpGroupName,
      })),
  };
}

// Converts a GroupMappings value into an array of GroupMapping (which is what the
// API looks for). Does not touch the original.

export function denormalizeGroupMappings(
  source: Partial<GroupMappings>,
): Partial<GroupMapping>[] {
  if (!source.mappings) {
    return [];
  }

  if (!source.fndsGroup) {
    throw new Error('fndsGroup is required on source');
  }

  return source.mappings.map((item) => ({
    id: item.id,
    fndsGroupId: source.fndsGroup!.id,
    idpGroupName: item.idpGroupName,
  }));
}

// Convenience function to run the above on arrays and concat the results.

export function denormalizeGroupMappingsArray(
  source: Partial<GroupMappings>[],
): Partial<GroupMapping>[] {
  let result: Partial<GroupMapping>[] = [];

  source.forEach(item => {
    result = [...result, ...denormalizeGroupMappings(item)];
  });

  return result;
}

// A deep comparison of mappings, so that we handle if an object in a mapping
// array was copied (which would fail a === check) or if we lost an ID along the way.

export function mappingsEqual(a: Partial<GroupMapping>, b: Partial<GroupMapping>) {
  return (
    a.fndsGroupId === b.fndsGroupId &&
    // no id check!
    a.idpGroupName === b.idpGroupName
  );
}

// The equivalent of Array.includes(), but using mappingsEqual above.

export function mappingsInclude(
  haystack: Partial<GroupMapping>[],
  needle: Partial<GroupMapping>,
) {
  return haystack.some((item) => mappingsEqual(item, needle));
}

// Issues a series of API requests as a diff between two states. It doesn't
// query the API first because we are treating the user's changes as a series of
// edits expressed sideways.
//
// Meaning if the user saw these mappings: A, B, C
//
// And they edited to: A, C, D
//
// ... we should only do a DELETE on B and a POST on D. If a mapping E was
// created by some other user while this user was editing, it should be
// untouched.
//
// This requires multiple requests and will reject as soon as any one fails.

export function updateTenantIdpMappings(
  tenantId: string,
  idpId: string,
  fromMappings: Partial<GroupMapping>[],
  toMappings: Partial<GroupMapping>[],
  accessToken: string,
) {
  // Determine what actions to take, and map them to axios call arguments.
  // Because our args are partials, do some quick checks to make sure that our
  // requests will be valid.

  const creates: AxiosRequestConfig[] = toMappings.filter(mapping => !mappingsInclude(fromMappings, mapping)).map(mapping => {
    if (!mapping.idpGroupName) {
      throw new Error(`Want to create a group mapping, but don't have a tenant IDP group name for it: ${JSON.stringify(mapping)}`);
    }

    if (!mapping.fndsGroupId) {
      throw new Error(`Want to create a group mapping, but don't have a Foundations group ID for it: ${JSON.stringify(mapping)}`);
    }

    return {
      headers: { Authorization: `Bearer ${accessToken}` },
      method: 'post',
      data: { idpGroupName: mapping.idpGroupName, fndsGroupId: mapping.fndsGroupId },
      url: apiUrl('sso', `tenants/${tenantId}/identityProviders/${idpId}/groupMappings`),
    };
  });
  const deletes: AxiosRequestConfig[] = fromMappings.filter(mapping => !mappingsInclude(toMappings, mapping)).map(mapping => {
    if (!mapping.id) {
      throw new Error(`Want to delete a role mapping, but don't have an id for it: ${JSON.stringify(mapping)}`);
    }

    return {
      headers: { Authorization: `Bearer ${accessToken}` },
      method: 'delete',
      url: apiUrl('sso', `tenants/${tenantId}/identityProviders/${idpId}/groupMappings/${mapping.id}`),
    };
  });

  // Prioritize creates first so that if we fail midstream, they happen first.
  // It's likely things will require cleanup by the user anyway, so this is kind
  // of arbitrary.

  return Promise.all(([...creates, ...deletes]).map(axios));
}
