// Manages information about the current route in relation to the route config
// in ../routes/. If multiple route configs match, we use the longest pathname
// so that a nested subroute is considered current instead of one of its
// parents. We need the config injected to avoid a massive circular dependency,
// since route config refers to every page in the app.
//
// Named so it's clear it's not part of react-router.

import * as React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AppRouteProps } from 'routes/AppRoute';

export interface AppRouteConsumerProps {
  allRoutes: AppRouteProps[];
  childRoutes: AppRouteProps[];
  currentRoute?: AppRouteProps;
  currentLevelRoutes: AppRouteProps[];
  parentRoutes: AppRouteProps[];
}

export interface AppRouteProviderProps {
  routes: AppRouteProps[];
}

export const AppRouteContext = React.createContext<AppRouteConsumerProps>({ allRoutes: [], childRoutes: [], currentLevelRoutes: [], parentRoutes: [] });

export const useAppRouteContext = () => React.useContext(AppRouteContext);

export const AppRouteContextProvider: React.FunctionComponent<AppRouteProviderProps> = ({ children, routes }) => {
  const { pathname } = useLocation();
  let currentRoute: AppRouteProps | undefined;
  let currentLevelRoutes: AppRouteProps[] = [];
  let parentRoutes: AppRouteProps[] = [];

  // Recursively search for the deepest route config matching the current
  // pathname. We need to consider every route config because we will match on a
  // parent route first before its children.

  function search(routes: AppRouteProps[], searchParents: AppRouteProps[]) {
    routes.forEach(route => {
      if (matchPath(pathname, { exact: route.exact, path: route.path }) && (!currentRoute || searchParents.length > parentRoutes.length)) {
        currentRoute = route;
        currentLevelRoutes = routes;
        parentRoutes = searchParents;
      }

      // Check nested routes.

      if (route.routes) {
        return search(route.routes, [...searchParents, route]);
      }
    });
  }

  search(routes, []);

  const context: AppRouteConsumerProps = {
    currentRoute,
    currentLevelRoutes,
    parentRoutes,
    allRoutes: routes,
    childRoutes: currentRoute?.routes || [],
  };

  return <AppRouteContext.Provider value={context}>{children}</AppRouteContext.Provider>;
};
