import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageTitleContainer: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loadMoreButton: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
