import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { JobFormCard } from './basic/JobFormCard';

export const JobInputBox: React.FC = () => {
  const { execution } = useJobExecutionContext();
  return (
    <JobFormCard
      title="Job Input"
      schema={execution?.jobConfig.inputSchema ?? {}}
      uiSchema={execution?.jobConfig.inputUiSchema}
      formData={execution?.jobInput}
    />
  );
};
