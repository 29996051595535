export const MobileConstants = {
  RowSize: 6,
};

export enum ToggleStatusString {
  On = '1',
  Off = '0',
}

export const urlPattern = /^(https?:\/\/)?([\w.-]+\.)+[a-z]{2,}(\/\S*)?$/i;
