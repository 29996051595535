// A top-level wrapper for a route, incorporating path and whether
// authentication is required.

import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { SignIn } from 'pages/SignIn';
import {
  AuthorizationPermission,
  AuthorizationService,
  useAuthorization,
} from 'hooks/useAuthorization';
import { ErrorPage } from 'pages/Error';
import { useAuthContext } from 'contexts/AuthContext';

export interface AppRouteProps extends Omit<RouteProps, 'component'> {
  /**
   * If specified, the user must have ALL of the permissions listed in order to
   * get access to the route.
   */
  authorization?: {
    permission: AuthorizationPermission;
    service: AuthorizationService;
  }[];
  /**
   * Component to render at the route.
   */
  component: React.ElementType;
  /**
   * Match `path` exactly?
   */
  exact?: boolean;
  /**
   * Does this route have a tab?
   */
  hasTab?: boolean;
  /**
   * Name of the route. Used with tabs and breadcrumbs.
   */
  name?: string;
  /**
   * Path of the route.
   */
  path: string;
  /**
   * Can the route be reached without the user being authorized?
   */
  public?: boolean;
  /**
   * Routes nested in this one, if any. To display them, the component must use
   * a `<Subroutes>` component. Because a nested route is displayed inside a
   * parent...
   * - You cannot nest a `public` route inside a private one.
   * - If a subroute has an `authorization` property, its permissions are
   *   effectively concatenated with its parents'. It acts like there is an AND
   *   between the permissions. not an OR.
   */
  routes?: AppRouteProps[];
}

export const AppRoute: React.FunctionComponent<AppRouteProps> = (props) => {
  const {
    authorization,
    component: Component,
    exact,
    location,
    path,
    public: routePublic,
    routes,
  } = props;
  const { hasPermission } = useAuthorization();
  const { isAuthenticated } = useAuthContext();

  if (!routePublic && !isAuthenticated) {
    return <SignIn returnPath={location?.pathname} />;
  }

  if (authorization) {
    if (authorization.some(({ permission, service }) => !hasPermission(service, permission))) {
      return <ErrorPage type="unauthorized" />;
    }
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={(props: any) => <Component {...props} routes={routes} />}
    />
  );
};
