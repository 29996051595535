import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  CardHeader,
  CardContent,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
  CircularProgress,
} from '@bb-ui/react-library';
import { Badge } from '@bb-ui/react-library/dist/components/Badge';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { TenantIdps } from 'pages/Tenant/TenantIdps';
import { useTenantContext } from 'contexts/TenantContext';
import { PageCard } from 'components/PageCard';
import { useRestApi } from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import { TenantData } from 'App.types';
import { apiUrl } from 'utils/apiUrl';
import { useAuthorization } from 'hooks/useAuthorization';
import { TooltipIconButton } from 'components/TooltipIconButton';
import { SsoDialog } from './SsoDialog';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageTitle: {
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  badgeOff: {
    backgroundColor: theme.palette.background.b8,
  },
  badgeOn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

export const TenantAuth = () => {
  const { tenant, loading: tenantLoading, fetch } = useTenantContext();
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(tenantLoading);
  const {
    doPatch,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi(apiUrl('tenancy', 'tenants'), { manual: true });
  const { enqueueSnackbar } = useSnackbar();

  const canEditVisibility = hasPermission('tenant', 'update');
  const canSeeIdps = hasPermission('identity-provider', 'read');

  function handleOnSave({ isHidden }: Partial<TenantData>) {
    doPatch(tenant?.id, { isHidden });
    setDialogIsOpen(false);
    setLoading(true);
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      fetch!();
      clearSucceededRequests();
      enqueueSnackbar(t('tenantAuth.ssoSettingsSuccess'), { variant: 'success' });
      setLoading(false);
    }
  }, [succeededRequests, clearSucceededRequests, fetch, enqueueSnackbar, setLoading, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      clearFailedRequests();
      enqueueSnackbar(t('tenantAuth.ssoSettingsError'), { variant: 'error' });
      setLoading(false);
    }
  }, [failedRequests, clearFailedRequests, enqueueSnackbar, setLoading, t]);

  return (
    <div data-testid="tenant-auth-page">
      <Typography variant="h2" className={classes.pageTitle}>
        {t('tenantAuth.pageTitle')}
      </Typography>

      <PageCard variant="section">
        <CardHeader
          title={t('tenantAuth.singleSignOn')}
          titleTypographyProps={{
            color: 'textSecondary',
            component: 'h3' as any,
            variant: 'h4',
          }}
          action={
            canEditVisibility && (
              <TooltipIconButton
                aria-haspopup="dialog"
                label={t('global.edit')}
                onClick={() => setDialogIsOpen(true)}
              >
                <Edit />
              </TooltipIconButton>
            )
          }
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardRow}>
            <div>
              <Typography variant="subtitle2">
                {t('tenantAuth.tenantDiscoverability')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <Trans
                  i18nKey="tenantAuth.tenantDiscoverabilityDetail"
                  components={[<Link href="https://signin.blackboard.com" />]}
                  values={{ linkText: 'signin.blackboard.com' }}
                />
              </Typography>
            </div>
            {loading || tenantLoading ? (
              <CircularProgress
                ariaLabel={t('global.loadingIndicator.loading').toString()}
                size="small"
                data-testid="loading-tenant-data"
              />
            ) : (
              <Badge
                badgeContent={
                  tenant?.isHidden ? t('global.off') : t('global.on')
                }
                classes={{
                  badge: tenant?.isHidden ? classes.badgeOff : classes.badgeOn,
                }}
                position="inline"
                data-testid="tenant-is-hidden"
              />
            )}
          </div>
        </CardContent>
      </PageCard>

      <SsoDialog
        onClose={() => setDialogIsOpen(false)}
        onSave={handleOnSave}
        open={dialogIsOpen}
        tenant={tenant}
      />

      {canSeeIdps && <TenantIdps />}
    </div>
  );
};
