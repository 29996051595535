import React from 'react';
import { withRouter } from 'react-router-dom';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { LoadingIndicator } from 'components/LoadingIndicator';
import {
  CognitoAuthProvider as LibraryCognitoAuthProvider,
  CognitoAuthProviderProps,
} from '@bb-ui/auth/dist/cognito';

export interface AuthProviderProps {
  search: string;
}

export const CognitoAuthProvider = ({
  children,
  search,
}: React.PropsWithChildren<AuthProviderProps>) => {
  const { loading, cognito, deployment } = useAppConfigContext();

  if (loading || !cognito || !deployment) {
    return (
      <LoadingIndicator theme="light" variant="fill-window" data-testid="loading-app-config" />
    );
  }

  function formatUrl(url: string) {
    if (cognito!.isIl4) {
      // When isIl4, the url (which represents the path in this case) is appended to the origin.
      return window.location.origin + url;
    }

    return url;
  }

  const uasProps: CognitoAuthProviderProps = {
    signInUrl: formatUrl(cognito.signInUrl),
    returnUrl: formatUrl(cognito.returnUrl),
    // Note: signOutReturnUrl defaults to returnUrl when undefined.
    // When provided, must have same hostname as `returnUrl` to pass validation in Sign-In UI.
    signOutReturnUrl: cognito.signOutReturnUrl ? formatUrl(cognito.signOutReturnUrl) : undefined,
    tenantId: cognito.tenantId,
  };

  if (deployment.internal) {
    // For E2E tests we need to switch the IdP from bb-corp-okta to a LearnConnector so that the tests can automatically
    // create and delete test users. The uri component (tenant ID) of the underlying tenant is stored to local storage so that it's
    // persisted between page reloads.

    const searchParams = new URLSearchParams(search);
    const searchTestTenantUriComponent = searchParams.get('testTenantUriComponent');
    if (searchTestTenantUriComponent) {
      window.localStorage.setItem('auth-tenant-uri-fragment', searchTestTenantUriComponent);
      uasProps.tenantId = searchTestTenantUriComponent;
    }
  }

  return <LibraryCognitoAuthProvider {...uasProps}>{children}</LibraryCognitoAuthProvider>;
};

// We have to be careful with this HOC because <AuthProvider> is higher in the
// hierarchy than the routes (which we want so that routes can call login() and
// so on). We can safely use the `location` prop but not `match`, for example.
//
// @see https://reactrouter.com/web/api/withRouter

export default withRouter(({ location, children }) => (
  <CognitoAuthProvider search={location.search}>{children}</CognitoAuthProvider>
));
