import * as React from 'react';
import { CircularProgress } from '@bb-ui/react-library/dist/components/Progress';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts/AuthContext';
import { useStyles } from './SignOut.styles';

export const SignOut: React.FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { isAuthenticated, signOut } = useAuthContext();
  const { t } = useTranslation();
  const [redirect, setRedirect] = React.useState('');

  React.useEffect(() => {
    if (isAuthenticated && signOut) {
      signOut();
    }
  }, [isAuthenticated, signOut]);

  // This is true after the user has clicked the return button.
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const onReturn = () => {
    setRedirect('/');
    window.localStorage.removeItem('auth-tenant-uri-fragment');
  };

  const signingOutContent = (
    <CircularProgress
      data-testid="signing-out"
      ariaLabel={t('global.loadingIndicator.loading').toString()}
    />
  );
  const signedOutContent = (
    <>
      <Typography data-testid="signed-out">{t('auth.signedOutConfirmation')}</Typography>
      <PrimaryButton data-testid="return-button" onClick={onReturn}>
        {t('auth.return')}
      </PrimaryButton>
    </>
  );

  return (
    <div className={classes.root} data-testid="sign-out-page">
      {isAuthenticated ? signingOutContent : signedOutContent}
    </div>
  );
};

export default SignOut;
