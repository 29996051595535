import { LinearProgress, Table, TableCell, TableHead, TableRow } from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IJobConfig, ListJobsResponseBody } from '@lct-jobs/core';
import { useEffect } from 'react';
import useRestApi from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';
import { JobRow } from './JobRow';
import { useTenantContext } from '../../contexts/TenantContext';
import { useSnackbar } from '../../hooks/useSnackbar';

export const JobList: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenantId } = useTenantContext();

  const jobApi = useRestApi<ListJobsResponseBody>(
    apiUrl('lct', `tenants/${tenantId}/jobManager/jobs`),
  );

  const [jobs, setJobs] = React.useState<IJobConfig[]>([]);

  const { data, error } = jobApi;
  useEffect(() => {
    if (data?.jobs && data.jobs.length > 0) {
      setJobs((prevJobs) => [...prevJobs, ...(data?.jobs ?? [])]);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(`Failed to load Jobs: ${error.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  return (
    <div>
      {jobApi.loading ? <LinearProgress ariaLabel="Loading Jobs" /> : null}
      <Table>
        <TableHead>
          <TableRow key="$HEADER">
            <TableCell>{t('jobService.name')}</TableCell>
            <TableCell>{t('jobService.domain')}</TableCell>
            <TableCell>{t('jobService.description')}</TableCell>
            <TableCell>{t('jobService.submitJob')}</TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {jobs.map((job) => (
            <JobRow job={job} tenantId={tenantId} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
