import * as React from 'react';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { Edit } from '@bb-ui/icons/dist/small';
import {
  CircularProgress,
  IconButton,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useFeatureFlagDefinitions,
  FeatureFlagDefinitionData,
  IFeatureFlagLocalizedLabel,
} from '@bb-config-ui/feature-flags';
import { orderBy } from 'lodash';
import { SortableTable } from '@bb-ui/react-library/dist/components/SortableTable';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { TenantData } from 'App.types';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useAuthContext } from 'contexts/AuthContext';
import { FeatureFlagRegionsDialog } from './FeatureFlagRegionsDialog';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  editCell: {
    textAlign: 'right',
  },
  tenantsTableCell: {
    paddingLeft: theme.spacing(3),
  },
  iconButton: {
    lineHeight: 'inherit',
  },
  heading: {
    marginBottom: theme.spacing(1),
    display: 'inline-flex',
    width: '100%',
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(0.5),
    left: theme.spacing(1),
  },
  sectionWarning: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(-3),
    maxWidth: theme.spacing(100),
  },
}));

interface Region {
  name: string;
  visibility: boolean | undefined;
}

export const FeatureFlagRegions: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation('home');
  const localLanguage = i18n.language ? i18n.language.replace('-', '_') : 'en_US';
  const classes = useStyles(props);
  const { flagKey } = useParams<{ flagKey: string }>();
  const { idToken } = useAuthContext();
  const parseIdToken = idToken ?? '';
  const tenantRegions = useAppConfigContext().tenantSupportedRegions ?? [];
  const featureFlagRegions = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const regionNames = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const { enqueueSnackbar } = useSnackbar();
  let content: React.ReactElement;
  const {
    flagDefinitions,
    error: errorFFD,
    loading: loadingFFD,
    loadingRequests: flagDefinitionUpdateQueue,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
    updateFeatureFlagDefinition,
  } = useFeatureFlagDefinitions({ accessToken: parseIdToken });
  const {
    data,
    error: errorTenant,
    loading: loadingTenant,
  } = useRestApi(apiUrl('tenancy', 'tenants/searchable'));
  const tenants = React.useMemo<TenantData[]>(() => {
    // Filter Tenants based on Tenant supported regions for this deployment
    const allTenantsData: TenantData[] = data?.results ?? [];
    const filteredTenantsData: TenantData[] = [];
    allTenantsData.forEach((tenant) => {
      if (
        !tenant.region ||
        (tenantRegions.includes(tenant.region) && featureFlagRegions.includes(tenant.region))
      ) {
        filteredTenantsData.push(tenant);
      }
    });
    return filteredTenantsData;
  }, [data, tenantRegions, featureFlagRegions]);
  const [regions, setRegions] = React.useState<Region[]>([]);
  const [currentFeatureFlag, setCurrentFeatureFlag] = React.useState<FeatureFlagDefinitionData>();
  const [selectedRegion, setSelectedRegion] = React.useState<string>('');
  const [isRegionDialogOpen, setIsRegionDialogOpen] = React.useState<boolean>(false);
  // Manage state for updates done in multiple flag values
  const [batchUpdateLoading, setBatchUpdateLoading] = React.useState<boolean>(false);
  const [batchUpdateError, setBatchUpdateError] = React.useState<Error>();
  const [batchUpdateResponse, setBatchUpdateResponse] = React.useState();
  // This is used to localize the Feature Flag name filtering by local language or by default in "en_US"
  const findLabelByLocale = React.useCallback(
    (labels: IFeatureFlagLocalizedLabel[]) => {
      let labelWithLanguage = labels?.find((data) => data.locale === localLanguage);

      if (!labelWithLanguage) {
        labelWithLanguage = labels?.find((data) => data.locale === 'en_US');
      }

      return labelWithLanguage ? labelWithLanguage?.label : '';
    },
    [localLanguage],
  );

  const batchUpdateState = (isLoading?: boolean, response?: any, error?: Error) => {
    setBatchUpdateLoading(isLoading ?? false);
    setBatchUpdateResponse(response);
    setBatchUpdateError(error);
  };

  // This is used by the update dialog to update the data on the Region table when updates are performed successfully
  React.useEffect(() => {
    const flag: FeatureFlagDefinitionData | undefined = flagDefinitions?.find(
      (el: FeatureFlagDefinitionData) => el?.flagKey === flagKey
    );
    setCurrentFeatureFlag(flag);
  }, [flagDefinitions, flagKey]);

  React.useEffect(() => {
    if (currentFeatureFlag && regions.length === 0) {
      const theCurrentFlag = currentFeatureFlag;
      const data: Region[] = [];
      // Filter Regions based on Tenant supported regions for this deployment
      regionNames.forEach((regionName) => {
        if (tenantRegions.includes(regionName) && featureFlagRegions.includes(regionName)) {
          const visible =
            theCurrentFlag?.visibility?.visible ||
            theCurrentFlag?.visibility.criteria?.regions?.includes(regionName.toLowerCase());
          data.push({ name: regionName.toUpperCase(), visibility: visible });
        }
      });
      setRegions(data);
    }
  }, [currentFeatureFlag, regionNames, regions.length, tenantRegions, featureFlagRegions]);

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedRegions = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    return sortColumnId ? orderBy(regions, [sortColumnId], [sortDirection ?? true]) : regions;
  }, [regions, sortParams]);
  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`featureFlagDefinitionRegions.${columnId}`);
    const orderLabel =
      sortDirection === 'asc' ?
        t('global.paginatedTable.ascending') :
        t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  // Visibility update handling
  React.useEffect(() => {
    if (
      (flagDefinitionUpdateQueue && !batchUpdateLoading) ||
      batchUpdateResponse ||
      batchUpdateError
    ) {
      setIsRegionDialogOpen(false);
    }
    if (
      currentFeatureFlag &&
      (succeededRequests.length > 0 || batchUpdateResponse) &&
      !batchUpdateLoading &&
      !flagDefinitionUpdateQueue
    ) {
      const theCurrentFlag = succeededRequests.pop()?.response.data;
      if (theCurrentFlag) {
        setCurrentFeatureFlag(theCurrentFlag);
      }
      clearSucceededRequests();
      if (batchUpdateResponse) {
        setBatchUpdateResponse(undefined);
      }
      enqueueSnackbar(
        t('featureFlagGeneral.manageSettingsSuccess', {
          flagName: findLabelByLocale(currentFeatureFlag.labels),
          item: selectedRegion,
        }),
        { variant: 'info' },
      );
    }

    if (
      (failedRequests.length > 0 || batchUpdateError) &&
      !batchUpdateLoading &&
      !flagDefinitionUpdateQueue
    ) {
      enqueueSnackbar(t('featureFlagGeneral.updateFeatureFlagFail'), { variant: 'error' });
      clearFailedRequests();
    }
  }, [
    batchUpdateError,
    batchUpdateLoading,
    batchUpdateResponse,
    clearFailedRequests,
    clearSucceededRequests,
    currentFeatureFlag,
    enqueueSnackbar,
    failedRequests,
    findLabelByLocale,
    flagDefinitionUpdateQueue,
    selectedRegion,
    succeededRequests,
    t,
  ]);

  if (errorFFD ?? errorTenant) {
    content = (
      <ErrorMessage
        data-testid="fnds-feature-flag-regions-list-error"
        title={t('featureFlagDefinitionRegions.loadError')}
        message={errorFFD?.message}
        variant="block"
      />
    );
  } else if (loadingFFD ?? loadingTenant) {
    content = <LoadingIndicator data-testid="fnds-feature-flag-regions-list-init" />;
  } else if (currentFeatureFlag) {
    content = (
      <div data-testid="feature-flags-definition-regions-data">
        <FeatureFlagRegionsDialog
          isDialogOpen={isRegionDialogOpen}
          dialogToggle={setIsRegionDialogOpen}
          flagDefinition={currentFeatureFlag}
          updateFeatureFlagDefinition={updateFeatureFlagDefinition}
          selectedRegion={selectedRegion}
          tenants={tenants}
          batchUpdateState={batchUpdateState}
          flagName={findLabelByLocale(currentFeatureFlag.labels)}
        />
        <SortableTable
          id="feature-flags-definition-regions-table"
          getAriaSortMessage={getAriaSortMessage}
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
        >
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="feature-flag-regions-table-header-region_name"
                columnId="name"
                tooltip={t('featureFlagDefinitionRegions.sortByRegionName')}
              >
                {t('tenantInformation.region')}
              </SortableTableHeaderCell>
              <TableCell id="feature-flag-regions-table-header-tenants-count" role="columnheader">
                {t('featureFlagGeneral.tenantsCountTableHeader')}
              </TableCell>
              <TableCell
                id="feature-flag-regions-table-header-region_client_admin_permissions"
                role="columnheader"
              >
                {t('featureFlagGeneral.clientAdminPermissions')}
              </TableCell>
              <TableCell
                id="feature-flag-regions-table-header-region_manage_values"
                role="columnheader"
                className={classes.editCell}
              />
            </TableRow>
          </TableHead>

          <TableBody>
            {sortedRegions.map((region, index) => (
              <TableRow
                aria-rowindex={index + 1}
                data-testid={`feature-flag-region-table-row-${index}`}
                role="row"
                key={index}
              >
                <TableCell
                  aria-colindex={1}
                  tabIndex={-1}
                  data-testid="region-name"
                  aria-describedby="feature-flag-regions-table-header-region_name"
                >
                  {region.name}
                </TableCell>
                <TableCell
                  aria-colindex={2}
                  tabIndex={-1}
                  className={classes.tenantsTableCell}
                  aria-describedby="feature-flag-regions-table-header-tenants-count"
                >
                  {tenants?.filter(
                    (tenant) => tenant.region?.toLocaleLowerCase() === region.name.toLocaleLowerCase()
                  ).length ?? 0}
                </TableCell>
                <TableCell
                  aria-colindex={3}
                  tabIndex={-1}
                  aria-describedby="feature-flag-regions-table-header-region_client_admin_permissions"
                >
                  {region.visibility ?
                    t('featureFlagGeneral.clientAdminPermissionsEditViewOption') :
                    t('featureFlagGeneral.none')}
                </TableCell>
                <TableCell
                  aria-colindex={4}
                  tabIndex={-1}
                  className={classes.editCell}
                  aria-describedby="feature-flag-regions-table-header-region_manage_values"
                >
                  <IconButton
                    aria-haspopup="dialog"
                    data-testid="submit-flag-definition-edit-btn"
                    onClick={() => {
                      setSelectedRegion(region.name.toLowerCase());
                      setIsRegionDialogOpen(true);
                    }}
                  >
                    {flagDefinitionUpdateQueue > 0 &&
                    selectedRegion === region.name.toLowerCase() ? (
                      <CircularProgress
                          data-testid="region-edit-btn-circular-progress"
                          ariaLabel={t('featureFlagDefinitionRegions.circularProgress') ?? ''}
                          size="small"
                        />
                      ) : (
                        <Edit />
                      )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </SortableTable>
      </div>
    );
  } else {
    content = (
      <Typography data-testid="fnds-feature-flag-regions-list-no-data">
        {t('featureFlagDefinitionRegions.noData')}
      </Typography>
    );
  }

  return (
    <div data-testid="feature-flag-regions-list-page">
      <div>
        <Typography className={classes.sectionWarning} variant="subtitle2">
          {t('featureFlagDefinitionRegions.sectionWarning')}
        </Typography>
        <Typography className={classes.heading} component="h2" variant="h2">
          {t('featureFlagDefinitionRegions.regionsLabel')}
        </Typography>
        {content}
      </div>
    </div>
  );
};
