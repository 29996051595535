/**
 * This is the temporary implementation of changes slated for the Snackbar
 * component in bb-ui.
 * See: https://jira.bbpd.io/browse/PLAT-3774
 */

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsObject, SnackbarMessage } from 'notistack';
import {
  BbThemeProvider,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useSnackbar as useBbUiSnackbar,
  useTheme,
} from '@bb-ui/react-library';
import { Attention } from '@bb-ui/icons/dist/small/Attention';
import { Close } from '@bb-ui/icons/dist/small/Close';
import { Complete } from '@bb-ui/icons/dist/small/Complete';

export interface EnqueueSnackbarOptions extends OptionsObject {
  icon?: React.ReactElement;
  hideIcon?: boolean;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
  },
}));

export type SnackbarVariant = 'success' | 'error';

const variantIcons: Record<SnackbarVariant, React.ReactElement> = {
  success: <Complete />,
  error: <Attention />,
};

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useBbUiSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const enqueue = (message: SnackbarMessage, options?: EnqueueSnackbarOptions) => {
    const { variant, icon: propsIcon, hideIcon, ...rest } = options ?? {};
    const variantIcon = variant ? variantIcons[variant as SnackbarVariant] : null;
    const icon = propsIcon ?? variantIcon;

    return enqueueSnackbar(
      <div className={classes.messageContainer}>
        {!hideIcon && icon &&
          <span className={classes.icon}>
            {icon}
          </span>
        }
        {message}
      </div>,
      {
        action: (
          <BbThemeProvider theme="dark" isRtl={theme.direction === 'rtl'}>
            <IconButton onClick={() => closeSnackbar()} aria-label={t('global.close')}>
              <Close />
            </IconButton>
          </BbThemeProvider>
        ),
        ...rest,
      },
    );
  };

  return { enqueueSnackbar: enqueue, closeSnackbar };
};
