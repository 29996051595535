import * as React from 'react';
import { Theme, makeStyles, createStyles } from '@bb-ui/react-library';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { TabPanel } from '@bb-ui/react-library/dist/components/TabPanel';
import { Header } from 'components/Header';
import { ContentWrap } from 'components/ContentWrap';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { BreadcrumbsProps } from 'components/Breadcrumbs/Breadcrumbs';
import { PageTabs } from 'components/PageTabs';
import { PageTabProps } from 'components/PageTabs/PageTabs';
import { useAppRouteContext } from 'contexts/AppRouteContext';
import { PageCard } from 'components/PageCard';
import { ErrorMessage, ErrorMessageProps } from 'components/ErrorMessage';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    breadcrumbs: {
      margin: '12px 0 0 30px',
    },
    headerDetailWrap: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    headerDetail: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  }),
);

export interface PageTemplateProps {
  title: string;
  subtitle?: string;
  breadcrumbProps?: Partial<BreadcrumbsProps>;
  headerControl?: React.ReactNode;
  showTabs?: boolean;
  tabProps?: Partial<PageTabProps>;
  errorMessage?: ErrorMessageProps;
  unbounded?: boolean;
  disabledTabIds?: string[];
}

export const PageTemplate: React.FunctionComponent<PageTemplateProps> = (props) => {
  const {
    children,
    breadcrumbProps,
    showTabs,
    headerControl,
    subtitle,
    tabProps,
    title,
    errorMessage,
    disabledTabIds,
    ...other
  } = props;
  const classes = useStyles(props);
  const { currentRoute } = useAppRouteContext();
  const tabPanelId = 'page-template-tabs';
  let content = children;

  if (errorMessage) {
    content = (
      <PageCard>
        <ErrorMessage minHeight="168px" {...errorMessage} />
      </PageCard>
    );
  }

  if (showTabs) {
    content = (
      <TabPanel
        panelId={tabPanelId}
        // Needs to match the DOM ID that will be assigned to the active tab by <PageTabs>
        ariaLabelledby={`tab-${currentRoute?.path}`}
        // tabindex={-1} // TODO: update in bb-ui to accept additional props and should default to -1
      >
        {content}
      </TabPanel>
    );
  }

  return (
    <>
      <BbThemeProvider theme="dark">
        <Header />
      </BbThemeProvider>
      <div className={classes.root} data-testid="page-header">
        <Breadcrumbs className={classes.breadcrumbs} {...breadcrumbProps} />
        <ContentWrap className={classes.headerDetailWrap}>
          <div className={classes.headerDetail}>
            <div>
              <Typography variant="display2" component="h2">
                {title}
              </Typography>
              {subtitle && (
                <Typography variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              )}
            </div>
            {headerControl}
          </div>
          {showTabs && (
            <PageTabs tabPanelId={tabPanelId} tabProps={tabProps} disabledTabIds={disabledTabIds} />
          )}
        </ContentWrap>
      </div>
      <ContentWrap component="main" marginY="50px" {...other}>
        {content}
      </ContentWrap>
    </>
  );
};

export default PageTemplate;
