import React from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { TenantData } from 'App.types';
import { UseRestApiResult } from 'hooks/useRestApi.types';
import { apiUrl } from 'utils/apiUrl';

export interface TenantContextProps {
  fetch?: UseRestApiResult["fetch"];
  loading: boolean;
  tenant?: Partial<TenantData>;
  error?: Error;
  tenantId: string;
}

export interface TenantContextProviderProps {
  tenantId: string;
}

export const TenantContext = React.createContext<TenantContextProps>({ loading: true, tenantId: "GLOBAL" });

export const useTenantContext = () => React.useContext(TenantContext);

export const TenantContextProvider: React.FunctionComponent<TenantContextProviderProps> = (props) => {
  const { children, tenantId } = props;
  const { data, error, loading, ...rest } = useRestApi(apiUrl('tenancy', `tenants/${tenantId}`));

  // Pass error along to handle in page view

  let context: TenantContextProps = { tenantId, loading, error, ...rest };

  if (data) {
    context = { tenant: data, ...context };
  }

  return <TenantContext.Provider value={context}>{children}</TenantContext.Provider>;
};

export default TenantContextProvider;
