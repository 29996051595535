import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nThemeProvider } from 'components/I18nThemeProvider';
import { App } from 'App';
import 'i18n'; // needs to be bundled
import * as serviceWorker from './serviceWorker';

/**
 * Last resort error message.
 * Keeping this simple since translations and bb-ui aren't available at this point.
 */
const ErrorFallback = ({ error }: { error?: Error }) => (
  <div role="alert">
    <h1>Uh oh. An error occurred. Please refresh the page or contact your system administrator for help.</h1>
    {error && <p>{error.message}</p>}
  </div>
);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback="">
      <I18nThemeProvider>
        <App />
      </I18nThemeProvider>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
