import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Attention } from '@bb-ui/icons/dist/small/Attention';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.background.default,
    padding: theme.spacing(1, 2),
    width: '100%',
    minHeight: '53px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    alignContent: 'center',
    gridGap: theme.spacing(1),
    gridAutoFlow: 'column',
  },
  badge: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 1, 0),
    borderRadius: 999,
    fontSize: 10,
    fontWeight: 'bold',
  },
  inlineIndicator: {
    width: 'auto',
  },
  error: {
    color: theme.palette.error.main,
  },
}));
export interface ProvisioningStatusProps {
  tenantId: string;
  provisioningId: string;
  displayName?: string;
  type: string;
  status?: string;
}

export const ProvisioningStatus: React.FunctionComponent<ProvisioningStatusProps> = (props) => {
  const { displayName, provisioningId, type, status } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();

  const content = () => {
    switch (status) {
      case 'RUNNING':
        return (
          <>
            <Typography variant="body2">{t('tenantIdps.settingUpIdp')}</Typography>
            <LoadingIndicator className={classes.inlineIndicator} size="small" />
          </>
        );
      case 'FAILED':
      case 'TIMED_OUT':
      case 'ABORTED':
        return (
          <>
            <Attention className={classes.error} />
            <Typography className={classes.error} variant="caption">{t('tenantIdps.idpNotAdded')}</Typography>
          </>
        );
      case 'SUCCEEDED':
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.root}>
      <span className={classes.content}>
        <Typography variant="h4">{displayName ?? provisioningId}</Typography>
        <Typography className={classes.badge}>{t(`idpCard.connectionType.${type}`)}</Typography>
      </span>
      <div className={classes.content}>{content()}</div>
    </div>
  );
};

export default ProvisioningStatus;
