import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  PrimaryButton,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { TenantData, TenantTypes } from 'App.types';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';

export interface TenantDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onClose: () => void;
  onSave: ({
    parentTenant,
    description,
    hostname,
    region,
    tenantType,
  }: Partial<TenantData>) => void;
  tenant?: Partial<TenantData>;
}

export const TenantDialog = (props: TenantDialogProps) => {
  const { id, open, onSave, onClose, tenant } = props;
  const [parentTenant, setParentTenant] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();
  const [hostname, setHostName] = React.useState<string>();
  const [region, setRegion] = React.useState<string>();
  const [tenantType, setTenantType] = React.useState<string>();
  const { t } = useTranslation();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    onSave({ parentTenant, description, hostname, region, tenantType });
  }

  function handleTenantType(event: any) {
    setTenantType(event.target.value);
  }

  React.useEffect(() => {
    if (open) {
      // Reset on open
      setParentTenant(tenant?.parentTenant);
      setDescription(tenant?.description);
      setHostName(tenant?.hostname);
      setRegion(tenant?.region);
      setTenantType(tenant?.tenantType);
    }
  }, [open, tenant]);

  return (
    <Dialog id={id} open={open} onClose={onClose} aria-labelledby={`${id}-title`} maxWidth="md">
      <DialogTitle id={`${id}-title`} onClose={onClose}>
        {t('tenantInformation.pageTitle')}
      </DialogTitle>
      <form onSubmit={handleSubmit} data-testid="tenant-form">
        <DialogContent>
          <TextField
            id="edit-tenant-parent-input"
            label={t('tenantInformation.parentTenantId')}
            onChange={(event) => setParentTenant(event.target.value)}
            placeholder={t('tenantInformation.parentTenantId')}
            defaultValue={tenant?.parentTenant ?? ''}
            fullWidth
            autoFocus
          />
          <TextField
            id="edit-tenant-description-input"
            label={t('tenantInformation.description')}
            onChange={(event) => setDescription(event.target.value)}
            placeholder={t('tenantInformation.description')}
            defaultValue={tenant?.description ?? ''}
            fullWidth
          />
          <TextField
            id="edit-tenant-hostname-input"
            label={t('tenantInformation.hostname')}
            onChange={(event) => setHostName(event.target.value)}
            placeholder={t('tenantInformation.hostname')}
            defaultValue={tenant?.hostname ?? ''}
            fullWidth
          />
          <TextField
            id="edit-tenant-region-input"
            label={t('tenantInformation.region')}
            onChange={(event) => setRegion(event.target.value)}
            placeholder={t('tenantInformation.region')}
            defaultValue={tenant?.region ?? ''}
            fullWidth
          />
          <TextField
            id="edit-tenant-type-input"
            select
            label={t('tenantInformation.tenantType')}
            onChange={handleTenantType}
            placeholder={t('tenantInformation.tenantType')}
            value={tenantType}
            fullWidth
          >
            {TenantTypes.map((option) => (
              <MenuItem button onClick={handleTenantType} key={option} value={option}>
                {t(`tenantTypes.${option.toLowerCase()}`)}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={onClose}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
