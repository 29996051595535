import * as React from 'react';
import useRestApi from 'hooks/useRestApi';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { PaginatedTable } from 'components/PaginatedTable';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { IconButton } from '@bb-ui/react-library';
import { ChevronRightSpecial } from '@bb-ui/react-library/dist/internal/icons/small/ChevronRightSpecial';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { useRef } from 'react';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { Table } from '@bb-ui/react-library/dist/components/Table';
import { apiUrl } from '../../utils/apiUrl';
import { PageTemplate } from '../../components/PageTemplate';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  enabledCell: {
    width: '128px',
    whiteSpace: 'nowrap',
  },
  unavailableLabel: {
    color: theme.palette.link.disabled,
  },
  dialogTitle: {
    '& h1': {
      minWidth: 0,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  dialogContent: {
    overflowY: 'auto',
    height: 500,
  },
}));

interface License {
  licenseName: string;
  productName: string;
  description: string;
  productionEnabled: boolean;
  defaultMetadata?: Record<string, string>;
}

export const LicenseList: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);

  // License metadata dialog
  const [licenseShowMetadata, setLicenseShowingMetadata] = React.useState<License | null>(null);
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const id = 'license-list-metadata-dialog';
  const handleCloseDialog = () => {
    setLicenseShowingMetadata(null);
  };

  // Licence list datasource
  const {
    data: licensesData,
    error: licensesError,
    loading: licensesLoading,
  } = useRestApi(apiUrl('tenancy', 'licenses'));
  const licenses = React.useMemo<License[]>(() => licensesData?.results ?? [], [licensesData]);

  // Filter licenses with the searchbar value
  const search =
    (filter: string) => ({ licenseName }: License) => licenseName.toLowerCase().includes(filter.toLowerCase());

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedLicenses = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    // license name alphabetical sort
    if (sortColumnId === 'license') {
      return [...licenses].sort(
        (l1, l2) => orderSign * l1.licenseName.localeCompare(l2.licenseName),
      );
    }
    if (sortColumnId === 'productName') {
      return [...licenses].sort(
        (l1, l2) => orderSign * l1.productName.localeCompare(l2.productName),
      );
    }
    if (sortColumnId === 'productionEnabled') {
      const ord = (license: License) => (license.productionEnabled ? 1 : 0);
      return [...licenses].sort((l1, l2) => orderSign * (ord(l1) - ord(l2)));
    }

    return licenses;
  }, [licenses, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`licenseList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc' ?
        t('global.paginatedTable.ascending') :
        t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };
  let content: React.ReactElement;

  if (licensesLoading) {
    content = <LoadingIndicator data-testid="fnds-license-list-init" />;
  } else if (licensesError) {
    content = (
      <ErrorMessage
        title={t('licenseList.loadError')}
        message={licensesError.message}
        data-testid="fnds-license-list-error"
      />
    );
  } else if (!licensesData?.results || licensesData.results?.length === 0) {
    content = (
      <ErrorMessage
        title={t('licenseList.loadError')}
        message={t('licenseList.noData')}
        data-testid="fnds-license-list-no-data"
      />
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('licenseList.searchLabel') }}
        sortedData={sortedLicenses}
        search={search}
        noMatchesMessage={(searchExpression) => t('licenseList.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="license-list-table-header-license_name"
                columnId="license"
                tooltip={t('licenseList.sortByLicenseName')}
              >
                {t('licenseList.license')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="license-list-table-header-product_name"
                columnId="productName"
                tooltip={t('licenseList.sortByProductName')}
              >
                {t('licenseList.productName')}
              </SortableTableHeaderCell>
              <TableCell
                id="license-list-table-header-description"
                tabIndex={-1}
                role="columnheader"
              >
                {t('licenseList.description')}
              </TableCell>
              <SortableTableHeaderCell
                tableCellProps={{ className: classes.enabledCell }}
                id="license-list-table-header-production_enabled"
                columnId="productionEnabled"
                tooltip={t('licenseList.sortByProductionEnabled')}
              >
                {t('licenseList.productionEnabled')}
              </SortableTableHeaderCell>
              <TableCell
                id="license-list-table-header-metadata"
                tabIndex={-1}
                align="center"
                role="columnheader"
              >
                {t('licenseList.metadata')}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        renderRow={(license, index) => (
          <TableRow
            key={license.licenseName}
            aria-rowindex={index + 1}
            data-testid={`license-list-table-row-${license.licenseName}`}
          >
            <TableCell aria-colindex={1} aria-describedby="license-list-table-header-license_name">
              {license.licenseName}
            </TableCell>
            <TableCell aria-colindex={2} aria-describedby="license-list-table-header-product_name">
              {license.productName}
            </TableCell>
            <TableCell aria-colindex={3} aria-describedby="license-list-table-header-description">
              {license.description}
            </TableCell>
            <TableCell
              aria-colindex={4}
              aria-describedby="license-list-table-header-production_enabled"
              align="center"
            >
              {license.productionEnabled ? t('global.yes') : t('global.no')}
            </TableCell>
            <TableCell aria-colindex={5} aria-describedby="license-list-table-header-metadata">
              {Object.keys(license.defaultMetadata ?? {}).length !== 0 ? (
                <IconButton id={`${id}-button`} onClick={() => setLicenseShowingMetadata(license)}>
                  <Typography component="span" variant="inherit">
                    {t('licenseList.showMetadata')}
                  </Typography>
                  <ChevronRightSpecial />
                </IconButton>
              ) : (
                <Typography className={classes.unavailableLabel}>
                  {t('licenseList.noMetadata')}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return (
    <>
      <PageTemplate showTabs title={t('licenseList.pageTitle')} data-testid="license-list-page">
        {content}
      </PageTemplate>
      (
      <Dialog
        id={id}
        onClose={() => setLicenseShowingMetadata(null)}
        // work around to handle keyboard focus trap correctly in discard dialog
        // TODO: remove once handled in Bb-UI Dialog component
        onEntered={() => initialFocusRef.current && initialFocusRef.current.focus()}
        open={licenseShowMetadata !== null}
        maxWidth="lg"
        aria-labelledby="licenses-metadata-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          tabIndex={-1}
          className={classes.dialogTitle}
          onClose={handleCloseDialog}
          id={`${id}-title`}
        >
          {t('licenseList.dialogBox.heading', { licenseName: licenseShowMetadata?.licenseName })}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table>
            <TableHead>
              <TableCell id="license-dialog-table-header-default-metadata-key">
                {t('licenseList.dialogBox.key')}
              </TableCell>
              <TableCell id="license-dialog-table-header-default-metadata-value">
                {t('licenseList.dialogBox.value')}
              </TableCell>
            </TableHead>
            {Object.entries(licenseShowMetadata?.defaultMetadata || {}).map(
              ([key, value], index) => (
                <TableRow key={key} aria-rowindex={index + 1}>
                  <TableCell
                    aria-colindex={1}
                    aria-describedby="license-dialog-table-header-default-metadata-key"
                  >
                    <strong>{key}</strong>
                  </TableCell>
                  <TableCell
                    aria-colindex={2}
                    aria-describedby="license-dialog-table-header-default-metadata-value"
                  >
                    {value}
                  </TableCell>
                </TableRow>
              ),
            )}
          </Table>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleCloseDialog}>{t('global.close')}</PrimaryButton>
        </DialogActions>
      </Dialog>
      )
    </>
  );
};
