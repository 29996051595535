import React from 'react';
import {
  ListItem,
  ListItemText,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@bb-ui/react-library';
import { ExecutionLogEntry } from '@lct-jobs/core';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { JobCardList } from './basic/JobCardList';
import { useJobExecutionContext } from '../../../contexts/JobExecutionContext';

const JobLogItem: React.FC<{ entry: ExecutionLogEntry; index: number }> = (props) => {
  const logTime = moment(props.entry.timestamp);
  return (
    <ListItem>
      <ListItemText
        primary={`[${props.entry.level}] ${props.entry.message}`}
        secondary={logTime.format()}
      />
      {props.entry.data && (
        <ExpansionPanel>
          <ExpansionPanelSummary aria-controls="" id={`LOG#${props.index}`} />
          <ExpansionPanelDetails>
            <ReactJson
              src={props.entry.data}
              enableClipboard={false}
              name={false}
              displayDataTypes={false}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
    </ListItem>
  );
};

export const JobLogsBox: React.FC = () => {
  const { execution } = useJobExecutionContext();

  return (
    <JobCardList title="Execution Logs">
      {(execution?.logs ?? []).map((entry, index) => (
        <JobLogItem entry={entry} index={index} />
      ))}
    </JobCardList>
  );
};
