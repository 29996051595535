// See https://github.com/blackboard-foundations/bb-tenant/blob/master/openapi/Tenant.v1.yaml

export const TenantTypes = ['Customer', 'Learn', 'Student', 'Reach', 'Raise'];

export interface TenantData {
  id: string;
  name: string;
  description: string;
  uriComponent?: string;
  clientId?: string;
  authProviderId?: string;
  isHidden?: boolean;
  isDeleted?: boolean;
  hostname?: string;
  parentTenant?: string;
  tenantType?: string;
  region?: string;
  displayName?: string;
  fleetId?: string;
  tenantChildren?: string[];
}

/**
 * Possible types of Auth0 connections:
 * - 'LearnConnector' equates to Foundations Only in the UI.
 * - 'SAML' for SAML connections
 * - 'AzureAD' for Azure AD connections
 */
export type IdpConnectionType = 'LearnConnector' | 'SAML' | 'AzureAD';
export interface IdentityProvidersData {
  id: string;
  name: string;
  type: IdpConnectionType;
  displayName: string;
  description: string;
}

export interface ClientContact {
  email?: string;
  phone?: string;
}
export interface ClientData {
  id: string;
  name: string;
  primaryMarket: string;
  secondaryMarket: string;
  city: string;
  state: string;
  country: string;
  externalIds: { [key: string]: string };
  contact: ClientContact[];
  team: object[];
}

export interface LearnConfigDetails {
  id: string;
  tenantId: string;
  domain: string;
  externalId: string;
  description: string;
  configuration: Record<string, any>;
}

// TODO: Remove these definitions (IJobPreview, IInputPaths, IJobSummary) once the actual types are defined in the job-service package
export interface IJobPreview {
  title: string;
  contentPath?: string;
  chipText?: string;
  chipLinkPath?: string;
  chipTenantIdLinkPath?: string;
}
export interface IInputPaths {
  title: string;
  subtitle?: string;
  tenant?: string;
  link?: string;
}

export interface IJobSummary {
  widgetId: string;
  inputPaths: IInputPaths;
}
