import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  PrimaryButton,
  TextField,
  Theme,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { AzureADProvisionersPost } from './TenantIdps.context';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
  },
}));

export interface ConnectAzureADIdpDialogProps extends DialogProps {
  onClose: () => void;
  onPost: (body: AzureADProvisionersPost) => void;
}

export const ConnectAzureADIdpDialog = (props: ConnectAzureADIdpDialogProps) => {
  const { id, open, onPost, onClose } = props;
  const fields: Partial<keyof AzureADProvisionersPost>[] = ['displayName', 'clientId', 'azureADDomain', 'clientSecret'];
  const [invalidFields, setInvalidFields] = React.useState<Partial<keyof AzureADProvisionersPost>[]>();
  const [values, setValues] = React.useState<AzureADProvisionersPost>(
    fields.reduce((acc, value) => ({ ...acc, [value]: '' }), {
      authBroker: 'Auth0',
      authBrokerConnectionType: 'AzureAD',
    } as AzureADProvisionersPost),
  );
  const classes = useStyles(props);
  const { t } = useTranslation();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const invalidFields = fields.filter(field => !values[field]);
    event.preventDefault();

    if (invalidFields.length) {
      setInvalidFields(invalidFields);
    } else {
      onPost(values);
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    if (invalidFields) {
      // Clear error message for current field
      setInvalidFields(invalidFields.filter(error => error !== name));
    }
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  }

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      maxWidth="md"
    >
      <DialogTitle onClose={onClose} id={`${id}-title`}>{t('tenantConnectAzureADIdpDialog.title')}</DialogTitle>
      <form onSubmit={handleSubmit} noValidate data-testid="azure-ad-connection-form">
        <DialogContent className={classes.content}>
          {fields.map(field => (
            <TextField
              id={`${field}-input`}
              name={field}
              label={t(`tenantConnectAzureADIdpDialog.${field}`)}
              onChange={handleOnChange}
              value={values[field]}
              error={invalidFields?.includes(field)}
              helperText={invalidFields?.includes(field) && t(`tenantConnectAzureADIdpDialog.errors.${field}`)}
              fullWidth
              required
              key={field}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={onClose}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton type="submit">
            {t('tenantConnectAzureADIdpDialog.startConnection')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConnectAzureADIdpDialog;
