import { AddCircle } from '@bb-ui/icons/dist/medium/AddCircle';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateDomainDialog } from 'dialogs/CreateDomainDialog';

interface AddDomainButtonProps {
  fetchDomains: () => void;
}

export const AddDomainButton: React.FunctionComponent<AddDomainButtonProps> = ({
  fetchDomains,
}): JSX.Element => {
  const { t } = useTranslation();
  const [newDomainDialogIsOpen, setNewDomainDialogIsOpen] = React.useState(false);

  return (
    <>
      <PrimaryButton
        startIcon={<AddCircle />}
        data-testid="header-add-button"
        onClick={() => setNewDomainDialogIsOpen(true)}
      >
        {t('createDomainDialog.title')}
      </PrimaryButton>
      {newDomainDialogIsOpen && (
        <CreateDomainDialog
          id="create-domain-dialog"
          onClose={() => setNewDomainDialogIsOpen(false)}
          open={newDomainDialogIsOpen}
          fetchDomains={fetchDomains}
        />
      )}
    </>
  );
};
