import * as React from 'react';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { ReleaseStageDialogData } from 'pages/ReleaseStages/ReleaseStages.types';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'hooks/useSnackbar';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';

const styles = (theme: Theme) =>
  createStyles({
    addReleaseStageInput: {
      margin: theme.spacing(1, 0, 1),
    },
  });

export const useStyles = makeStyles(styles);

const emptyReleaseStageDate: ReleaseStageDialogData = {
  releaseStage: '',
  learnVersion: '',
};

export interface CreateReleaseStageDialogProps extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const CreateReleaseStageDialog: React.FunctionComponent<CreateReleaseStageDialogProps> = (
  props,
) => {
  const { id, open, onClose, onSuccess } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('lct', 'releaseStage/'), { manual: true });

  const [showErrors, setShowErrors] = React.useState(false);
  const [formData, setFormData] = React.useState<ReleaseStageDialogData>(emptyReleaseStageDate);
  const classes = useStyles(props);

  const validateField = (fieldValue: string, fieldName: string): string => {
    if (!fieldValue.trim()) {
      return t(`releaseStages.createReleaseStageDialog.${fieldName}ErrorMessage`);
    }
    return '';
  };

  const getFieldError = (fieldKey: keyof ReleaseStageDialogData): string => {
    return showErrors ? validateField(formData[fieldKey], fieldKey) : '';
  };

  function create(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);
    const errors = {
      releaseStage: validateField(formData.releaseStage, 'releaseStage'),
      learnVersion: validateField(formData.learnVersion, 'learnVersion'),
    };
    const hasErrors = Object.values(errors).some((error) => error !== '');
    if (hasErrors) {
      return;
    }
    doPost(formData);
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    setFormData(emptyReleaseStageDate);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('releaseStages.createReleaseStageDialog.succededMessage'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      onSuccess();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    formData.releaseStage,
    onSuccess,
    succeededRequests.length,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('releaseStages.createReleaseStageDialog.createFailed', {
          message: failedRequests[0].error.message,
          ReleaseStage: formData.releaseStage,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    formData.releaseStage,
    t,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('releaseStages.createReleaseStageDialog.title')}
      </DialogTitle>
      <form onSubmit={create} noValidate data-testid="create-release-stage-form">
        <DialogContent>
          <TextField
            id={`${id}-release-stage-name`}
            data-testid={`${id}-release-stage-name`}
            label={t('releaseStages.createReleaseStageDialog.name')}
            onChange={(event) =>
              setFormData({ ...formData, releaseStage: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('releaseStages.createReleaseStageDialog.namePlaceHolder')}
            required
            error={!!getFieldError('releaseStage')}
            helperText={getFieldError('releaseStage')}
            className={classes.addReleaseStageInput}
          />
          <TextField
            id={`${id}-release-stage-version`}
            data-testid={`${id}-release-stages-version`}
            label={t('releaseStages.createReleaseStageDialog.version')}
            onChange={(event) =>
              setFormData({ ...formData, learnVersion: event.target.value.trim() })
            }
            fullWidth
            placeholder={t('releaseStages.createReleaseStageDialog.versionPlaceHolder')}
            required
            error={!!getFieldError('learnVersion')}
            helperText={getFieldError('learnVersion')}
            className={classes.addReleaseStageInput}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-create-release-stage-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-create-release-stage-dialog"
            disabled={loadingRequests}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateReleaseStageDialog;
