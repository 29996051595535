import {
  FeatureFlagDefinitionData,
  IFeatureFlagLocalizedLabel,
  useFeatureFlagDefinitions,
  FeatureFlagScheduleTask,
  useFeatureFlagScheduleTasks,
} from '@bb-config-ui/feature-flags';
import { Edit } from '@bb-ui/icons/dist/small';
import {
  CircularProgress,
  IconButton,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TenantData } from 'App.types';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PaginatedTable } from 'components/PaginatedTable';
import { useAuthContext } from 'contexts/AuthContext';
import useRestApi from 'hooks/useRestApi';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { apiUrl } from 'utils/apiUrl';
import { FeatureFlagFleetsDialog } from './FeatureFlagFleetsDialog';
import fleetData from './fleets.json';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  tableCell: {
    width: '17%',
  },
  tenantsTableCell: {
    paddingLeft: theme.spacing(4),
  },
  heading: {
    marginBottom: theme.spacing(1),
    display: 'inline-flex',
    width: '100%',
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(0.5),
    left: theme.spacing(1),
  },
  sectionWarning: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(-3),
    maxWidth: theme.spacing(100),
  },
}));

interface Fleet {
  id: string;
  name: string;
  region: string;
  visibility?: boolean;
  tenants?: string[];
}

interface IFleetsTenantData {
  [fleetId: string]: {
    tenants: string[];
  };
}

// Read fleetId on each tenant and create a data structure to be easily indexed
export const getFleetIdsFromTenants = (tenants: TenantData[]) => tenants.reduce((fleetsTenant, tenant) => {
  const fleetsTenantAccumulator = { ...fleetsTenant };
  if (tenant.fleetId) {
    if (tenant.fleetId in fleetsTenantAccumulator) {
      fleetsTenantAccumulator[tenant.fleetId].tenants.push(tenant.id);
    } else {
      fleetsTenantAccumulator[tenant.fleetId] = {
        tenants: [tenant.id],
      };
    }
  }
  return fleetsTenantAccumulator;
}, {} as IFleetsTenantData);

export const FeatureFlagFleets: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation('home');
  const classes = useStyles(props);
  const { flagKey } = useParams<{ flagKey: string }>();
  const { idToken } = useAuthContext();
  const parseIdToken = idToken ?? '';
  const tenantRegions = useAppConfigContext().tenantSupportedRegions ?? [];
  const featureFlagRegions = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const { enqueueSnackbar } = useSnackbar();
  let content: React.ReactElement;
  const {
    flagDefinitions,
    error: errorFFD,
    loading: loadingFFD,
    loadingRequests,
    succeededRequests,
    failedRequests,
    clearFailedRequests,
    clearSucceededRequests,
    updateFeatureFlagDefinition,
  } = useFeatureFlagDefinitions({ accessToken: parseIdToken });
  const { data: tenantsData } = useRestApi(apiUrl('tenancy', 'tenants/searchable'));
  const tenants = React.useMemo<TenantData[]>(() => {
    // Filter Tenants based on Tenant supported regions for this deployment
    const allTenantsData: TenantData[] = tenantsData?.results ?? [];
    const filteredTenantsData: TenantData[] = [];
    allTenantsData.forEach((tenant) => {
      if (
        !tenant.region ||
        (tenantRegions.includes(tenant.region) && featureFlagRegions.includes(tenant.region))
      ) {
        filteredTenantsData.push(tenant);
      }
    });
    return filteredTenantsData;
  }, [tenantsData, tenantRegions, featureFlagRegions]);
  const tenantGroup = 'Fleet';
  const {
    error: errorFFST,
    loading: loadingFFST,
    getFeatureFlagScheduleTask,
    createFeatureFlagScheduleTask,
    updateFeatureFlagScheduleTask,
    deleteFeatureFlagScheduleTask,
    succeededRequests: succeededRequestsFFST,
    clearSucceededRequests: clearSucceededRequestsFFST,
    failedRequests: failedRequestsFFST,
    clearFailedRequests: clearFailedRequestsFFST,
    loadingRequests: loadingRequestsFFST,
  } = useFeatureFlagScheduleTasks({
    accessToken: parseIdToken,
    flagKey,
    tenantGroup,
  });
  const [fleets, setFleets] = React.useState<Fleet[]>([]);
  const localLanguage = i18n.language ? i18n.language.replace('-', '_') : 'en_US';
  const [selectedFleet, setSelectedFleet] = React.useState<Fleet>({
    id: '0',
    name: '',
    region: '',
  });
  const [isVisibilityDialogOpen, toggleVisibilityDialog] = React.useState<boolean>(false);
  const [currentFeatureFlag, setCurrentFeatureFlag] = React.useState<FeatureFlagDefinitionData>();
  const [scheduleFlagChange, setScheduleFlagChange] = React.useState<boolean>(false);
  const [selectedScheduleTask, setSelectedScheduleTask] = React.useState<FeatureFlagScheduleTask>();
  // Manage state for updates done in multiple flag values
  const [batchUpdateLoading, setBatchUpdateLoading] = React.useState<boolean>(false);
  const [batchUpdateError, setBatchUpdateError] = React.useState<Error>();
  const [batchUpdateResponse, setBatchUpdateResponse] = React.useState();

  const flag: FeatureFlagDefinitionData | undefined = flagDefinitions?.find(
    (el: FeatureFlagDefinitionData) => el?.flagKey === flagKey,
  );

  // This is used to localize the Feature Flag name filtering by local language or by default in "en_US"
  const findLabelByLocale = React.useCallback(
    (labels: IFeatureFlagLocalizedLabel[]) => {
      let labelWithLanguage = labels?.find((data) => data.locale === localLanguage);

      if (!labelWithLanguage) {
        labelWithLanguage = labels?.find((data) => data.locale === 'en_US');
      }

      return labelWithLanguage ? labelWithLanguage?.label : '';
    },
    [localLanguage],
  );

  const batchUpdateState = (isLoading?: boolean, response?: any, error?: Error) => {
    setBatchUpdateLoading(isLoading ?? false);
    setBatchUpdateResponse(response);
    setBatchUpdateError(error);
  };

  React.useEffect(() => {
    if (!currentFeatureFlag) {
      setCurrentFeatureFlag(flag);
    }
    const fleetsTenantData = getFleetIdsFromTenants(tenants);
    // Set fleets list using json data and filtering by the fleetIds found
    // in tenants data. Also set the list of tenants for each fleet.
    setFleets(
      fleetData.reduce((filteredFleet, fleet) => {
        // Filter Fleets based on Tenant supported regions for this deployment
        if (
          tenantRegions.includes(fleet.region.toLowerCase()) &&
          featureFlagRegions.includes(fleet.region.toLowerCase())
        ) {
          if (fleet.id in fleetsTenantData) {
            filteredFleet.push({
              id: fleet.id.toString(),
              name: fleet.name,
              region: fleet.region.toLowerCase(),
              visibility:
                currentFeatureFlag?.visibility?.visible ||
                currentFeatureFlag?.visibility.criteria?.regions?.includes(
                  fleet.region.toLowerCase(),
                ) ||
                currentFeatureFlag?.visibility.criteria?.fleets?.includes(fleet.id.toString()),
              tenants: fleetsTenantData[fleet.id].tenants,
            });
          }
        }
        return filteredFleet;
      }, [] as Fleet[]),
    );
  }, [currentFeatureFlag, flag, tenants, tenantRegions, featureFlagRegions]);

  // SORTING
  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedFleets = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    return sortColumnId ? orderBy(fleets, [sortColumnId], [sortDirection ?? true]) : fleets;
  }, [fleets, sortParams]);

  // SEARCH
  const search =
    (filter: string) => ({ name, id }: Fleet) => {
      let nameId = name.toLowerCase().includes(filter.toLowerCase());
      if (!nameId) {
        nameId = id.toString().toLowerCase().includes(filter.toLowerCase());
      }
      return nameId ?? false;
    };

  const getScheduleTaskMessage = React.useCallback(
    (success: boolean, fleetName: string, requestMethod: string) => {
      let operation;
      switch (requestMethod) {
        case 'post': {
          operation = 'created';
          break;
        }
        case 'patch': {
          operation = 'updated';
          break;
        }
        case 'delete': {
          operation = 'deleted';
          break;
        }
        default: {
          throw new Error(`Unexpected request method ${requestMethod}`);
        }
      }
      const translationKey = success ?
        'featureFlagGeneral.manageScheduleSettingsSuccess' :
        'featureFlagGeneral.manageScheduleSettingsFail';

      return t(translationKey, { operation, item: fleetName });
    },
    [t],
  );

  React.useEffect(() => {
    if (
      (loadingRequests && !batchUpdateLoading) ||
      loadingRequestsFFST ||
      batchUpdateResponse ||
      batchUpdateError
    ) {
      toggleVisibilityDialog(false);
    }
    if (
      currentFeatureFlag &&
      (succeededRequests.length > 0 || batchUpdateResponse) &&
      !batchUpdateLoading &&
      !loadingRequests
    ) {
      const theCurrentFlag = succeededRequests.pop()?.response.data;
      if (theCurrentFlag) {
        setCurrentFeatureFlag(theCurrentFlag);
      }
      clearSucceededRequests();
      if (batchUpdateResponse) {
        setBatchUpdateResponse(undefined);
      }
      enqueueSnackbar(
        t('featureFlagGeneral.manageSettingsSuccess', {
          flagName: findLabelByLocale(currentFeatureFlag.labels),
          item: selectedFleet.name,
        }),
        { variant: 'info' },
      );
    }

    if (succeededRequestsFFST.length > 0 && !loadingRequestsFFST) {
      const response = succeededRequestsFFST.pop()?.response;
      // Explicitly set current schedule task. For deleted schedule task, it will be set to undefined
      setSelectedScheduleTask(response.data);
      clearSucceededRequestsFFST();
      enqueueSnackbar(getScheduleTaskMessage(true, selectedFleet.name, response.config.method), {
        variant: 'info',
      });
    }

    if (
      (failedRequests.length > 0 || batchUpdateError) &&
      !batchUpdateLoading &&
      !loadingRequests
    ) {
      clearFailedRequests();
      setBatchUpdateError(undefined);
      enqueueSnackbar(t('featureFlagGeneral.updateFeatureFlagFail'), { variant: 'error' });
    }

    if (failedRequestsFFST.length > 0 && !loadingRequestsFFST) {
      const error = failedRequestsFFST.pop()?.error;
      clearFailedRequestsFFST();
      enqueueSnackbar(getScheduleTaskMessage(false, selectedFleet.name, error.config.method), {
        variant: 'error',
      });
    }
  }, [
    clearFailedRequests,
    clearSucceededRequests,
    currentFeatureFlag,
    enqueueSnackbar,
    failedRequests,
    findLabelByLocale,
    selectedFleet,
    succeededRequests,
    t,
    batchUpdateError,
    batchUpdateResponse,
    batchUpdateLoading,
    loadingRequests,
    selectedScheduleTask,
    clearFailedRequestsFFST,
    clearSucceededRequestsFFST,
    failedRequestsFFST,
    succeededRequestsFFST,
    loadingRequestsFFST,
    getScheduleTaskMessage,
  ]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`featureFlagDefinitionFleets.${columnId}`);
    const orderLabel =
      sortDirection === 'asc' ?
        t('global.paginatedTable.ascending') :
        t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  if (errorFFD || errorFFST) {
    content = (
      <ErrorMessage
        data-testid="fnds-feature-flag-fleets-list-error"
        title={t('featureFlagGeneral.loadError')}
        message={errorFFD ? errorFFD?.message : errorFFST?.message}
        variant="block"
      />
    );
  } else if (loadingFFD || loadingFFST) {
    content = <LoadingIndicator data-testid="fnds-feature-flag-fleets-list-init" />;
  } else if (currentFeatureFlag && fleets.length) {
    content = (
      <div data-testid="feature-flags-definition-fleets-data">
        <FeatureFlagFleetsDialog
          isDialogOpen={isVisibilityDialogOpen}
          dialogToggle={toggleVisibilityDialog}
          scheduleFlagChange={scheduleFlagChange}
          scheduleTask={selectedScheduleTask}
          flagDefinition={currentFeatureFlag}
          updateFeatureFlagDefinition={updateFeatureFlagDefinition}
          createFeatureFlagScheduleTask={createFeatureFlagScheduleTask}
          updateFeatureFlagScheduleTask={updateFeatureFlagScheduleTask}
          deleteFeatureFlagScheduleTask={deleteFeatureFlagScheduleTask}
          selectedFleet={selectedFleet}
          flagName={findLabelByLocale(currentFeatureFlag.labels)}
          batchUpdateState={batchUpdateState}
        />
        <PaginatedTable
          id="feature-flags-definition-fleets-table"
          getSortChangedAriaMessage={getAriaSortMessage}
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
          sortedData={sortedFleets}
          search={search}
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="feature-flag-fleets-table-header-fleet_name"
                  columnId="name"
                  tooltip={t('featureFlagDefinitionFleets.sortByFleetName')}
                >
                  {t('featureFlagGeneral.name')}
                </SortableTableHeaderCell>
                <TableCell id="feature-flag-fleets-table-header-tenants-count" role="columnheader">
                  {t('featureFlagGeneral.tenantsCountTableHeader')}
                </TableCell>
                <TableCell
                  id="feature-flag-fleets-table-header-fleet_client_admin_permissions"
                  role="columnheader"
                >
                  {t('featureFlagGeneral.clientAdminPermissions')}
                </TableCell>
                <TableCell
                  id="feature-flag-fleets-table-header-fleet_scheduled_change"
                  role="columnheader"
                >
                  {t('featureFlagGeneral.scheduledChange')}
                </TableCell>
                <TableCell id="feature-flag-fleets-table-header-fleet_settings" role="columnheader">
                  {t('featureFlagGeneral.settings')}
                </TableCell>
                <TableCell
                  id="feature-flag-fleets-table-header-fleet_schedule_settings"
                  role="columnheader"
                >
                  {t('featureFlagGeneral.scheduleSettings')}
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(fleet: Fleet, index) => (
            <TableRow
              aria-rowindex={index + 1}
              data-testid={`feature-flag-fleet-table-row-${index}`}
              role="row"
              key={index}
            >
              <TableCell
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="feature-flag-fleets-table-header-fleet_name"
              >
                {fleet.name}
              </TableCell>
              <TableCell
                aria-colindex={2}
                tabIndex={-1}
                className={classes.tenantsTableCell}
                aria-describedby="feature-flag-fleets-table-header-tenants-count"
              >
                {fleet.tenants?.length ?? 0}
              </TableCell>
              <TableCell
                aria-colindex={3}
                tabIndex={-1}
                aria-describedby="feature-flag-fleets-table-header-fleet_client_admin_permissions"
              >
                {fleet.visibility ?
                  t('featureFlagGeneral.clientAdminPermissionsEditViewOption') :
                  t('featureFlagGeneral.none')}
              </TableCell>
              <TableCell
                aria-colindex={4}
                tabIndex={-1}
                aria-describedby="feature-flag-fleets-table-header-fleet_scheduled_change"
              >
                {getFeatureFlagScheduleTask(fleet.id)?.scheduleDate ??
                  t('featureFlagGeneral.notScheduled')}
              </TableCell>
              <TableCell
                aria-colindex={5}
                tabIndex={-1}
                className={classes.tableCell}
                aria-describedby="feature-flag-fleets-table-header-fleet_settings"
              >
                <IconButton
                  aria-haspopup="dialog"
                  aria-label={t('featureFlagGeneral.moreInformation')}
                  data-testid="fleet-edit-btn"
                  onClick={() => {
                    setSelectedFleet(fleet);
                    setScheduleFlagChange(false);
                    setSelectedScheduleTask(undefined);
                    toggleVisibilityDialog(true);
                  }}
                >
                  {loadingRequests > 0 && selectedFleet.id === fleet.id ? (
                    <CircularProgress
                      data-testid="fleet-edit-btn-circular-progress"
                      ariaLabel={t('featureFlagDefinitionFleets.circularProgress') ?? ''}
                      size="small"
                    />
                  ) : (
                    <Edit />
                  )}
                </IconButton>
              </TableCell>
              <TableCell
                aria-colindex={6}
                tabIndex={-1}
                className={classes.tableCell}
                aria-describedby="feature-flag-fleets-table-header-fleet_schedule_settings"
              >
                <IconButton
                  aria-haspopup="dialog"
                  aria-label={t('featureFlagGeneral.moreInformation')}
                  data-testid="fleet-edit-schedule-btn"
                  onClick={() => {
                    setSelectedFleet(fleet);
                    setScheduleFlagChange(true);
                    setSelectedScheduleTask(getFeatureFlagScheduleTask(fleet.id));
                    toggleVisibilityDialog(true);
                  }}
                >
                  {loadingRequestsFFST > 0 && selectedFleet.id === fleet.id ? (
                    <CircularProgress
                      data-testid="fleet-edit-schedule-btn-circular-progress"
                      ariaLabel={t('featureFlagDefinitionFleets.circularProgress') ?? ''}
                      size="small"
                    />
                  ) : (
                    <Edit />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    );
  } else if (!fleets.length) {
    content = (
      <Typography data-testid="fnds-feature-flag-fleets-list-no-fleetId-match">
        {t('featureFlagGeneral.noFleetIdMatch')}
      </Typography>
    );
  } else {
    content = (
      <Typography data-testid="fnds-feature-flag-fleets-list-no-data">
        {t('featureFlagGeneral.noData')}
      </Typography>
    );
  }

  return (
    <div data-testid="feature-flag-fleets-list-page">
      <div>
        <Typography className={classes.sectionWarning} variant="subtitle2">
          {t('featureFlagDefinitionFleets.sectionWarning')}
        </Typography>
        <Typography className={classes.heading} component="h2" variant="h2">
          {t('featureFlagDefinitionFleets.fleetsLabel')}
        </Typography>
        {content}
      </div>
    </div>
  );
};
