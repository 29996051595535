import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { createStyles, getColor, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  unavailableLabel: {
    color: getColor('link.disabled', theme),
  },
}));

export interface TenantLicenseMetadataControlProps {
  id: string;
  hasMetadata: boolean;
  disabled: boolean;
  onEditMetadata: () => void;
}

export const TenantLicenseMetadataControl: React.FunctionComponent<TenantLicenseMetadataControlProps> = (props) => {
  const { t } = useTranslation();
  const { hasMetadata, disabled, onEditMetadata } = props;
  const classes = useStyles();

  return hasMetadata ? (
    <IconButton onClick={onEditMetadata} disabled={disabled}>
      <Edit />
      <Typography component="span" variant="inherit">
        {t('tenantLicenses.editMetadata')}
      </Typography>
    </IconButton>
  ) :
    <Typography className={classes.unavailableLabel}>{t('tenantLicenses.metadataNotConnected')}</Typography>;
};

export default TenantLicenseMetadataControl;
