import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageTemplate } from 'components/PageTemplate';
import {
  TenantContext,
  TenantContextProps, TenantContextProvider,
} from 'contexts/TenantContext/TenantContext';
import { useAuthorization } from 'hooks/useAuthorization';
import { useRestApi } from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { apiUrl } from 'utils/apiUrl';
import { GroupMappingCard } from './GroupMappingCard';
import { IdpActions } from './IdpActions';
import { IdpSettingsCard } from './IdpSettingsCard';
import { IdentityProvider, PublicAuth0BrokerData } from './TenantIdp.types';
import { IdpConnectionCard } from "./IdpConnectionCard";

export const TenantIdp: React.FunctionComponent = () => {
  const { tenantId, idpId } = useParams<{ tenantId: string; idpId: string }>();
  const { hasPermission } = useAuthorization();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    data: idpData,
    error: idpError,
    fetch: fetchIdps,
  } = useRestApi<{results: IdentityProvider[]}>(
    apiUrl('sso', `tenants/${tenantId}/identityProviders`),
  );

  const currentIdp = React.useMemo<IdentityProvider | undefined>(
    () => idpData?.results.find((idp: IdentityProvider) => idp.id === idpId),
    [idpData, idpId],
  );

  const idpConnectionName =
      (currentIdp?.brokerData as PublicAuth0BrokerData | undefined)?.identityProviderName;

  const canDeleteIdp = hasPermission('identity-provider', 'delete');
  const canSeeGroupMappings = hasPermission('group-mapping', 'read');

  async function handleDeleteIdp() {
    // After delete, our Axios cache is now stale. We need to refresh it, then
    // navigate to the IDP list (which will now show the IDP gone).

    try {
      await fetchIdps();
    } catch (err) {
      console.error('Refreshing IDPs failed, continuing', err);
    }

    history.push(`/tenants/${tenantId}/auth`);
  }

  return (
    <TenantContextProvider tenantId={tenantId!}>
      <TenantContext.Consumer>
        {(value: TenantContextProps) => (
          <>
            {value.loading && (
              <LoadingIndicator
                theme="dark"
                variant="fill-window"
                data-testid="loading-tenant"
              />
            )}
            {!value.loading && (
              <PageTemplate
                title={
                  (currentIdp && currentIdp.displayName) ||
                  t('tenantIdp.notFoundTitle')
                }
                subtitle={t('tenantIdp.pageSubtitle', { idpId })}
                breadcrumbProps={
                  value.tenant &&
                  currentIdp && {
                    replaceText: {
                      'tenantProfile.pageTitle': value.tenant.name!,
                      'tenantIdp.pageTitle': currentIdp.displayName!,
                    },
                  }
                }
                errorMessage={
                  idpError && {
                    title: t('tenantIdp.doesNotExist'),
                    message: idpError.message,
                  }
                }
                headerControl={
                  currentIdp && canDeleteIdp && (
                    <IdpActions
                      idp={currentIdp}
                      onDeleteIdp={handleDeleteIdp}
                    />
                  )
                }
              >
                <IdpSettingsCard idp={currentIdp} />
                <IdpConnectionCard idpConnectionName={idpConnectionName} />
                {canSeeGroupMappings && <GroupMappingCard />}
              </PageTemplate>
            )}
          </>
        )}
      </TenantContext.Consumer>
    </TenantContextProvider>
  );
};
