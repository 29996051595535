import { IconButton, TableCell, TableRow, useEnqueueBbSnackbar } from '@bb-ui/react-library';
import { Start } from '@bb-ui/icons/dist/medium';
import * as React from 'react';
import { SnackbarKey } from 'notistack';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IJobConfig, CreateExecutionRequestBody, JobExecutionDto } from '@lct-jobs/core';
import { JobSubmitEvent, RunDialog } from './RunDialog';
import useRestApi from '../../hooks/useRestApi';
import { apiUrl } from '../../utils/apiUrl';
import { useSnackbar } from '../../hooks/useSnackbar';

export interface JobRowProps {
  job: IJobConfig;
  tenantId: string;
}

export const JobRow: React.FC<JobRowProps> = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [snackbarKey, setSnackbarKey] = React.useState<SnackbarKey | null>(null);
  const jobApi = useRestApi<JobExecutionDto>(
    apiUrl('lct', `tenants/${props.tenantId}/jobManager/executions`),
    { manual: true },
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const dismissSnackbar = useCallback(() => {
    if (snackbarKey) {
      closeSnackbar(snackbarKey);
      setSnackbarKey(null);
    }
  }, [closeSnackbar, snackbarKey]);

  const submitJob = (s: JobSubmitEvent) => {
    setDialogOpen(false);
    setSnackbarKey(enqueueSnackbar('Submitting Execution...', { variant: 'info' }));

    const body: CreateExecutionRequestBody = {
      jobInput: s.formData,
      jobId: props.job.jobId,
      providerId: s.selectedProvider?.providerId,
    };

    jobApi.doPost(body);
  };

  React.useEffect(() => {
    if (jobApi.failedRequests.length > 0) {
      jobApi.clearFailedRequests();
      dismissSnackbar();
      setSnackbarKey(enqueueSnackbar('Error submitting execution', { variant: 'error' }));
    }
  }, [closeSnackbar, dismissSnackbar, enqueueSnackbar, jobApi, jobApi.failedRequests]);

  const showSuccessSnackbar = useEnqueueBbSnackbar(
    { snackbarMessage: 'Execution Submitted' },
    {
      actionMessage: 'View Details',
      onActionClick: () => {
        const result = jobApi.succeededRequests.at(0);
        const tenantId: string | undefined = result?.response?.data?.tenantId;
        const executionId: string | undefined = result?.response?.data?.executionId;
        if (tenantId && executionId) {
          history.push(`/tenants/${tenantId}/executions/${executionId}`);
          closeSnackbar();
        }
      },
      closeButtonAriaLabel: 'close',
    },
  );

  React.useEffect(() => {
    if (jobApi.succeededRequests.length > 0) {
      dismissSnackbar();
      showSuccessSnackbar();
      jobApi.clearSucceededRequests();
    }
  }, [dismissSnackbar, jobApi, showSuccessSnackbar]);

  return (
    <TableRow key={props.job.jobId}>
      <TableCell>{props.job.jobName}</TableCell>
      <TableCell>{props.job.jobDomain}</TableCell>
      <TableCell>{props.job.jobDescription}</TableCell>
      <TableCell>
        <IconButton onClick={() => setDialogOpen(true)}>
          <Start />
          {dialogOpen && (
            <RunDialog
              job={props.job}
              tenantId={props.tenantId}
              closeDialog={() => setDialogOpen(false)}
              submitJob={submitJob}
            />
          )}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
