import {
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  PrimaryButton,
  TextField,
  Theme,
  Typography,
  useSnackbar,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import useRestApi from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { IdentityProvider } from '../TenantIdp.types';

export interface DeleteIdpDialogProps extends DialogProps {
  idp: IdentityProvider;
  onClose: (idpDeleted: boolean) => void;
  tenantId: string;
  tenantName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'grid',
    gridGap: '20px',
  },
  primaryButton: {
    backgroundColor: theme.palette.error.main,
  },
  title: {
    color: theme.palette.error.main,
  },
}));

// This is modelled heavily after CreateGroupDialog.

export const DeleteIdpDialog: React.FC<DeleteIdpDialogProps> = (props) => {
  const { id, idp, onClose, tenantId, tenantName, ...other } = props;
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = React.useState('');
  const [showErrors, setShowErrors] = React.useState(false);
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doDelete,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('sso/admin', `tenants/${tenantId}/identityProviders`), { manual: true });

  const confirmPhrase = t('deleteIdpDialog.confirmPhrase');
  let confirmationError: string | null = null;

  if (confirmation === '') {
    confirmationError = t('deleteIdpDialog.confirmEmpty', { confirmPhrase });
  } else if (confirmation !== confirmPhrase) {
    confirmationError = t('deleteIdpDialog.confirmIncorrect', { confirmPhrase });
  }

  // Called if we tried to delete or the user cancelled out of the dialog.

  const close = React.useCallback((idpDeleted: boolean) => {
    setShowErrors(false);
    onClose(idpDeleted);
  }, [onClose]);

  // If we successfully deleted, then we're done. We assume one request at a time.

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      // The response code should be 204--otherwise something actually went
      // wrong that isn't detectable by the hook.
      //
      // See note below about closing the dialog.

      const badRequest = succeededRequests.find((req) => req.response.status !== 204);

      if (badRequest) {
        enqueueSnackbar(
          t('deleteIdpDialog.deleteFailed', {
            message: badRequest.response.status,
            idpName: idp.displayName,
          }),
          { variant: 'error' },
        );
      } else {
        enqueueSnackbar(
          t('deleteIdpDialog.deleteSucceeded', {
            tenantName,
            idpName: idp.displayName,
          }),
          { variant: 'info' },
        );
      }

      clearSucceededRequests();
      close(true);
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    idp.displayName,
    succeededRequests,
    succeededRequests.length,
    t,
    tenantName,
  ]);

  // If there was an error deleting, show a snackbar notification. We close
  // because otherwise the snackbar will appear under the modal overlay, which
  // is difficult to see. We should revisit this.

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('deleteIdpDialog.deleteFailed', {
          message: failedRequests[0].error.message,
          idpName: idp.displayName,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close(false);
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    idp.displayName,
    t,
  ]);

  // Starts a deletion via API attempt.

  function handleDelete(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);

    if (confirmationError !== null) {
      return;
    }

    doDelete(idp.id);
  }

  return (
    <Dialog
      {...other}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      data-testid="delete-idp-dialog"
    >
      <DialogTitle
        className={classes.title}
        id={`${id}-title`}
        onClose={() => onClose(false)}
      >
        {t('deleteIdpDialog.title', { name: idp.displayName })}
      </DialogTitle>
      <form onSubmit={handleDelete} noValidate data-testid="delete-idp-dialog-form">
        <DialogContent className={classes.content}>
          <Typography>{t('deleteIdpDialog.explanation')}</Typography>
          <TextField
            error={showErrors && !!confirmationError}
            fullWidth
            helperText={showErrors && !!confirmationError && confirmationError}
            label={t('deleteIdpDialog.prompt', {
              confirmPhrase,
            })}
            onChange={(e) => setConfirmation(e.target.value)}
            value={confirmation}
            data-testid="delete-idp-dialog-confirmation-field"
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={() => close(false)}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton
            className={classes.primaryButton}
            disabled={loadingRequests}
            type="submit"
          >
            {t('global.delete')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
