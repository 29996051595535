import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { PaginatedTable } from 'components/PaginatedTable';
import { apiUrl } from 'utils/apiUrl';
import useRestApi from 'hooks/useRestApi';
import { PageTemplate } from 'components/PageTemplate';
import { get, orderBy } from 'lodash';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useHistory } from 'react-router-dom';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { IconButton } from '@bb-ui/react-library';
import { LearnInstance } from '@bb-learn-instances-service/common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageTitleContainer: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
);

export const LearnSites: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    data: siteData,
    loading: loadSiteData,
    error,
    fetch: fetchLearnSites,
  } = useRestApi(apiUrl('lct', 'instances/learnInstances'));

  const siteItems = siteData?.items ?? [];

  const sites = React.useMemo<LearnInstance[]>(() => siteItems ?? [], [siteItems]);

  const fetchLearnSitesData = React.useCallback(() => {
    fetchLearnSites();
  }, [fetchLearnSites]);

  React.useEffect(() => {
    fetchLearnSitesData();
  }, [fetchLearnSitesData]);

  const search =
    (filter: string) =>
    ({ InstallationId }: LearnInstance) =>
      !!InstallationId && InstallationId.toLowerCase().includes(filter.toLowerCase());

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedSites = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    if (sortColumnId && sortDirection && ['InstallationId', 'Status'].includes(sortColumnId)) {
      return orderBy(sites, [(site) => get(site, sortColumnId).toLowerCase()], sortDirection);
    }
    return sites;
  }, [sites, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`sitesList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (error) {
    content = (
      <ErrorMessage
        data-testid="learn-site-list-error"
        title={t('learnSites.loadError')}
        message={error.message}
        variant="block"
      />
    );
  } else if (loadSiteData) {
    content = <LoadingIndicator data-testid="site-list-init" />;
  } else if (sites.length === 0) {
    content = <Typography data-testid="learn-list-no-data">{t('learnSites.noData')}</Typography>;
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('learnSites.searchLabel') }}
        sortedData={sortedSites}
        search={search}
        noMatchesMessage={(searchExpression) => t('learnSites.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="site-list-table-header-name"
                columnId="InstallationId"
                tooltip={t('learnSites.sortByInstallationId')}
              >
                {t('learnSites.installationId')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="site-list-table-header-status"
                columnId="Status"
                tooltip={t('learnSites.sortByStatus')}
              >
                {t('learnSites.status')}
              </SortableTableHeaderCell>
              <TableCell role="columnheader" />
            </TableRow>
          </TableHead>
        )}
        renderRow={({ InstallationId, Status, TenantId }, index) => (
          <TableRow
            key={InstallationId}
            aria-rowindex={index + 1}
            data-testid={`site-list-table-row-${index}`}
          >
            <TableCell
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="site-list-table-header-name"
            >
              {InstallationId}
            </TableCell>
            <TableCell
              aria-colindex={1}
              tabIndex={-1}
              aria-describedby="site-list-table-header-status"
            >
              {Status}
            </TableCell>
            <TableCell
              aria-colindex={2}
              tabIndex={-1}
              aria-describedby="site-list-table-header-actions"
              align="right"
            >
              <IconButton
                aria-label={t('tenantList.moreInformation')}
                onClick={() => history.push(`/learn-site/${TenantId}/site`)}
              >
                <Typography component="span" variant="inherit">
                  {t('global.view')}
                </Typography>
                <ChevronRight />
              </IconButton>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return (
    <>
      <PageTemplate showTabs title={t('learnSites.pageTitle')} data-testid="license-list-page">
        {content}
      </PageTemplate>
    </>
  );
};
