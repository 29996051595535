import * as React from 'react';
import useRestApi from 'hooks/useRestApi';
import { IconButton, TableRow, Typography, TableCell, CircularProgress } from '@bb-ui/react-library';
import { FeatureFlagDefinitionData, FeatureStatusBadge, TenantFeatureFlagsUtils, FeatureFlagVisibility } from '@bb-config-ui/feature-flags';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Edit } from '@bb-ui/icons/dist/small';
import { TenantData } from 'App.types';

interface IFeatureFlagValueData {
  flagKey: string;
  scope: string;
  value: string;
  locked: boolean;
  overridden: boolean;
  tenantId: string;
}

export interface FeatureFlagTenantsTableRowProps {
  index: number;
  tenant: TenantData;
  updatedFlagValue?: IFeatureFlagValueData;
  currentTenant: TenantData;
  flagDefinition: FeatureFlagDefinitionData;
  loadingFeatureUpdate: boolean;
  toggleDialogOpen: (value: boolean) => void;
  setCurrentTenant: (value: TenantData) => void;
  setFlagValue: (value: IFeatureFlagValueData) => void;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  description: {
    marginTop: theme.spacing(1),
  },
  editCell: {
    textAlign: 'right',
  },
}));

export const FeatureFlagTenantsTableRow: React.FunctionComponent<FeatureFlagTenantsTableRowProps> = (props) => {
  const { index, tenant, flagDefinition, loadingFeatureUpdate, currentTenant, updatedFlagValue, toggleDialogOpen, setCurrentTenant, setFlagValue } = props;
  const { t } = useTranslation();
  const classes = useStyles(props);
  const apiPath = `/config/api/v1/tenants/${tenant.id}/featureFlags/flags/${flagDefinition.flagKey}/scopes/Tenant`;
  const { data: flagValue, loading, fetch } = useRestApi(apiPath);
  const [rowFlagValue, setRowFlagValue] = React.useState<IFeatureFlagValueData>(flagValue);

  React.useEffect(() => {
    fetch().then(response => {
      setRowFlagValue(response.data);
    });
  }, [fetch]);

  React.useEffect(() => {
    if (tenant.id === currentTenant?.id && updatedFlagValue) {
      setRowFlagValue(updatedFlagValue);
    }
  }, [currentTenant, tenant, updatedFlagValue, rowFlagValue]);

  const getFeatureStatus = () => rowFlagValue?.value ?? flagDefinition?.defaultValue;

  const getAdminPermissions = () => {
    const { isVisible } = TenantFeatureFlagsUtils.getVisibility(flagDefinition?.visibility as FeatureFlagVisibility, tenant.id, tenant?.region!);
    const isLocked = rowFlagValue?.locked ?? false;
    if (isVisible && isLocked) {
      return t("featureFlagGeneral.canView");
    }
    if (isVisible && !isLocked) {
      return t("featureFlagGeneral.canEdit");
    }
    return t("featureFlagGeneral.none");
  };

  return (
    <>
      <TableRow aria-rowindex={index + 1} data-testid={`feature-flag-tenant-table-row-${index}`} role="row">
        <TableCell
          aria-colindex={1}
          tabIndex={-1}
          aria-describedby="feature-flag-tenant-table-header-tenant_name"
          role="cell"
        >
          <Typography variant="h3">{tenant.name}</Typography>
          <Typography variant="body2" className={classes.description}>{tenant.id}</Typography>
        </TableCell>
        <TableCell
          aria-colindex={2}
          tabIndex={-1}
          aria-describedby="feature-flag-tenant-table-header-tenant_feature_status"
          role="cell"
        >
          <Typography component="span" variant="h4">
            {loading ? (
              <CircularProgress ariaLabel={t('global.loadingIndicator.loading').toString()} size="small" data-testid="loading-tenant-flag" />
            ) : (<FeatureStatusBadge
              featureStatus={getFeatureStatus()}
              label={getFeatureStatus() === "true" ? t("global.on") : t("global.off")}
            />)}
          </Typography>
        </TableCell>
        <TableCell
          aria-colindex={3}
          tabIndex={-1}
          aria-describedby="feature-flag-tenant-table-header-tenant_client_admin_permissions"
          role="cell"
        >
          <Typography variant="h4">
            {loading ? (
              <CircularProgress ariaLabel={t('global.loadingIndicator.loading').toString()} size="small" data-testid="loading-tenant-flag" />
            ) : (getAdminPermissions())}
          </Typography>
        </TableCell>
        <TableCell
          className={classes.editCell}
          aria-colindex={4}
          tabIndex={-1}
          aria-describedby="feature-flag-tenant-table-header-tenant_edit"
          role="cell"
        >
          {loadingFeatureUpdate && tenant.id === currentTenant.id ? (
            <CircularProgress ariaLabel={t('global.loadingIndicator.loading').toString()} size="small" data-testid="loading-tenant-flag" />
          ) : (
            <IconButton aria-haspopup="dialog" data-testid="submit-flag-definition-edit-btn" onClick={() => {
              toggleDialogOpen(true);
              setCurrentTenant(tenant);
              setFlagValue(rowFlagValue!);
            }}
            >
              <Edit />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default FeatureFlagTenantsTableRow;
