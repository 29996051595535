import {
  FeatureFlagDefinitionData,
  FeatureStatusBadge,
  IFeatureFlagLocalizedLabel,
  IFeatureFlagValueData,
  ITenantFeatureFlagValues,
  TenantFeatureFlagsUtils,
  useTenantFeatureFlags,
} from '@bb-config-ui/feature-flags';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import {
  CircularProgress,
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PaginatedTable } from 'components/PaginatedTable';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useTenantContext } from 'contexts/TenantContext';
import { useSnackbar } from 'hooks/useSnackbar';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { TenantFeatureFlagDialog } from './TenantFeatureFlagsDialog';

export interface ITenantFeatureFlagEdit {
  featureFlagName: string;
  flagDefinition: FeatureFlagDefinitionData;
  flagValue?: IFeatureFlagValueData;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  flagCircularProgress: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  searchBox: {
    margin: theme.spacing(1),
  },
}));

export const TenantFeatureFlags: React.FunctionComponent = () => {
  const classes = useStyles();
  const { idToken } = useAuthContext();
  const { t } = useTranslation();
  const { i18n } = useTranslation('home');
  const localLanguage = i18n.language ? i18n.language.replace('-', '_') : 'en_US';
  const { tenant } = useTenantContext();
  const tenantRegions = useAppConfigContext().tenantSupportedRegions ?? [];
  const featureFlagRegions = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const { enqueueSnackbar } = useSnackbar();
  const { tenantId } = useParams<{ tenantId: string }>();
  const {
    tenantFeatureFlagsData,
    loading,
    error,
    featureFlagValueUpdateStateHandler,
    featureFlagDefinitionUpdateStateHandler,
    clearUpdateResponses,
    clearUpdateErrors,
  } = useTenantFeatureFlags({
    accessToken: idToken as string,
    tenantId,
  });
  const [loadingFeatureUpdate, setLoadingFeatureUpdate] = React.useState(false);
  const [flagKeyChanging, setFlagKeyChanging] = React.useState<string>();
  const [tenantFeatureFlags, setTenantFeatureFlags] = React.useState<ITenantFeatureFlagValues[]>();
  const [isDialogOpen, toggleDialogOpen] = React.useState(false);
  const [selectedTenantFeatureFlag, setSelectedTenantFeatureFlag] =
    React.useState<ITenantFeatureFlagEdit>();

  const findLabelByLocale = React.useCallback(
    (labels?: IFeatureFlagLocalizedLabel[]) => {
      if (!labels) {
        return '';
      }
      let labelWithLanguage = labels.find((data) => data.locale === localLanguage);
      if (!labelWithLanguage) {
        labelWithLanguage = labels.find((data) => data.locale === 'en_US');
      }
      return labelWithLanguage ? labelWithLanguage?.label : '';
    },
    [localLanguage],
  );

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const formatDataList = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    if (!tenantFeatureFlags) {
      return [];
    }

    const formattedFeatureFlagsData = tenantFeatureFlags.map((tenantFeatureFlag) => {
      const { flagDefinition, flagValue } = tenantFeatureFlag;
      const flagElement: ITenantFeatureFlagEdit = {
        featureFlagName: findLabelByLocale(flagDefinition.labels),
        flagDefinition,
        flagValue,
      };
      return flagElement;
    });

    if (sortColumnId === 'featureFlag') {
      return Object.values(formattedFeatureFlagsData).sort(
        (l1, l2) => orderSign * l1.featureFlagName.localeCompare(l2.featureFlagName),
      );
    }

    return formattedFeatureFlagsData;
  }, [tenantFeatureFlags, sortParams, findLabelByLocale]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`featureFlagList.${columnId}`);
    const orderLabel =
      sortDirection === 'asc' ?
        t('global.paginatedTable.ascending') :
        t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  const search =
    (filter: string) => ({ featureFlagName }: ITenantFeatureFlagEdit) => featureFlagName.toLowerCase().includes(filter.toLowerCase());

  const getAdminPermissions = (tenantFeatureFlagEdit: ITenantFeatureFlagEdit) => {
    const { isVisible } = TenantFeatureFlagsUtils.getVisibility(
      tenantFeatureFlagEdit.flagDefinition.visibility,
      tenantId,
      tenant!.region!,
    );
    const isLocked = tenantFeatureFlagEdit.flagValue?.locked ?? false;
    if (isVisible && isLocked) {
      return t('featureFlagGeneral.canView');
    }
    if (isVisible && !isLocked) {
      return t('featureFlagGeneral.canEdit');
    }
    return t('featureFlagGeneral.none');
  };

  // TODO: This logic will not be needed when we have a separate GovCloud UI deployment and TenantsList page
  // filters based on Tenant regions supported for that deployment. However, it wouldn't hurt even if we decide to
  // retain the logic just in case.
  const isRegionSupported = () => (
    tenant &&
      tenant.region &&
      tenantRegions.includes(tenant.region) &&
      featureFlagRegions.includes(tenant.region)
  );

  const getFeatureStatus = (tenantFeatureFlagEdit: ITenantFeatureFlagEdit) => {
    const featureStatus =
      tenantFeatureFlagEdit.flagValue?.value ?? tenantFeatureFlagEdit.flagDefinition.defaultValue;
    return String(featureStatus);
  };

  React.useEffect(() => {
    setTenantFeatureFlags(tenantFeatureFlagsData);
  }, [tenantFeatureFlagsData]);

  // Add snackbar in response of changes done in dialog, Also update tenantFeatureFlags state in order
  // to reflect the most updated data finally update loadingFeatureUpdate state in order to show a loading
  // icon for the feature that is being updated.
  React.useEffect(() => {
    const flagDefinitionResponse = featureFlagDefinitionUpdateStateHandler?.flagDefinitionResponse;
    const flagValueResponse = featureFlagValueUpdateStateHandler?.flagValueResponse;

    const getFlagKeyLoading = () => {
      if (featureFlagDefinitionUpdateStateHandler.flagDefinitionLoading) {
        return featureFlagDefinitionUpdateStateHandler.flagDefinitionChange?.flagKey;
      }
      if (featureFlagValueUpdateStateHandler.flagValueLoading) {
        return featureFlagValueUpdateStateHandler.flagValueChange?.flagKey;
      }
      return null;
    };

    const getFlagKeyError = () => {
      if (featureFlagDefinitionUpdateStateHandler.flagDefinitionError) {
        return featureFlagDefinitionUpdateStateHandler.flagDefinitionChange?.flagKey;
      }
      if (featureFlagValueUpdateStateHandler.flagValueError) {
        return featureFlagValueUpdateStateHandler.flagValueChange?.flagKey;
      }
      return null;
    };

    const flagKeyInProgress = getFlagKeyLoading();
    if (flagKeyInProgress) {
      setFlagKeyChanging(flagKeyInProgress);
    }
    const isLoading =
      featureFlagDefinitionUpdateStateHandler.flagDefinitionLoading ||
      featureFlagValueUpdateStateHandler.flagValueLoading;
    if (
      featureFlagDefinitionUpdateStateHandler?.flagDefinitionError ||
      featureFlagValueUpdateStateHandler.flagValueError
    ) {
      enqueueSnackbar(
        t('featureFlagDefinitionTenants.updateError', { tenantId, flagKey: getFlagKeyError() }),
        { variant: 'error' },
      );
      setLoadingFeatureUpdate(false);
      clearUpdateErrors();
    } else if (isLoading) {
      setLoadingFeatureUpdate(true);
    } else if (flagValueResponse || flagDefinitionResponse) {
      setLoadingFeatureUpdate(false);
      const flagKey = flagValueResponse?.data.flagKey ?? flagDefinitionResponse?.data.flagKey;
      enqueueSnackbar(t('featureFlagDefinitionTenants.updateSuccess', { tenantId, flagKey }), {
        variant: 'info',
      });
      setTenantFeatureFlags((currentValues) => currentValues!.map((flag) => {
        let newFlagValue;
        let newFlagDefinition;
        if (flagValueResponse && flagValueResponse.data.flagKey === flag.flagKey) {
          newFlagValue = flagValueResponse.data;
        }
        if (flagDefinitionResponse && flagDefinitionResponse.data.flagKey === flag.flagKey) {
          newFlagDefinition = flagDefinitionResponse.data;
        }
        return {
          ...flag,
          flagValue: newFlagValue ?? flag.flagValue,
          flagDefinition: newFlagDefinition ?? flag.flagDefinition,
        };
      }));
      clearUpdateResponses();
    }
  }, [
    featureFlagValueUpdateStateHandler,
    featureFlagDefinitionUpdateStateHandler,
    tenantId,
    enqueueSnackbar,
    t,
    clearUpdateResponses,
    clearUpdateErrors,
  ]);

  let content: React.ReactElement;
  if (error) {
    content = (
      <ErrorMessage
        data-testid="tenant-feature-flags-error"
        title={t('tenantFeatureFlags.error')}
        message={error.message}
        variant="block"
      />
    );
  } else if (loading) {
    content = <LoadingIndicator data-testid="tenant-feature-flags-loading" />;
  } else if (!isRegionSupported()) {
    // Skip displaying FF for Tenant region not supported for this deployment
    content = (
      <ErrorMessage
        data-testid="tenant-feature-flags-region-not-supported"
        title={t('tenantFeatureFlags.regionNotSupported')}
        variant="block"
      />
    );
  } else if (tenantFeatureFlags?.length) {
    const totalFlags = tenantFeatureFlags.length;
    content = (
      <div data-testid="tenant-feature-flags-data-table">
        <Typography variant="h2">{t('tenantFeatureFlags.pageTitle')}</Typography>
        <Typography className={classes.searchBox} variant="body1">
          {t('tenantFeatureFlags.subTitle', { totalFlags })}
        </Typography>
        <PaginatedTable
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('tenantFeatureFlags.searchLabel') }}
          sortedData={formatDataList}
          search={search}
          noMatchesMessage={(searchExpression) => t('tenantFeatureFlags.noMatch', { searchExpression })
          }
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="tenant-feature-flag-table-header-feature-flag_label"
                  columnId="featureFlag"
                  tooltip={t('featureFlagList.sortByFeatureFlagLabel')}
                >
                  {t('featureFlagList.featureFlag')}
                </SortableTableHeaderCell>
                <TableCell id="tenant-feature-flag-table-header-feature-status">
                  {t('tenantFeatureFlags.featureStatus')}
                </TableCell>
                <TableCell id="tenant-feature-flag-table-header-admin-permissions">
                  {t('featureFlagGeneral.clientAdminPermissions')}
                </TableCell>
                <TableCell id="feature-flag-fleets-table-header-edit-feature" />
              </TableRow>
            </TableHead>
          )}
          renderRow={(flag, index) => (
            <TableRow
              key={flag.featureFlagName}
              aria-rowindex={index + 1}
              data-testid={`tenant-feature-flag-table-row-${index}`}
            >
              <TableCell
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="tenant-feature-flag-table-cell-feature_name"
                role="cell"
              >
                {flag.featureFlagName}
              </TableCell>
              <TableCell
                aria-colindex={2}
                tabIndex={-1}
                aria-describedby="tenant-feature-flag-table-cell-feature_status"
                role="cell"
              >
                <FeatureStatusBadge
                  featureStatus={getFeatureStatus(flag)}
                  label={getFeatureStatus(flag) === 'true' ? t('global.on') : t('global.off')}
                />
              </TableCell>
              <TableCell
                aria-colindex={3}
                tabIndex={-1}
                aria-describedby="tenant-feature-flag-table-cell-admin_permissions"
                role="cell"
              >
                {getAdminPermissions(flag)}
              </TableCell>
              <TableCell
                aria-colindex={4}
                tabIndex={-1}
                aria-describedby="tenant-feature-flag-table-cell-edit-edit_feature"
                role="cell"
              >
                {loadingFeatureUpdate && flag.flagDefinition.flagKey === flagKeyChanging ? (
                  <CircularProgress
                    className={classes.flagCircularProgress}
                    ariaLabel={t('global.loadingIndicator.loading').toString()}
                    size="small"
                    data-testid="loading-tenant-flag"
                  />
                ) : (
                  <IconButton
                    aria-haspopup="dialog"
                    data-testid="submit-flag-definition-edit-btn"
                    onClick={() => {
                      toggleDialogOpen(true);
                      setSelectedTenantFeatureFlag(flag);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    );
  } else {
    content = (
      <Typography data-testid="tenant-feature-flags-no-data">
        {t('tenantFeatureFlags.noData')}
      </Typography>
    );
  }
  return (
    <div data-testid="tenant-feature-flag-list-page">
      {selectedTenantFeatureFlag && (
        <TenantFeatureFlagDialog
          isDialogOpen={isDialogOpen}
          dialogToggle={toggleDialogOpen}
          tenant={tenant!}
          featureFlagName={selectedTenantFeatureFlag.featureFlagName}
          flagDefinition={selectedTenantFeatureFlag.flagDefinition}
          flagValue={selectedTenantFeatureFlag.flagValue}
          updateFeatureFlagValue={featureFlagValueUpdateStateHandler.updateFeatureFlagValue}
          updateFeatureFlagDefinition={
            featureFlagDefinitionUpdateStateHandler.updateFeatureFlagDefinition
          }
        />
      )}
      {content}
    </div>
  );
};
