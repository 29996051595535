import * as React from 'react';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import {
  IconButton,
  Tooltip,
  Chip,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@bb-ui/react-library';
import { ContentView } from '@bb-ui/icons/dist/medium';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import path from 'path';
import { IJobPreview } from 'App.types';
import { JobExecutionDto, Status } from '@lct-jobs/core';
import { JobStatusIcon } from '../JobStatusIcon/JobStatusIcon';

export interface JobExecutionRowProps {
  jobExecution: JobExecutionDto;
}

const LooseIconButton = IconButton as any;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewCellTableCell: {
      display: 'flex',
      flexDirection: 'column',
    },
    previewCellTd: {
      fontWeight: 'bold',
    },
    noPreview: {
      color: theme.palette.text.secondary,
    },
  }),
);

export const JobExecutionRow: React.FC<JobExecutionRowProps> = (props) => {
  const classes = useStyles(props);
  return (
    <TableRow>
      <TableCell>{props.jobExecution.jobName}</TableCell>
      <TableCell>{props.jobExecution.jobDomain}</TableCell>
      {props.jobExecution.jobPreview ? (
        <JobExecutionPreviewCell preview={props.jobExecution.jobPreview} />
      ) : (
        <TableCell className={classes.noPreview}>No Preview</TableCell>
      )}
      <JobExecutionStatusCell status={props.jobExecution.status} />
      <JobExecutionDateCell date={props.jobExecution.startDate} />
      <JobExecutionDateCell date={props.jobExecution.endDate} />
      <JobExecutionDetailsCell
        executionId={props.jobExecution.executionId}
        tenantId={props.jobExecution.tenantId}
      />
    </TableRow>
  );
};

export const JobExecutionDateCell: React.FC<{ date?: string }> = (props) => {
  if (!props.date) {
    return <TableCell />;
  }
  const date = moment(props.date);
  return (
    <Tooltip title={date.format('lll')}>
      <TableCell>{date.fromNow()}</TableCell>
    </Tooltip>
  );
};

export const JobExecutionStatusCell: React.FC<{ status: Status }> = (props) => (
  <TableCell>
    <Tooltip title={props.status}>
      <JobStatusIcon status={props.status} />
    </Tooltip>
  </TableCell>
);

export const JobExecutionDetailsCell: React.FC<{ executionId: string; tenantId: string }> = (
  props,
) => {
  const location = useLocation();
  return (
    <TableCell>
      <LooseIconButton component={Link} to={path.join(location.pathname, props.executionId)}>
        <ContentView />
      </LooseIconButton>
    </TableCell>
  );
};

export const JobExecutionPreviewCell: React.FC<{ preview: IJobPreview }> = (props) => {
  const classes = useStyles(props);

  return (
    <TableCell className={classes.previewCellTableCell}>
      <td className={classes.previewCellTd}>{props.preview.title}</td>
      <td>{props.preview.contentPath}</td>
      {props.preview.chipText && (
        <td className={classes.previewCellTd}>{props.preview.chipText}</td>
      )}
      {props.preview.chipLinkPath && (
        <td>
          <Chip label={props.preview.chipLinkPath} />
        </td>
      )}
      {props.preview?.chipTenantIdLinkPath && (
        <td>
          <Chip label={props.preview.chipTenantIdLinkPath} />
        </td>
      )}
    </TableCell>
  );
};
