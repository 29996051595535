/**
 * Returns an absolute URL for an API that is reverse-proxied on the app.
 * @param capabilityName short name of the capability (i.e. `sso` or `tenancy`)
 * @param apiPath path of the API URL. Do *not* include a leading slash.
 * @param apiVersion version of the API to use (defaults to `1`)
 */
export function apiUrl(
  capabilityName: string,
  apiPath: string,
  apiVersion = 1,
) {
  return `/${capabilityName}/api/v${apiVersion}/${apiPath}`;
}
