// Manages API interactions to retrieve the state of IDP provisioning, and retrieval of provisioned IDPs.
// See API docs at https://github.com/blackboard-foundations/bb-auth-broker-provisioner/blob/master/openapi/bb-auth-broker-provisioner-oas.yml

import React from 'react';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { RefetchOptions } from 'axios-hooks';
import { useRestApi } from 'hooks/useRestApi';
import { IdentityProvidersData, IdpConnectionType } from 'App.types';
import { useInterval } from 'hooks/useInterval';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';

interface AuthBrokerProvisioner {
  provisioningId: string;
  tenantId: string;
  authBrokerConnectionType: IdpConnectionType;
  providerDisplayName?: string;
}

// Expected arguments for a POST to begin auth provisioning for a Foundations tenant.
interface AuthBrokerProvisionersPost {
  // The auth broker to use. Only 'Auth0' is supported currently. "Broker" means
  // any other service we are using for authentication, like perhaps AWS
  // Cognito (though there are no plans to do so right now).
  authBroker: 'Auth0';

  // The connection type to create.
  authBrokerConnectionType: IdpConnectionType;
}

export interface LearnConnectorProvisionersPost extends AuthBrokerProvisionersPost {
  authBrokerConnectionType: 'LearnConnector';

  // hostname of the tenant. This is required by the API.
  hostname: string;

  // Possible user attributes. Values below are the only ones currently supported.
  userAttribute: '$.user_id' | 'username' | 'email';
}

// internal base for POST body of SAML connection requests
// exported in `SamlProvisionersPost`
interface SamlProvisionersPostInternal extends AuthBrokerProvisionersPost {
  authBrokerConnectionType: 'SAML';

  // Display name. Must be unique per Tenant.
  displayName: string;

  // Mapping to allow SAML Attributes to be mapped to Auth0 User Attributes
  mapping: { user_id: string; groups: string };

  // SAML connection metadata, either HTTP URL or a local file is supported, both of XML type
  metadataUrl: string;
  metadataXml: string;
}

// POST body for SAML connection requests
// requires either URL or string for metadata from `SamlProvisionersPostInternal`
export type SamlProvisionersPost =
  | Omit<SamlProvisionersPostInternal, 'metadataUrl'>
  | Omit<SamlProvisionersPostInternal, 'metadataXml'>;

// POST body for Azure AD connection requests
// OpenAPI spec: https://github.com/blackboard-foundations/bb-auth-broker-provisioner/blob/master/openapi/bb-auth-broker-provisioner-oas.yml#L2379
export interface AzureADProvisionersPost extends AuthBrokerProvisionersPost {
  authBrokerConnectionType: 'AzureAD';
  /** Display name. Must be unique per Tenant. */
  displayName: string;
  /** Unique identifier for clients registered Azure AD application. */
  clientId: string;
  /** Client's Azure AD domain name. */
  azureADDomain: string;
  /** String used to gain access to client registered Azure AD application. */
  clientSecret: string;
}

// What consumers of the context receive.
export interface ITenantIdpsContext {
  // Error, if any, retrieving IDPs/provisioning status.
  error?: Error;

  // Are we currently getting data from the identityProviders API?
  idpsLoading: boolean;

  // Provisioned IDPs, if provisioning has previously completed.
  identityProviders?: IdentityProvidersData[];

  // Fetch identityProviders
  fetchIdps: () => AxiosPromise;

  // Are we currently getting data from the authBrokerProvisioners API?
  authBrokerProvisionersLoading: boolean;

  // Results of authBrokerProvisioners fetch
  authBrokerProvisioners?: AuthBrokerProvisioner[];

  // Fetch authBrokerProvisioners
  fetchAuthBrokerProvisioners: () => AxiosPromise | void;

  // Function to initiate provisioning of IDPs.
  provisioningDoPost: (
    body: LearnConnectorProvisionersPost | SamlProvisionersPost | AzureADProvisionersPost,
    annotation?: any
  ) => void;

  provisioningStatuses: Record<string, string | undefined>;
}

// Required props for the context.
export interface TenantIdpsContextProviderProps {
  tenantId: string;
}

export const TenantIdpsContext = React.createContext<Partial<ITenantIdpsContext>>({});

export const useTenantIdpsContext = () => React.useContext(TenantIdpsContext) as ITenantIdpsContext;
interface ProvisioningStatusMonitorProps {
  tenantId: string;
  provisioningId: string;
  onStatusChange: (provisioningId: string, status?: string) => void;
}

const ProvisioningStatusMonitor: React.FunctionComponent<ProvisioningStatusMonitorProps> = (
  props,
) => {
  const { tenantId, provisioningId, onStatusChange } = props;
  const { data, fetch } = useRestApi(
    apiUrl('sso', `tenants/${tenantId}/authBrokerProvisioners/${provisioningId}/status`),
  );
  const [status, setStatus] = React.useState<string | undefined>();

  // report status changes, ignore undefined and repeated values

  React.useEffect(() => {
    if (data?.status && data.status !== status) {
      onStatusChange(provisioningId, data.status);
      setStatus(data.status);
    }
  }, [data, status, onStatusChange, provisioningId]);

  // We poll this for consumers if the status is currently `RUNNING`

  useInterval(
    () => {
      fetch().catch(() => {
        // Ignore the error--hopefully the next poll will succeed.
      });
    },
    status === 'RUNNING' ? 2000 : null,
  );

  return <></>;
};

export const TenantIdpsContextProvider: React.FunctionComponent<TenantIdpsContextProviderProps> = (
  props,
) => {
  const { children, tenantId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  // Request for IDPs provisioned for the tenant. We immediately get these and
  // act on the result below.

  const {
    data: idpsData,
    error: idpsError,
    fetch: fetchIdps,
    loading: idpsLoading,
  } = useRestApi(apiUrl('sso', `tenants/${tenantId}/identityProviders`));

  // Request to find if there have been any provisions for the tenant.
  // We immediately get these and act on the result below.

  const {
    data: authBrokerProvisionersData,
    error: authBrokerProvisionersError,
    fetch: fetchAuthBrokerProvisioners,
    loading: authBrokerProvisionersLoading,
  } = useRestApi(apiUrl('sso', `tenants/${tenantId}/authBrokerProvisioners`), { manual: true });

  // Keep array of provisioners in state so that we're able to modify
  // without relying on fetching a new set of provisioners each time
  // (it might have an arbitrary delay after POSTing new connection)

  const [authBrokerProvisioners, setAuthBrokerProvisioners] = React.useState<
  AuthBrokerProvisioner[]
  >([]);
  React.useEffect(() => {
    if (authBrokerProvisionersData?.results) {
      setAuthBrokerProvisioners(authBrokerProvisionersData?.results);
    }
  }, [authBrokerProvisionersData]);

  // Request to begin provisioning for a tenant via POST. This is a bit sneaky
  // in that GETs to this endpoint are meaningless.

  const {
    doPost: provisioningDoPost,
    succeededRequests: provisioningPostSucceededRequests,
    clearSucceededRequests: provisioningClearSuceededPosts,
  } = useRestApi(apiUrl('sso', `tenants/${tenantId}/authBrokerProvisioners/connection`), {
    manual: true,
  });

  // Monitoring status of all returner authBrokerProvisioners
  // This is done by creating a ProvisioningStatusMonitor component for each returned provisioner

  const [provisioningStatuses, setProvisioningStatuses] = React.useState<
  Record<string, string | undefined>
  >({});

  const getDisplayName = React.useCallback(
    (provisioningId: string) => authBrokerProvisioners.find((e) => e.provisioningId === provisioningId)
        ?.providerDisplayName ?? provisioningId,
    [authBrokerProvisioners],
  );

  const handleStatusChange = React.useCallback(
    (provisioningId: string, status?: string) => {
      setProvisioningStatuses((prevStatuses) => {
        if (prevStatuses[provisioningId] === 'RUNNING') {
          if (status === 'SUCCEEDED') {
            enqueueSnackbar(
              t(`tenantIdpsContext.provisioningSucceeded`, {
                provisionerDisplayName: getDisplayName(provisioningId),
              }),
              { variant: 'info' },
            );
            fetchIdps().catch(() => {
              // Do nothing--it will be reflected in idpsError.
            });
          } else if (['FAILED', 'TIMED_OUT', 'ABORTED'].find((s) => s === status)) {
            enqueueSnackbar(
              t(`tenantIdpsContext.provisioningFailed`, {
                provisionerDisplayName: getDisplayName(provisioningId),
              }),
              { variant: 'error' },
            );
          }
        }

        const result = { ...prevStatuses };
        result[provisioningId] = status;
        return result;
      });
    },
    [enqueueSnackbar, fetchIdps, getDisplayName, t],
  );

  // Refetch provisioners on successful POST

  React.useEffect(() => {
    if (provisioningPostSucceededRequests.length) {
      // We don't need to fetch authBrokerProvisioners here.
      // ID for status checking is added through this effect (see below).

      const { provisioningId } = provisioningPostSucceededRequests[0].response.data;
      const { displayName: providerDisplayName, authBrokerConnectionType } =
        provisioningPostSucceededRequests[0].requestBody;
      // succeeded connection POST also implicitly means the provisioning is RUNNING
      // save the status here to be able to display RUNNING->FAILED transition notification
      // in case the very first status check responds with FAILED.
      if (provisioningId) {
        handleStatusChange(provisioningId, 'RUNNING');
        setAuthBrokerProvisioners((provisioners) => [
          { provisioningId, tenantId, providerDisplayName, authBrokerConnectionType },
          ...provisioners,
        ]);
      }
      provisioningClearSuceededPosts();
    }
  }, [
    provisioningPostSucceededRequests,
    fetchAuthBrokerProvisioners,
    provisioningClearSuceededPosts,
    handleStatusChange,
    tenantId,
  ]);

  function handleFetchAuthBrokerProvisioners(
    config?: AxiosRequestConfig | undefined,
    options?: RefetchOptions | undefined,
  ) {
    fetchAuthBrokerProvisioners(config, options).catch(() => {
      // Do nothing--it will be reflected in idpsError.
    });
  }

  const context: ITenantIdpsContext = {
    provisioningDoPost,
    identityProviders: idpsData?.results,
    idpsLoading,
    fetchIdps,
    fetchAuthBrokerProvisioners: handleFetchAuthBrokerProvisioners,
    authBrokerProvisionersLoading,
    authBrokerProvisioners,
    provisioningStatuses,
    error: idpsError || authBrokerProvisionersError,
  };

  return (
    <TenantIdpsContext.Provider value={context}>
      {authBrokerProvisioners?.map(({ tenantId, provisioningId }) => (
        <ProvisioningStatusMonitor
          key={provisioningId}
          tenantId={tenantId}
          provisioningId={provisioningId}
          onStatusChange={handleStatusChange}
        />
      ))}
      {children}
    </TenantIdpsContext.Provider>
  );
};

export default TenantIdpsContextProvider;
