import { FormProps } from '@rjsf/core';
import React from 'react';
import Form from '@rjsf/material-ui';
import { CardActions } from '@bb-ui/react-library/dist/components/CardActions';
import { CardContent, Chip } from '@bb-ui/react-library';
import { BulletedListDefault, Editor } from '@bb-ui/icons/dist/small';
import ReactJson from 'react-json-view';
import { JobCard, JobCardProps } from './JobCard';

export interface JobFormCardProps extends Omit<JobCardProps, 'content'>, FormProps<any> {}

export const JobFormCard: React.FC<JobFormCardProps> = (props) => {
  const [rawShown, setRawShown] = React.useState(false);
  const { uiSchema, formData, actions, ...rest } = props;

  return (
    <JobCard
      content={
        <CardContent>
          {rawShown ? (
            <ReactJson src={formData} name={false} enableClipboard={false} />
          ) : (
            <Form
              uiSchema={{
                ...uiSchema,
                'ui:submitButtonOptions': { norender: true },
                'ui:readonly': true,
              }}
              {...rest}
              formData={formData}
            />
          )}
        </CardContent>
      }
      actions={
        <CardActions>
          <Chip
            label={`View ${rawShown ? 'List' : 'JSON'}`}
            onClick={() => setRawShown(!rawShown)}
            icon={rawShown ? <BulletedListDefault /> : <Editor />}
          />
          {actions}
        </CardActions>
      }
      {...rest}
    />
  );
};
