// Read-only table row of group mappings.

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@bb-ui/react-library/dist/components/Chip';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { GroupMappings } from './GroupMapping.types';

export const GroupMappingTableRow: React.FunctionComponent<GroupMappings> = ({ fndsGroup, mappings }) => {
  const { t } = useTranslation();
  let mappingsCell = <></>;

  if (mappings.length > 0) {
    mappingsCell = <>{mappings.map(({ idpGroupName }) => <Chip label={idpGroupName} data-testid={`role-item-${idpGroupName}`} key={`group-item-${idpGroupName}`} />)}</>;
  } else {
    mappingsCell = <Typography color="textSecondary" data-testid="no-assigned-groups">{t('tenantIdp.noAssignedGroups')}</Typography>;
  }

  return (
    <TableRow data-testid={`group-mapping-row-${fndsGroup.name}-${fndsGroup.type}`}>
      <TableCell data-testid="group-mapping-fnds-group-name">{fndsGroup.name}</TableCell>
      <TableCell data-testid="group-mapping-fnds-group-type">{fndsGroup.type}</TableCell>
      <TableCell data-testid="group-mapping-tenant-groups">
        {mappingsCell}
      </TableCell>
    </TableRow>
  );
};
