import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import React, { ReactElement } from 'react';
import { useAppRouteContext } from '../../../contexts/AppRouteContext';

export const RefreshOnNavigate: React.FC<{ content: ReactElement }> = (props) => {
  const { refresh, loading, setExecutionId } = useJobExecutionContext();
  const { currentRoute } = useAppRouteContext();
  const [currentPath, setCurrentPath] = React.useState<string | undefined>();
  const path = currentRoute?.path;

  React.useEffect(() => {
    if (currentPath !== path) {
      setExecutionId(undefined);
      setCurrentPath(path);
      if (!loading) refresh();
    }
  }, [currentPath, loading, path, refresh, setExecutionId]);

  return props.content;
};
