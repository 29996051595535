import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useSnackbar } from 'hooks/useSnackbar';
import { ClientData } from 'App.types';

const styles = (theme: Theme) => createStyles({
  addLearnClientInput: {
    margin: theme.spacing(1, 0, 1),
  },
  addButton: {
    marginRight: theme.spacing(2),
  },
});

export const useStyles = makeStyles(styles);

interface ClientDataWithoutId extends Omit<ClientData, 'id'> {}

const emptyClientData: ClientDataWithoutId = {
  name: '',
  primaryMarket: '',
  secondaryMarket: '',
  city: '',
  state: '',
  country: '',
  contact: [{ phone: '', email: '' }],
  team: [{ team: '' }],
  externalIds: { salesforce: '', peoplesoft: '' },
};

export interface CreateLearnClientDialogProps extends DialogProps {
  onClose: () => void;
}

export const CreateLearnClientDialog: React.FunctionComponent<CreateLearnClientDialogProps> = (
  props,
) => {
  const { id, open, onClose } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    clearFailedRequests,
    clearSucceededRequests,
    doPost,
    failedRequests,
    loadingRequests,
    succeededRequests,
  } = useRestApi(apiUrl('tenancy', 'clients'), { manual: true });
  const classes = useStyles(props);
  const [showErrors, setShowErrors] = React.useState(false);
  const [formData, setFormData] = React.useState<ClientDataWithoutId>(emptyClientData);

  const nameError = formData?.name === '' ? t('createLearnClientDialog.errors.nameRequired') : '';

  function create(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setShowErrors(true);

    if (nameError) {
      return;
    }

    doPost(formData);
  }

  const close = React.useCallback(() => {
    setShowErrors(false);
    setFormData(emptyClientData);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      enqueueSnackbar(t('createLearnClientDialog.createSucceeded', { name: formData.name }), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
    }
  }, [clearSucceededRequests, close, enqueueSnackbar, formData.name, succeededRequests.length, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('createLearnClientDialog.createFailed', {
          message: failedRequests[0].error.message,
          name: formData.name,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [
    clearFailedRequests,
    close,
    enqueueSnackbar,
    failedRequests,
    failedRequests.length,
    formData.name,
    t,
  ]);

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      data-testid={id}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('createLearnClientDialog.title')}
      </DialogTitle>
      <form onSubmit={create} noValidate data-testid="create-learn-site-form">
        <DialogContent>
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-name`}
            data-testid={`${id}-name`}
            label={t('learnClients.name')}
            onChange={(event) => setFormData({ ...formData, name: event.target.value.trim() })}
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-primary-market`}
            data-testid={`${id}-primary-market`}
            label={t('learnClients.primaryMarket')}
            onChange={(event) => setFormData({ ...formData, primaryMarket: event.target.value.trim() })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-secondary-market`}
            data-testid={`${id}-secondary-market`}
            label={t('learnClients.secondaryMarket')}
            onChange={(event) => setFormData({ ...formData, secondaryMarket: event.target.value.trim() })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-city`}
            data-testid={`${id}-city`}
            label={t('learnClients.city')}
            onChange={(event) => setFormData({ ...formData, city: event.target.value.trim() })}
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-state`}
            data-testid={`${id}-state`}
            label={t('learnClients.state')}
            onChange={(event) => setFormData({ ...formData, state: event.target.value.trim() })}
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-country`}
            data-testid={`${id}-country`}
            label={t('learnClients.country')}
            onChange={(event) => setFormData({ ...formData, country: event.target.value.trim() })}
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-contact-phone`}
            data-testid={`${id}-contact-phone`}
            label={t('learnClients.phone')}
            onChange={(event) => setFormData({
              ...formData,
              contact: [{ ...formData.contact[0], phone: event.target.value.trim() }],
            })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-contact-email`}
            data-testid={`${id}-contact-email`}
            label={t('learnClients.email')}
            onChange={(event) => setFormData({
              ...formData,
              contact: [{ ...formData.contact[0], email: event.target.value.trim() }],
            })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-team`}
            data-testid={`${id}-team`}
            label={t('learnClients.team')}
            onChange={(event) => setFormData({
              ...formData,
              team: [{ ...formData.team[0], team: event.target.value.trim() }],
            })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-salesforce-id`}
            data-testid={`${id}-salesforce-id`}
            label={t('learnClients.salesforce')}
            onChange={(event) => setFormData({
              ...formData,
              externalIds: { ...formData.externalIds, salesforce: event.target.value.trim() },
            })
            }
            fullWidth
          />
          <TextField
            className={classes.addLearnClientInput}
            id={`${id}-peoplesoft-id`}
            data-testid={`${id}-peoplesoft-id`}
            label={t('learnClients.peoplesoft')}
            onChange={(event) => setFormData({
              ...formData,
              externalIds: { ...formData.externalIds, peoplesoft: event.target.value.trim() },
            })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid="cancel-create-learn-client-dialog" onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton
            data-testid="submit-create-learn-client-dialog"
            disabled={loadingRequests}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateLearnClientDialog;
