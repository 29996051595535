import { createStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, makeStyles, PrimaryButton, Theme, Typography } from '@bb-ui/react-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTenantContext } from '../../../contexts/TenantContext';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { TenantInformationItem } from '../TenantInformation/TenantInformationItem';
import { LoadingIndicator } from 'components/LoadingIndicator';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      paddingLeft: '.5em',
      paddingRight: '.5em',
    },
    pageTitleContainer: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    expansionPanelDetails: {
      padding: 0,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      flexDirection: 'column'
    },
    expansionPanelSummary: {
      minHeight: 0,
      margin: 0,
      padding: 0
    },
    expansionPanelSummaryContent: {
      margin: 0,
    },
    expansionPanel: {
      paddingLeft: theme.spacing(2), 
      paddingRight: theme.spacing(2),
    },
    loadMoreButton: {
      marginTop: '1rem',
    },
  })
);

interface Log {
  tenantId: string;
  namespace: string;
  event: string;
  user: string;
  timestamp: number;
  data: Record<string, string>;
}

export const TenantLogs: React.FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [limit, setLimit] = React.useState(100);
  const { tenantId } = useTenantContext();
  const {
    data: auditLogs,
    loading: loadLogs,
    error: logsError,
  } = useRestApi(
    apiUrl('lct', `audit/${tenantId}/logs?limit=${limit}`),
  );
  
  const logResults = auditLogs?.items ?? [];

  const logs = React.useMemo<Log[]>(() => logResults ?? [], [logResults]);
  
  let content = <LoadingIndicator data-testid="loading-log-events" />;

  if (logsError) {
    content = <Typography>{t('errorLoadingLogs')}</Typography>;
  } else if (!loadLogs) {
    content = 
    <>
      {logs.map((log: Log, index: number) => (
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            id="log-event"
            aria-controls="small-font-size-details"
            className={classes.expansionPanelSummary}
          >
            <Typography className={classes.expansionPanelSummaryContent} variant="h4" component="span">{new Date(log.timestamp).toLocaleString()} - {log.event} by {log.user}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {Object.entries(log).map(([key, value]) => {
              if (typeof value === 'object') {
                return Object.entries(value).map(([subKey, subValue]) => (
                  <TenantInformationItem key={subKey} title={subKey} value={subValue as string} />
                ));
              }
              return <TenantInformationItem key={key} title={key} value={value as string} />;
            })}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      {logs.length >= limit && (
        <PrimaryButton className={classes.loadMoreButton} onClick={() => setLimit(limit + 100)}>
          {t('domainConfiguration.loadMore')}
        </PrimaryButton>
      )} 
    </>
  }

  return (
    <div data-testid="tenant-logs-page">
      <div className={classes.section}>
        {content}
      </div>
    </div>
  );
};