// Shows a list of group mappings for a given IDP that allows users to edit
// them. Changes may be made in the page by the user but until the Save button
// is clicked, they are uncommitted to the API.

import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { CardHeader } from '@bb-ui/react-library/dist/components/CardHeader';
import { Theme, createStyles, makeStyles } from '@bb-ui/react-library/dist/components/styles';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageCard } from 'components/PageCard';
import { TooltipIconButton } from 'components/TooltipIconButton';
import { useAuthContext } from 'contexts/AuthContext';
import { useAuthorization } from 'hooks/useAuthorization';
import { useRestApi } from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { apiUrl } from 'utils/apiUrl';
import {
  denormalizeGroupMappingsArray,
  normalizeGroupMapping,
  updateTenantIdpMappings,
} from './GroupMapping';
import { FoundationsGroup, GroupMappings } from './GroupMapping.types';
import { GroupMappingTable } from './GroupMappingTable';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  // TODO: create card component variations for different design types (primary/secondary? editable/non-editable?)
  cardHeader: {
    padding: theme.spacing(0, 0, 4, 0),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardHeaderSecondary: {
    padding: theme.spacing(0, 0, 3, 0),
  },
  cardHeaderAction: {
    marginRight: 0,
  },
  cardHeaderActionControl: {
    marginLeft: theme.spacing(1.5),
  },
  cardContent: {
    padding: theme.spacing(2, 0, 0, 0),
  },
}));

export const GroupMappingCard: React.FunctionComponent = (props) => {
  const { tenantId, idpId } = useParams<{ tenantId: string; idpId: string }>();
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const { idToken } = useAuthContext();
  const {
    data: mappingData,
    error: mappingError,
    fetch: fetchMappings,
    loading: mappingLoading,
  } = useRestApi(
    apiUrl('sso', `tenants/${tenantId}/identityProviders/${idpId}/groupMappings?limit=999`),
  );
  const {
    data: fndsGroupsData,
    error: fndsGroupsError,
    loading: fndsGroupsLoading,
  } = useRestApi(apiUrl('sso', 'fndsGroups'));
  let error: Error | undefined;

  const canEditMappings =
    hasPermission('group-mapping', 'create') && hasPermission('group-mapping', 'delete');

  // Using state for this to store mappings in an uncommitted state. It begins
  // with the state as received by the API but can be modified by the user
  // before saving via the API.
  const [uncommittedMappings, setUncommittedMappings] = React.useState<GroupMappings[]>([]);

  // A helper function to set uncommitted state to the last known API state. We
  // do this either after cancelling an edit or after committing the local state
  // to the API.

  const apiMappings = React.useCallback(() => {
    if (fndsGroupsData && mappingData) {
      return fndsGroupsData.results.map((fndsGroup: FoundationsGroup) => normalizeGroupMapping(mappingData.results, fndsGroup));
    }
    return [];
  }, [fndsGroupsData, mappingData]);

  // Is the table in editable mode?
  const [isEditing, setIsEditing] = React.useState(false);

  // Are we in the middle of committing changes to the API?
  const [isSaving, setIsSaving] = React.useState(false);

  // If we have loaded both mappings and groups, reset the uncommitted data.
  // This may happen both when the component is initially mounted and when a
  // fetch is manually triggered.

  React.useEffect(() => {
    if (!fndsGroupsLoading && !mappingLoading) {
      setUncommittedMappings(apiMappings());
    }
  }, [fndsGroupsLoading, mappingLoading, apiMappings]);

  // Early bailouts.

  if (fndsGroupsError || mappingError) {
    error = fndsGroupsError || mappingError;
  }

  if (mappingLoading || fndsGroupsLoading) {
    return <LoadingIndicator data-testid="loading-group-mapping-card" />;
  }

  // When the user makes changes in the table, update our uncommitted state. We
  // imitate the structure created by resetUncommittedState() as much as we can.

  function handleMappingChange(fndsGroup: FoundationsGroup, tenantGroups: string[]) {
    setUncommittedMappings(
      uncommittedMappings.map((mapping) => {
        if (mapping.fndsGroup.name === fndsGroup.name && mapping.fndsGroup.type) {
          return {
            fndsGroup: mapping.fndsGroup,
            mappings: tenantGroups.map((tenantGroupName) => ({
              idpGroupName: tenantGroupName,
              fndsGroupId: fndsGroup.id,
            })),
          };
        }

        return mapping;
      }),
    );
  }

  function cancelEditing() {
    setIsEditing(false);
    setUncommittedMappings(apiMappings());
  }

  function save() {
    setIsSaving(true);
    setIsEditing(false);
    updateTenantIdpMappings(
      tenantId,
      idpId,
      denormalizeGroupMappingsArray(apiMappings()),
      denormalizeGroupMappingsArray(uncommittedMappings),
      idToken!,
    ).finally(() => {
      // Sync up with the API. This will also trigger a reset of uncommitted mappings.

      fetchMappings();
      setIsSaving(false);
    });

    // TODO: notification?
  }

  return (
    <PageCard data-testid="group-mapping-card">
      {error ? (
        <ErrorMessage minHeight="168px" title={t('global.fetchError')} message={error.message} />
      ) : (
        <>
          <CardHeader
            className={classes.cardHeaderSecondary}
            classes={{ action: classes.cardHeaderAction }}
            title={t('tenantIdp.groupMapping')}
            titleTypographyProps={{ component: 'h3', variant: 'h2' } as any} // any: component expects 'span' | undefined
            action={
              isEditing ? (
                <>
                  <DefaultButton onClick={cancelEditing} data-testid="cancel-mapping-edit">
                    {t('global.cancel')}
                  </DefaultButton>
                  <PrimaryButton
                    disabled={isSaving}
                    onClick={save}
                    className={classes.cardHeaderActionControl}
                    data-testid="save-mapping-edit"
                  >
                    {t('global.save')}
                  </PrimaryButton>
                </>
              ) : (
                canEditMappings && (
                  <TooltipIconButton
                    data-testid="edit-group-mapping"
                    label={t('global.edit')}
                    onClick={() => setIsEditing(true)}
                  >
                    <Edit />
                  </TooltipIconButton>
                )
              )
            }
          />
          <CardContent className={classes.cardContent}>
            <GroupMappingTable
              groupMappings={uncommittedMappings}
              isEditing={isEditing}
              onChangeMapping={handleMappingChange}
            />
          </CardContent>
        </>
      )}
    </PageCard>
  );
};
