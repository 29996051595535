import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from '@bb-ui/react-library/dist/components/Snackbar';
import { BrowserRouter } from 'react-router-dom';
import { default as AppConfigProvider } from 'contexts/AppConfigContext';
import { AuthProvider } from 'components/AuthProvider';
import { WaitForContexts } from 'components/WaitForContexts';
import { AuthContextProvider } from 'contexts/AuthContext';
import { Routes } from './routes';
import { styles } from './App.styles';

export const useStyles = makeStyles(styles);

export const App: React.FunctionComponent = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppConfigProvider>
          <AuthProvider>
            <AuthContextProvider>
              <WaitForContexts>
                <SnackbarProvider key={undefined} variant={undefined}>
                  <Routes />
                </SnackbarProvider>
              </WaitForContexts>
            </AuthContextProvider>
          </AuthProvider>
        </AppConfigProvider>
      </BrowserRouter>
    </div>
  );
};
