// useTenantMobile.ts
import React, { useState } from 'react';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useSnackbar } from 'hooks/useSnackbar';

export interface MobileProps {
  mobileprops?: MobileDetailsResponse;
  updateMobileDetails: (newMobileDetails: MobileDetails, isModified: boolean) => void;
  updateChildDetails: (
    newMobileDetails: MobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => void;
}

export interface MobileConfigurationFields {
  environment: string;
  publicId: string;
  peopleSoftInstitutionId: number;
  forcedOff: string;
  notes: string;
  name: string;
  b2Url: string;
  forceWebLogin: string;
  userName: string;
}

export interface MobileDetails {
  tenantId: string;
  domain: string;
  version: string;
  description: string;
  configuration: MobileConfigurationFields;
}

export interface MobileDetailsResponse {
  items: MobileDetails[];
  nextPageToken: string;
}

interface UseTenantMobileProps {
  tenantId: string | undefined;
}

export const useTenantMobile = ({ tenantId }: UseTenantMobileProps) => {
  // API URL
  const apiBaseUrl = apiUrl('config', `tenants/${tenantId}/capabilityConfigs`);

  const { enqueueSnackbar } = useSnackbar();
  // To display snackbar on update failure
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

  // Fetch Mobile and descendant Details
  const { data, error, loading, fetch } = useRestApi<MobileDetailsResponse>(apiBaseUrl, {
    manual: true,
  });

  React.useEffect(() => {
    if (tenantId) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetch().catch(() => {});
    }
  }, [fetch, tenantId]);

  // Update Mobile Details, Descendant details, add new descendant details and delete descendants
  const {
    doPatch,
    doPost,
    doDelete,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi<MobileDetails>(apiBaseUrl, { manual: true });

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      setIsSnackbarVisible(false);
      fetch!();
      clearSucceededRequests();
    }
  }, [succeededRequests, clearSucceededRequests, fetch]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      clearFailedRequests();
      if (!isSnackbarVisible) {
        enqueueSnackbar(`Error updating configuration details. Try again.`, { variant: 'error' });
        setIsSnackbarVisible(true);
      }
    }
  }, [failedRequests, clearFailedRequests, isSnackbarVisible, enqueueSnackbar]);

  return {
    doPatch,
    doPost,
    doDelete,
    data,
    error,
    loading,
    isSnackbarVisible,
    setIsSnackbarVisible,
  };
};
