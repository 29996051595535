import { Menu as MenuIcon } from '@bb-ui/icons/dist/medium/Menu';
import {
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@bb-ui/react-library';
import { useTenantContext } from 'contexts/TenantContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIdpDialog } from '../DeleteIdpDialog';
import { IdentityProvider } from '../TenantIdp.types';

export interface IdpActionsProps {
  idp: IdentityProvider;
  onDeleteIdp: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteItem: {
    color: theme.palette.error.main,
  },
}));

export const IdpActions: React.FC<IdpActionsProps> = (props) => {
  const { idp, onDeleteIdp } = props;
  const classes = useStyles(props);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>();
  const { tenant } = useTenantContext();
  const { t } = useTranslation();

  function closeMenu() {
    setMenuAnchor(undefined);
  }

  function handleDeleteDialogClose(idpDeleted: boolean) {
    setDeleteDialogOpen(false);

    if (idpDeleted) {
      onDeleteIdp();
    }
  }

  function onClickButton(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function openDeleteDialog() {
    setMenuAnchor(undefined);
    setDeleteDialogOpen(true);
  }

  return (
    <>
      <IconButton
        aria-controls={menuAnchor ? 'tenant-idp-actions-menu' : undefined}
        aria-expanded={!!menuAnchor}
        aria-haspopup="menu"
        aria-label={t('tenantIdpActions.buttonAriaLabel')}
        onClick={onClickButton}
        data-testid="tenant-idp-actions-button"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        id="tenant-idp-actions-menu"
        onClose={closeMenu}
        open={!!menuAnchor}
        MenuListProps={{
          'aria-label': t('tenantIdpActions.menuAriaLabel'),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="tenant-idp-actions-menu"
      >
        <MenuItem
          button
          className={classes.deleteItem}
          onClick={openDeleteDialog}
          data-testid="tenant-idp-actions-delete"
        >
          {t('tenantIdpActions.delete')}
        </MenuItem>
      </Menu>
      {tenant && (
        <DeleteIdpDialog
          idp={idp}
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          tenantId={tenant.id!}
          tenantName={tenant.name!}
        />
      )}
    </>
  );
};
