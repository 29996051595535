// Editable table row of group mappings. This sends change events upward.

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { OptionType } from '@bb-ui/react-library/dist/components/Picker/Picker.types';
import { Picker } from 'components/Picker';
import { FoundationsGroup, GroupMappings } from './GroupMapping.types';

export interface GroupMappingEditableTableRowProps extends GroupMappings {
  onChange: (fndsGroup: FoundationsGroup, tenantGroups: string[]) => void;
}

export const GroupMappingEditableTableRow: React.FunctionComponent<GroupMappingEditableTableRowProps> = ({ fndsGroup, mappings, onChange }) => {
  const { t } = useTranslation();

  function onPickerChange(value: OptionType[]) {
    onChange(fndsGroup, (value || []).map(item => item.value));
  }

  return (
    <TableRow data-testid={`group-mapping-row-${fndsGroup.name}-${fndsGroup.type}`}>
      <TableCell data-testid="group-mapping-fnds-group-name">{fndsGroup.name}</TableCell>
      <TableCell data-testid="group-mapping-fnds-group-type">{fndsGroup.type}</TableCell>
      <TableCell data-testid="group-mapping-tenant-groups">
        <Picker
          defaultValue={
            mappings.map(({ idpGroupName }) => ({
              value: idpGroupName!,
              label: idpGroupName!,
            }))}
          floatingLabel
          isMulti={true}
          isCreatable={true}
          inputId={`picker-${fndsGroup.name}`}
          label={t('tenantIdp.groupPickerLabel')}
          onChange={(value) => onPickerChange(value as OptionType[])}
          placeholder={t('tenantIdp.groupPickerLabel')}
        />
      </TableCell>
    </TableRow>
  );
};
