import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { JssThemeProvider } from '@bb-ui/react-library/dist/components/JssThemeProvider';

export const I18nThemeProvider: React.FunctionComponent = ({ children }) => {
  const { i18n } = useTranslation();

  return (
    <JssThemeProvider isRtl={i18n.dir() === 'rtl'} theme="light">
      <Helmet
        htmlAttributes={{ lang: i18n.language }}
        bodyAttributes={{ dir: i18n.dir() }}
      />
      {children}
    </JssThemeProvider>
  );
};
