import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultButton,
  Card,
  PrimaryButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  makeStyles,
  createStyles,
} from '@bb-ui/react-library';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { useSnackbar } from 'hooks/useSnackbar';
import { useRestApi } from 'hooks/useRestApi';
import { schemaTemplate, uiSchemaTemplate } from 'pages/DomainConfiguration/defaultSchemaTemplate';
import { ControlledEditor } from '@monaco-editor/react';
import Form from '@rjsf/material-ui';

export const useStyles = makeStyles((theme) =>
  createStyles({
    addDomainInput: {
      padding: theme.spacing(2),
    },
    dialogContent: {
      '&.bb-dialog-content': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        minHeight: '15rem',
      },
    },
    editorTitle: {
      paddingTop: `${theme.spacing(1)}px`,
      paddingLeft: `${theme.spacing(1)}px`,
      paddingBottom: `${theme.spacing(1)}px`,
    },
    schemaEditorTitle: {
      padding: theme.spacing(0, 2, 1, 2),
      color: theme.palette.text.secondary,
    },
    schemaEditorContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '20px',
      flex: 1,
    },
    dialogEditorFormat: {
      display: 'flex',
      flexDirection: 'row',
    },
    dialogContentLivePreview: {
      '&.bb-dialog-content': {
        padding: 0,
        display: 'flex',
        overflow: 'hidden',
        minHeight: '15rem',
        justifyContent: 'center',
      },
    },
    livePreviewContainer: {
      width: '50%',
      minHeight: '15rem',
      height: 'fit-content',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'flex-end',
      padding: theme.spacing(5),
      paddingTop: theme.spacing(3),
      backgroundColor: theme.palette.background.b4,
    },
    livePreviewTitle: {
      alignSelf: 'center',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    livePreviewCard: {
      overflow: 'auto',
      borderRadius: '5px',
      padding: theme.spacing(2),
    },
    livePreview: {
      '& button[type="submit"]': {
        display: 'none',
      },
      '& button[type="button"]': {
        display: 'none',
      },
    },
  }),
);

export interface CreateDomainDialogProps extends DialogProps {
  onClose: () => void;
  fetchDomains: () => void;
}

export const CreateDomainDialog: React.FunctionComponent<CreateDomainDialogProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const { id, open, onClose, fetchDomains } = props;
  const [showErrors, setShowErrors] = React.useState(false);
  const [newDomainName, setNewDomainName] = React.useState('');
  const [schema, setSchema] = React.useState(schemaTemplate);
  const [uiSchema, setUiSchema] = React.useState(uiSchemaTemplate);
  const [formHeight, setFormHeight] = React.useState('auto');
  const formRef = React.useRef(null); // Dynamically set the height of the form preview when the schema changes using the livePreviewContainer div formRef
  const {
    doPost: createDomain,
    succeededRequests,
    failedRequests,
    loadingRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi(`/config/api/v1/capabilityConfigs/schemas/${newDomainName}`);
  const succeededRequestsLength = succeededRequests?.length;
  const failedRequestsLength = failedRequests?.length;
  const domainNameError = newDomainName === '' ? t('createDomainDialog.domainNameError') : '';

  React.useEffect(() => {
    if (formRef.current) {
      setFormHeight(`${(formRef.current as HTMLElement).offsetHeight}px`);
    }
  }, [schema]);

  const close = React.useCallback(() => {
    setShowErrors(false);
    onClose();
  }, [onClose]);

  React.useEffect(() => {
    if (succeededRequests?.length > 0) {
      enqueueSnackbar(t('createDomainDialog.createSucceeded'), {
        variant: 'success',
      });
      clearSucceededRequests();
      close();
      fetchDomains();
    }
  }, [
    clearSucceededRequests,
    close,
    enqueueSnackbar,
    succeededRequests,
    succeededRequestsLength,
    fetchDomains,
    t,
  ]);

  React.useEffect(() => {
    if (failedRequests?.length > 0) {
      enqueueSnackbar(
        t('createDomainDialog.createFailed', {
          message: failedRequests[0].error.message,
        }),
        { variant: 'error' },
      );
      clearFailedRequests();
      close();
    }
  }, [clearFailedRequests, close, enqueueSnackbar, failedRequests, failedRequestsLength, t]);

  const createNewDomain = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrors(true);
    if (domainNameError !== '') {
      return;
    }

    createDomain({
      schema: schema,
      uiSchema: uiSchema,
    });
  };

  const handleEditorChange = (ev: any, value: any): any => {
    try {
      const newSchema = JSON.parse(value);
      setSchema(newSchema);
    } catch (error) {
      console.error('Invalid JSON schema:', error);
    }
  };

  const handleUiSchemaChange = (ev: any, value: any): any => {
    try {
      const newUiSchema = value.trim() ? JSON.parse(value) : {};
      setUiSchema(newUiSchema);
    } catch (error) {
      console.error('Invalid JSON schema:', error);
    }
  };

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      maxWidth="lg"
    >
      <DialogTitle onClose={close} id={`${id}-title`}>
        {t('createDomainDialog.title')}
      </DialogTitle>
      <form onSubmit={createNewDomain} noValidate data-testid="create-domain-form">
        <DialogContent className={classes.dialogContent}>
          <TextField
            className={classes.addDomainInput}
            error={showErrors && domainNameError !== ''}
            helperText={showErrors && domainNameError}
            id="create-domain-name-input"
            test-id="create-domain-name-input"
            label={t('createDomainDialog.domainName')}
            onChange={(event) => setNewDomainName(event.target.value.trim())}
            placeholder={t('createDomainDialog.domainName')}
            fullWidth
            autoFocus
            required
          />
          <Typography variant="body2" gutterBottom className={classes.schemaEditorTitle}>
            {t('createDomainDialog.domainJsonSchemaHelperText')}
          </Typography>
          <DialogContent
            className={classes.dialogContentLivePreview}
            style={{ height: `${formHeight}` }}
          >
            <div className={classes.dialogEditorFormat}>
              <div className={classes.schemaEditorContainer}>
                <Typography className={classes.editorTitle}>JSON Editor</Typography>
                <ControlledEditor
                  height="100%"
                  width="100%"
                  language="json"
                  onChange={handleEditorChange}
                  value={JSON.stringify(schema, null, 2)}
                  options={{
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    hideCursorInOverviewRuler: true,
                    overviewRulerLanes: 0,
                  }}
                />
                <Typography className={classes.editorTitle}>UI Schema Editor</Typography>
                <ControlledEditor
                  height="100%"
                  width="100%"
                  language="json"
                  onChange={handleUiSchemaChange}
                  value={JSON.stringify(uiSchema, null, 2)}
                  options={{
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    hideCursorInOverviewRuler: true,
                    overviewRulerLanes: 0,
                  }}
                />
              </div>
              <div className={classes.livePreviewContainer} ref={formRef} style={{ flex: 1 }}>
                <Typography variant="h3" gutterBottom className={classes.livePreviewTitle}>
                  {t('editConfigurationDomainSchema.jsonSchemaPreview')}
                </Typography>
                <Card raised className={classes.livePreviewCard}>
                  <Form className={classes.livePreview} schema={schema} uiSchema={uiSchema} />
                </Card>
              </div>
            </div>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid={`cancel-${id}`} onClick={close}>
            {t('global.cancel')}
          </DefaultButton>
          <PrimaryButton data-testid={`submit-${id}`} disabled={loadingRequests} type="submit">
            {t('global.save')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateDomainDialog;
