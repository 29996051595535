import React from 'react';
import { ListItem } from '@bb-ui/react-library/dist/components/ListItem';
import { JobExecutionDto } from '@lct-jobs/core';
import { Divider, ListItemIcon } from '@bb-ui/react-library';
import { ListItemText } from '@bb-ui/react-library/dist/components/ListItemText';
import { useHistory } from 'react-router-dom';
import { useJobExecutionContext } from '../../../contexts/JobExecutionContext';
import { JobCardList } from './basic/JobCardList';
import { JobStatusIcon } from '../../JobStatusIcon/JobStatusIcon';

const JobChildItem: React.FC<{ child: JobExecutionDto }> = (props) => {
  const history = useHistory();
  return (
    <ListItem
      button
      onClick={() =>
        history.push(`/tenants/${props.child.tenantId}/executions/${props.child.executionId}`)
      }
    >
      <ListItemIcon>
        <JobStatusIcon status={props.child.status} />
      </ListItemIcon>
      <ListItemText primary={`Job ID: ${props.child.jobId}`} secondary={props.child.status} />
    </ListItem>
  );
};

export const JobChildrenBox: React.FC = () => {
  const { execution, loading } = useJobExecutionContext();

  const children = execution?.children || [];
  const childrenItems = children.flatMap((child) => [<JobChildItem child={child} />, <Divider />]);
  childrenItems.pop(); // remove last divider

  return (
    <JobCardList title="Child Job Executions" loading={loading}>
      {childrenItems}
    </JobCardList>
    // todo: add pagination
  );
};
