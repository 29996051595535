import * as React from 'react';
import { Theme, makeStyles, createStyles } from '@bb-ui/react-library';
import { AppBar, Toolbar } from '@bb-ui/react-library/dist/components';
import { Logo } from 'components/Logo';
import { HeaderUserMenu } from './HeaderUserMenu';

interface HeaderProps {}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      gridArea: 'header',
      boxShadow: '0 0 3px 0 rgba(0,0,0, 0.1)',
      backgroundColor: theme.palette.common.black,
    },
    headerContent: {
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
    },
    // TODO: revise when we have designs for smaller breakpoints
    // right now this will overloap other header content on smaller screens
    subTitle: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
);

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const classes = useStyles(props);

  return (
    <AppBar className={classes.header} position="sticky" color="inherit" data-testid="app-header">
      <Toolbar className={classes.headerContent}>
        <Logo />
        <HeaderUserMenu />
      </Toolbar>
    </AppBar>
  );
};
