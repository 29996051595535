// A button with dropdown menu allowing creation of various things--for now,
// just a new Foundations group.

import { AddCircle } from '@bb-ui/icons/dist/medium/AddCircle';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useAuthorization } from 'hooks/useAuthorization';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CreateTenantDialog from '../../dialogs/CreateTenantDialog';

export const AddTenantButton: React.FunctionComponent = () => {
  const { hasPermission } = useAuthorization();
  const { t } = useTranslation();
  const [tenantDialogOpen, setTenantDialogOpen] = React.useState(false);

  if (!hasPermission('tenant', 'create')) {
    return null;
  }

  return (
    <>
      <PrimaryButton
        startIcon={<AddCircle />}
        data-testid="header-add-button"
        onClick={() => setTenantDialogOpen(true)}
      >
        {t('tenantList.addNew')}
      </PrimaryButton>
      <CreateTenantDialog
        id="create-tenant-dialog"
        data-testid="create-tenant-dialog"
        onClose={() => setTenantDialogOpen(false)}
        open={tenantDialogOpen}
      />
    </>
  );
};
