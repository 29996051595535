import React from 'react';
import { useTenantContext } from '../../../contexts/TenantContext';
import { TenantListTable } from '../../../components/TenantList';
import useRestApi from '../../../hooks/useRestApi';
import { apiUrl } from '../../../utils/apiUrl';
import { TenantData } from '../../../App.types';

export const TenantChildren: React.FC = (props) => {
  const { tenant, loading, error } = useTenantContext();

  const {
    data,
    error: childError,
    loading: loadingChildren,
  } = useRestApi(apiUrl('tenancy', 'tenants/searchable'));

  const tenants = React.useMemo<TenantData[]>(
    () =>
      ((data?.results ?? []) as TenantData[]).filter((t) => tenant?.tenantChildren?.includes(t.id)),
    [data, tenant],
  );

  return (
    <TenantListTable
      tenants={tenants}
      loading={loading || loadingChildren}
      error={error ?? childError}
      {...props}
    />
  );
};
