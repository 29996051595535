import * as React from 'react';
import { useCognitoAuth } from '@bb-ui/auth/dist/cognito';

export type AppState = Record<string, unknown>;

export interface SignInRedirectOptions {
  appState?: AppState;
}

export interface AuthParams {
  /**
   * Auth-broker provided access token.
   */
  accessToken?: string | null;
  /**
   * Consumer provided state returned after the user is authenticated.
   */
  appState?: AppState;
  /**
   * Called after redirect to exchange authentication result.
   */
  handleRedirectCallback?: () => void;
  /**
   * Any error during authentication.
   */
  error?: Error;
  /**
   * Auth-broker provided ID token.
   */
  idToken?: string | null;
  /**
   * True when the user is authenticated.
   */
  isAuthenticated: boolean;
  /**
   * If authentication is in a loading state.
   */
  loading?: boolean;
  /**
   * Auth-broker provided refresh token.
   */
  refreshToken?: string | null;
  /**
   * Function call to initialize sign-in.
   */
  signIn: (options?: SignInRedirectOptions) => void;
  /**
   * Function call to initialize sign-out.
   */
  signOut: () => void;
  /**
   * TODO: We don't currently have this accounted for in Cognito.
   * Matching what we use in app from the Auth0 interface for the short term.
   */
  user?: {
    nickname: string;
    picture: string;
  } | null;
}

export const AuthContext = React.createContext<AuthParams | null>(null);

export function useAuthContext() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within the AuthContext.Provider');
  }

  return context;
}

export const AuthContextProvider = (props: React.PropsWithChildren<Partial<AuthParams>>) => {
  const { children, ...overrides } = props;
  const {
    accessToken,
    appState,
    error,
    idToken,
    isAuthenticated,
    loading,
    refreshToken,
    signInRedirect: signIn,
    signOutRedirect: signOut,
  } = useCognitoAuth();

  const context: AuthParams = {
    accessToken,
    appState,
    error,
    idToken,
    isAuthenticated,
    loading,
    refreshToken,
    signIn,
    signOut,
    user: null,
    ...overrides,
  };

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
