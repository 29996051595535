// This file contains the readonly items, forced off toggle, notes textfield, and the add/view descendant button.
import React, { useState, ReactElement } from 'react';
import {
  Typography,
  createStyles,
  makeStyles,
  Theme,
  OutlineButton,
  TextField,
} from '@bb-ui/react-library';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { Attention } from '@bb-ui/icons/dist/small/Attention';
import { useTranslation } from 'react-i18next';
import { Descendant } from './Descendant';
import { Toggle } from './Toggle';
import { MobileConstants } from './MobileConstants';
import { MobileDetails, MobileProps } from './useTenantMobile';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
    },
    leftCardContent: {
      paddingLeft: theme.spacing(0),
      flex: 1,
    },
    rightCardContent: {
      flex: 1,
    },
    costInputField: {
      width: '75%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'justify',
      padding: theme.spacing(0, 0, 2, 0),
    },
    readOnlyItem: {
      paddingTop: theme.spacing(1),
    },
    rightAlign: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    tooltip: {
      maxWidth: 125,
      justifyContent: 'center',
    },
    switchLabelContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    switchLabel: {
      minWidth: 30,
    },
    switchTitle: {
      padding: theme.spacing(0, 0, 0, 6),
      minWidth: 30,
    },
    viewDescendantButton: {
      marginRight: theme.spacing(2),
    },
    errorDisplay: {
      color: theme.palette.error.main,
      fontStyle: 'italic',
      display: 'flex',
    },
    attentionIcon: {
      margin: theme.spacing(0, 0.5, 0, 0),
      padding: theme.spacing(0, 0.4, 0, 0),
    },
  }),
);

export const MobileConfigurationDetails: React.FC<MobileProps> = ({
  mobileprops,
  updateMobileDetails,
  updateChildDetails,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // boolean to decide the button text
  const [isViewDescendants, setIsViewDescendants] = useState(true);
  // array to store the descendant details
  const [descendant, setDescendant] = useState<ReactElement[]>([]);
  const filteredMobileDetails = mobileprops?.items.filter((item) => item.domain === 'mobile');

  const [helperTextForNotes, setHelperTextForNotes] = useState('');

  // Helper function to extract the numeric part from the domain
  const extractNumberFromDomain = (domain: string) => {
    const match = domain.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  // Filter items based on the condition "domain": "!mobile" and sort them in ascending order
  const filteredMobileChildDetails = mobileprops?.items
    .filter((item) => item.domain !== 'mobile')
    .sort((item1, item2) => {
      const domainNumber1 = extractNumberFromDomain(item1.domain);
      const domainNumber2 = extractNumberFromDomain(item2.domain);

      return domainNumber1 - domainNumber2; // Ascending order
    });
  // Get the item with the largest number
  const itemWithLargestNumber = filteredMobileChildDetails?.reduce((maxItem, currentItem) => {
    const maxDomainNumber = extractNumberFromDomain(maxItem.domain);
    const currentDomainNumber = extractNumberFromDomain(currentItem.domain);

    return currentDomainNumber > maxDomainNumber ? currentItem : maxItem;
  }, filteredMobileChildDetails[0]);

  const largestNumber = itemWithLargestNumber
    ? extractNumberFromDomain(itemWithLargestNumber.domain)
    : 0;

  // Counter to keep track of the number of descendant details to provide id to the new child
  const [counter, setCounter] = useState(
    filteredMobileChildDetails && filteredMobileChildDetails.length > 0 ? largestNumber : 1,
  );

  const [parentValue, setParentValue] = useState({
    forcedOffValue: filteredMobileDetails?.[0].configuration.forcedOff || '0',
    notesValue: filteredMobileDetails?.[0].configuration.notes || '',
  });

  // Helper function to create updatedMobileDetails
  const createUpdatedMobileDetails = (newNotes: string, forcedOff?: string) => {
    if (filteredMobileDetails && filteredMobileDetails.length > 0) {
      return {
        ...filteredMobileDetails[0],
        configuration: {
          ...filteredMobileDetails[0].configuration,
          forcedOff: forcedOff !== undefined ? forcedOff : parentValue.forcedOffValue,
          notes: newNotes,
        },
      };
    }
    return null;
  };

  // Update the forcedOff toggle to the parent component
  const updateParentMobileConfigurationDetails = (forcedOff: string, isModified: boolean) => {
    setParentValue({ ...parentValue, forcedOffValue: forcedOff });
    const updatedMobileDetails = createUpdatedMobileDetails(parentValue.notesValue, forcedOff);
    if (updatedMobileDetails) {
      updateMobileDetails(updatedMobileDetails, isModified);
    }
  };

  // Update the notes field
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNotes =
      event.target.value.length <= 1000 ? event.target.value : event.target.value.slice(0, 1001);
    setParentValue({ ...parentValue, notesValue: newNotes });
    setHelperTextForNotes(
      event.target.value.length > 1000 ? t('mobileConfiguration.textLimitError') : '',
    );
    const updatedMobileDetails = createUpdatedMobileDetails(newNotes);
    if (updatedMobileDetails) {
      updateMobileDetails(updatedMobileDetails, true);
    }
  };

  // Update the descendant details (new/existing) to the parent component
  const updateChildMobileConfigurationDetails = (
    newMobileDetails: MobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => {
    updateChildDetails(newMobileDetails, counter, newChild, deleteChild);
  };

  // descendent button click action
  const handleOnDescendantButtonClick = () => {
    setCounter((prevCounter) => prevCounter + 1);
    if (filteredMobileChildDetails && filteredMobileChildDetails.length > 0 && isViewDescendants) {
      setIsViewDescendants(!isViewDescendants);
      const descendants = filteredMobileChildDetails.map((school) => {
        // Extract the numeric part if there is a match
        const extractedNumber = extractNumberFromDomain(school.domain);

        return (
          <Descendant
            counter={extractedNumber}
            mobileprops={school}
            updateChildDetails={updateChildMobileConfigurationDetails}
          />
        );
      });
      setDescendant([...descendant, ...descendants]);
    } else {
      setDescendant([
        ...descendant,
        <Descendant
          counter={counter}
          mobileResponse={mobileprops}
          updateChildDetails={updateChildMobileConfigurationDetails}
        />,
      ]);
    }
  };

  return (
    <>
      <div className={classes.cardContent}>
        <CardContent className={classes.leftCardContent}>
          <Typography data-testid="instance-type" variant="h5">
            {filteredMobileDetails?.[0].configuration.environment}
          </Typography>
          <Typography data-testid="public-id" variant="body2">
            {filteredMobileDetails?.[0].configuration.publicId}
          </Typography>
          <Typography
            className={classes.readOnlyItem}
            data-testid="peoplesoft-id-title"
            variant="h5"
          >
            {filteredMobileDetails?.[0].configuration.peopleSoftInstitutionId
              ? t('mobileConfiguration.psftinstitutionId')
              : ''}
          </Typography>
          <Typography variant="body2" data-testid="peoplesoft-id">
            {filteredMobileDetails?.[0].configuration.peopleSoftInstitutionId}
          </Typography>
          <Toggle
            isFromChildren={false}
            data-testid="toggle-switch"
            mobileProps={filteredMobileDetails?.[0]}
            updateMobileDetails={updateParentMobileConfigurationDetails}
          />
        </CardContent>
        <CardContent className={classes.rightCardContent}>
          <div className={classes.costInputField}>
            <TextField // notes field
              id="update-client-parent-input"
              helperText={
                <Typography variant="caption" className={classes.errorDisplay}>
                  {helperTextForNotes.length !== 0 && (
                    <Attention className={classes.attentionIcon} data-testid="notes-error-icon" />
                  )}
                  {helperTextForNotes}
                </Typography>
              }
              label={t('mobileConfiguration.notes')}
              multiline
              rows={MobileConstants.RowSize}
              value={parentValue.notesValue}
              onChange={handleNotesChange}
              fullWidth
              data-testid="notes"
            />
          </div>
        </CardContent>
      </div>

      <div>
        {descendant.map((descendant, index) => (
          <div key={index} data-testid={`descendant-${index}`}>
            {descendant}
          </div>
        ))}
        <div className={classes.rightAlign}>
          <OutlineButton onClick={handleOnDescendantButtonClick} data-testid="descendant-button">
            {filteredMobileChildDetails &&
            filteredMobileChildDetails.length > 0 &&
            isViewDescendants
              ? t('mobileConfiguration.descendantButtonType.view')
              : t('mobileConfiguration.descendantButtonType.add')}
          </OutlineButton>
        </div>
      </div>
    </>
  );
};
