import { PrimaryButton } from '@bb-ui/react-library';
import React from 'react';
import { useJobExecutionContext } from 'contexts/JobExecutionContext';
import { Refresh } from '@bb-ui/icons/dist/medium';

export const JobExecutionRefreshButton: React.FC = () => {
  const { refresh } = useJobExecutionContext();
  return (
    <PrimaryButton onClick={refresh} startIcon={<Refresh />}>
      Refresh
    </PrimaryButton>
  );
};
