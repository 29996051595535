import { IconButton as MuiIconButton, Tooltip } from '@bb-ui/react-library';
import { IconButtonProps as MuiIconButtonProps } from '@material-ui/core';
import * as React from 'react';

export interface TooltipIconButtonProps extends MuiIconButtonProps {
  label: string;
}

// This pairs an IconButton with a Tooltip.

export const TooltipIconButton: React.FC<TooltipIconButtonProps> = (props) => {
  const { label, ...other } = props;

  return (
    <Tooltip title={label} isCustomTitle placement="top">
      <MuiIconButton aria-label={label} {...other} />
    </Tooltip>
  );
};
