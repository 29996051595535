import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
  FeatureFlagVisibility,
  putTenantsFlagValue,
} from '@bb-config-ui/feature-flags';
import { Information } from '@bb-ui/icons/dist/small';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  DialogActions,
  DefaultButton,
  PrimaryButton,
  Radio,
  OutlineButton,
  Tooltip,
} from '@bb-ui/react-library';
import {
  FieldsetProps,
  LegendProps,
} from '@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useAuthContext } from 'contexts/AuthContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TenantData } from '../../../App.types';
import { FeatureFlagValuesFollowupDialog } from '../FeatureFlagGeneral/FeatureFlagValuesFollowupDialog';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  editHeadingText: {
    marginRight: theme.spacing(1),
  },
  radioButton: {
    marginLeft: theme.spacing(-1),
  },
  sectionDescription: {
    marginLeft: theme.spacing(3),
  },
  sectionTitle: {
    display: 'inline-flex',
  },
  dialogTitle: {
    alignItems: 'start',
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(0.5),
  },
}));

export interface FeatureFlagRegionVisibilityDialogProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (
    flagKey: string,
    defaultValue?: string,
    clearFlagValues?: boolean,
    visibility?: FeatureFlagVisibility
  ) => void;
  selectedRegion: string;
  tenants: TenantData[];
  flagName: string;
  batchUpdateState: (isLoading?: boolean, response?: any, error?: Error) => void;
}

export const FeatureFlagRegionsDialog: React.FC<FeatureFlagRegionVisibilityDialogProps> = (
  props,
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const { idToken } = useAuthContext();
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const {
    flagDefinition,
    isDialogOpen,
    dialogToggle,
    updateFeatureFlagDefinition,
    tenants,
    selectedRegion,
    flagName,
    batchUpdateState,
  } = props;
  const { flagKey, visibility } = flagDefinition;
  const [flagNewVisibility, setFlagNewVisibility] = React.useState<FeatureFlagVisibility>();
  const [currentFlagValue, setValue] = React.useState<string>();
  const [currentAdminPermission, setCurrentAdminPermission] = React.useState<string>();
  const featureFlagSupportedRegions = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const selectedRegionTenants = tenants.filter((tenant) => tenant.region === selectedRegion);
  const [batchUpdateLoading, setBatchUpdateLoading] = React.useState<boolean>(false);

  function getNewVisibility(adminPermission: string) {
    let newVisibility;
    let visibilityValue;
    switch (adminPermission) {
      case 'None': {
        visibilityValue = false;
        break;
      }
      case 'CanView': {
        visibilityValue = true;
        break;
      }
      case 'CanEdit': {
        visibilityValue = true;
        break;
      }
      default: {
        throw new Error(`Unexpected target value ${adminPermission}`);
      }
    }
    const previousCriteria = {
      tenants: visibility.criteria?.tenants,
      fleets: visibility.criteria?.fleets,
    };
    if (visibility.criteria?.regions) {
      if (
        visibility.criteria.regions.length === 1 &&
        visibility.criteria.regions.includes(selectedRegion) &&
        !visibilityValue
      ) {
        // Input: only one region criteria enabled, one region wants to be DISABLED
        // Expected outcome: visible criteria becomes false, all region criteria are deleted including the field itself
        newVisibility = { visible: false, criteria: { ...previousCriteria } };
      } else if (
        visibility.criteria.regions.length > 1 &&
        visibility.criteria.regions.includes(selectedRegion) &&
        !visibilityValue
      ) {
        // Input: two or more region criteria enabled, one region wants to be DISABLED
        // Expected outcome:  no visible criteria, region criteria minus the disabled region stay.
        newVisibility = {
          visible: false,
          criteria: {
            ...previousCriteria,
            regions: visibility.criteria.regions.filter((region) => region !== selectedRegion),
          },
        };
      } else if (
        visibility.criteria.regions.length === featureFlagSupportedRegions.length - 1 &&
        !visibility.criteria.regions.includes(selectedRegion) &&
        visibilityValue
      ) {
        // Input: All but one region are enabled, the region that wants to be ENABLED is the missing one
        // Expected outcome:  visible criteria  = true, region criteria gets deleted including the field itself
        newVisibility = { visible: true, criteria: { ...previousCriteria } };
      } else if (
        visibility.criteria.regions.length >= 1 &&
        !visibility.criteria.regions.includes(selectedRegion) &&
        visibilityValue
      ) {
        // Input: One or more region criteria enabled, one region wants to be ENABLED
        // Expected outcome:  no visible criteria, region criteria plus the enabled region stay.
        newVisibility = {
          visible: false,
          criteria: {
            ...previousCriteria,
            regions: [...visibility.criteria.regions, selectedRegion],
          },
        };
      } else {
        // Input: Default, used for when a disabled flag value needs to be refreshed
        // Expected outcome:  same flag definition as the initial one
        newVisibility = {
          visible: false,
          criteria: { ...previousCriteria, regions: visibility.criteria.regions },
        };
      }
    } else if (
      !('criteria' in visibility) ||
      (visibility.criteria && !visibility.criteria.regions)
    ) {
      if (visibility.visible && !visibilityValue) {
        // Input: Visibility = true, One region wants to be DISABLED
        // Expected outcome: visible criteria is deleted, all region criteria become enabled except the cne that has just been disabled
        if (featureFlagSupportedRegions.length > 1) {
          newVisibility = {
            visible: false,
            criteria: {
              ...previousCriteria,
              regions: featureFlagSupportedRegions.filter((region) => region !== selectedRegion),
            },
          };
        } else {
          newVisibility = { visible: false, criteria: { ...previousCriteria } };
        }
      } else if (!visibility.visible && visibilityValue) {
        // Input: Visibility = false, One region wants to be ENABLED
        // Expected outcome: visible criteria is deleted, only the selected region becomes enabled as criteria
        if (featureFlagSupportedRegions.length > 1) {
          newVisibility = {
            visible: false,
            criteria: { ...previousCriteria, regions: [selectedRegion] },
          };
        } else {
          newVisibility = { visible: true, criteria: { ...previousCriteria } };
        }
      } else {
        // Input: Default, used for when a disabled flag value needs to be refreshed
        // Expected outcome:  same flag definition as the initial one
        newVisibility = { visible: false, criteria: { ...previousCriteria } };
      }
    }

    return newVisibility;
  }

  function handleClearValues() {
    setValue(undefined);
    setCurrentAdminPermission(undefined);
    setFlagNewVisibility(undefined);
  }

  function handleOnClose() {
    handleClearValues();
    dialogToggle(false);
  }

  function handleOnChangeFlagStatus(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handleOnChangeAdminPermissions(event: React.ChangeEvent<HTMLInputElement>) {
    setFlagNewVisibility(getNewVisibility(event.target.value as string));
    setCurrentAdminPermission(event.target.value);
  }

  function handleOnSubmit() {
    let locked;
    if (currentAdminPermission) {
      switch (currentAdminPermission) {
        case 'None': {
          break;
        }
        case 'CanView': {
          locked = true;
          break;
        }
        case 'CanEdit': {
          locked = false;
          break;
        }
        default: {
          throw new Error(`Unexpected target value ${currentAdminPermission}`);
        }
      }
    }
    const selectedRegionTenantIds: string[] = [];
    selectedRegionTenants.forEach((tenant) => {
      selectedRegionTenantIds.push(tenant.id);
    });

    if ((currentFlagValue || locked !== undefined) && selectedRegionTenantIds) {
      setBatchUpdateLoading(true);
      batchUpdateState(true);
      putTenantsFlagValue(
        idToken as string,
        flagKey,
        selectedRegion,
        selectedRegionTenantIds,
        'Tenant',
        currentFlagValue,
        locked,
      )
        .then((response) => {
          batchUpdateState(false, response);
        })
        .catch((error) => {
          batchUpdateState(false, undefined, error);
        })
        .finally(() => {
          setBatchUpdateLoading(false);
        });
    }

    if (flagNewVisibility) {
      updateFeatureFlagDefinition(flagKey, undefined, undefined, flagNewVisibility);
    }

    handleClearValues();
  }

  let flagValueOptions;
  if (flagDefinition.possibleValues.type.toLowerCase() === 'list') {
    flagValueOptions = (
      <>
        {flagDefinition.possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => (
          <FormControlLabel
            key={`${option.value}-option`}
            value={option.value.toLowerCase()}
            className={classes.radioButton}
            checked={currentFlagValue === option.value.toLowerCase()}
            control={<Radio />}
            label={option.value.toLowerCase()}
          />
        ))}
      </>
    );
  } else {
    flagValueOptions = (
      <>
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'false'}
          value="false"
          control={<Radio />}
          label={t('global.off')}
        />
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'true'}
          value="true"
          control={<Radio />}
          label={t('global.on')}
        />
      </>
    );
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      data-testid="feature-flags-region-visibility-dialogue"
    >
      <FeatureFlagValuesFollowupDialog isDialogOpen={batchUpdateLoading} />
      <DialogTitle
        className={classes.dialogTitle}
        onClose={handleOnClose}
        id="feature-flags-region-dialog-title"
      >
        {t('featureFlagGeneral.manageSettings', { flagName, item: selectedRegion })}
      </DialogTitle>
      <DialogContent id="feature-flags-region-visibility-dialogue-content">
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-region-dialogue-feature-status-subtitle"
            >
              {t('tenantFeatureFlags.featureStatus')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Feature Status',
                criteria: 'region',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-feature-status"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-region-dialogue-feature-status-options"
            name="region-edit-feature-status"
            onChange={handleOnChangeFlagStatus}
          >
            {flagValueOptions}
          </RadioGroup>
        </Fieldset>
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-region-dialogue-flag-visibility-subtitle"
            >
              {t('featureFlagGeneral.clientAdminPermissions')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Client Admin Permissions',
                criteria: 'region',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-client-admin-permissions"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-region-dialogue-flag-visibility-options"
            name="region-edit-visibility"
            onChange={handleOnChangeAdminPermissions}
          >
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'None'}
              value="None"
              control={<Radio />}
              label={t('featureFlagGeneral.none')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanView'}
              value="CanView"
              control={<Radio />}
              label={t('featureFlagGeneral.canView')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanEdit'}
              value="CanEdit"
              control={<Radio />}
              label={t('featureFlagGeneral.canEdit')}
            />
          </RadioGroup>
        </Fieldset>
      </DialogContent>
      <DialogActions
        id="feature-flags-region-visibility-dialogue-actions"
        tertiaryContent={
          <OutlineButton onClick={handleClearValues}> {t('global.reset')}</OutlineButton>
        }
      >
        <DefaultButton
          id="feature-flags-region-visibility-dialogue-cancel-btn"
          onClick={handleOnClose}
        >
          {t('global.cancel')}
        </DefaultButton>
        <PrimaryButton
          id="feature-flags-region-visibility-dialogue-submit-btn"
          onClick={handleOnSubmit}
          disabled={currentAdminPermission === undefined && currentFlagValue === undefined}
        >
          {t('global.save')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
