import * as React from 'react';
import { ACTIVE_STATUSES, PENDING_STATUSES, Status, TERMINAL_STATUSES } from '@lct-jobs/core';
import { StatusApproval, StatusAway, StatusDisapproval } from '@bb-ui/icons/dist/medium';
import { LoadingIndicator } from '../LoadingIndicator';

export const JobStatusIcon: React.FC<{ status?: Status }> = (props) => {
  let status;
  if (TERMINAL_STATUSES.includes(props.status as any)) {
    if (props.status === Status.COMPLETED) {
      status = <StatusApproval />;
    } else {
      status = <StatusDisapproval color="error" />;
    }
  } else if (ACTIVE_STATUSES.includes(props.status as any)) {
    status = <LoadingIndicator size="small" />;
  } else if (PENDING_STATUSES.includes(props.status as any)) {
    status = <StatusAway />;
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {status}
    </div>
  );
};
