import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tabs } from '@bb-ui/react-library/dist/components/Tabs';
import { Tab } from '@bb-ui/react-library/dist/components/Tab';
import { ITabsProps } from '@bb-ui/react-library/dist/components/Tabs/Tabs.types';
import { ITabProps } from '@bb-ui/react-library/dist/components/Tab/Tab.types';
import { useTranslation } from 'react-i18next';
import { AppRouteProps } from 'routes/AppRoute';
import { useAppRouteContext } from 'contexts/AppRouteContext';
import { useAuthorization } from 'hooks/useAuthorization';

// Props of this <PageTabs> component.

export interface PageTabsProps extends ITabsProps {
  tabPanelId: string;
  tabProps?: PageTabProps;
  disabledTabIds?: string[];
}

// Props for each <Tab> inside the component.

export interface PageTabProps extends ITabProps {
  component?: React.ElementType;
  to?: string;
}

export const PageTabs: React.FunctionComponent<PageTabsProps> = (props) => {
  const PageTab = Tab as React.ElementType<PageTabProps>;
  const { tabPanelId, tabProps, disabledTabIds, ...other } = props;
  const { currentRoute, currentLevelRoutes } = useAppRouteContext();
  const { hasPermission } = useAuthorization();
  const routeParams: { [key: string]: string } = useParams();
  const { t } = useTranslation();
  const tabRoutes: AppRouteProps[] = currentLevelRoutes.filter((route) => {
    if (!route.hasTab) {
      return false;
    }

    if (
      route.authorization &&
      !route.authorization.every(({ permission, service }) => hasPermission(service, permission))
    ) {
      return false;
    }

    if (disabledTabIds && disabledTabIds.includes(route.path)) {
      return false;
    }

    return true;
  });
  let currentTab = 0;

  if (currentRoute) {
    currentTab = tabRoutes.indexOf(currentRoute);
    // Coerce -1 for not found to 0.
    if (currentTab === -1) {
      // If we're on a nested route, find the parent route.
      // This supports having multiple pages with the same tab.
      const parentRoute = tabRoutes
        .filter((route) => currentRoute.path.startsWith(route.path))
        .pop();
      currentTab = parentRoute ? tabRoutes.indexOf(parentRoute) : 0;
    }
  }

  // Our route config is full of :params. Interpolate what we can into links.

  function interpolateParams(value: string) {
    return Object.keys(routeParams).reduce(
      (result, param: string) =>
        result.replace(new RegExp(`/:${param}(/|$)`, 'g'), `/${routeParams[param]}$1`),
      value,
    );
  }

  return (
    <Tabs value={currentTab} variant="scrollable" aria-orientation="horizontal" data-testid="page-tabs" {...other}>
      {tabRoutes.map((route) => (
        <PageTab
          aria-controls={tabPanelId}
          component={Link}
          id={`tab-${route.path}`}
          key={`tab-${route.path}`}
          label={t(route.name!)}
          to={interpolateParams(route.path)}
          {...tabProps}
        />
      ))}
    </Tabs>
  );
};

export default PageTabs;
