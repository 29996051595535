import * as React from 'react';
import {
  IconButton,
  Link,
  PrimaryButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  createStyles,
  makeStyles,
} from '@bb-ui/react-library';
import theme from '@bb-ui/react-library/dist/components/styles/theme';
import { ChevronRight } from '@bb-ui/icons/dist/small';
import useRestApi from 'hooks/useRestApi';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageTemplate } from 'components/PageTemplate';
import { ErrorMessage } from 'components/ErrorMessage';
import { EditDomainSchemaDialog } from 'dialogs/EditDomainSchemaDialog';
import { AddDomainButton } from './AddDomainButton';

export const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    description: {
      width: '60%',
      marginBottom: theme.spacing(2),
    },
    table: {
      width: '60%',
    },
    tableHead: {
      height: '100%',
    },
    manageSchemaDetails: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    edit: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    domainNameCell: {
      width: '60%',
    },
    loadMoreButton: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

// Loosely typing this, so we can use <ButtonBase> props.
const LooseIconButton = IconButton as any;

export const DomainConfiguration: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const {
    data: domainSchemas,
    loading: loadingDomains,
    error: errorGettingDomains,
    fetch: fetchDomains,
  } = useRestApi(apiUrl('config', `capabilityConfigs/schemas/`));
  const nextPageToken = domainSchemas?.nextPageToken ?? [];
  const domainItems = domainSchemas?.items ?? [];
  const [domainList, setDomainList] = React.useState<string[]>(() => domainItems ?? []);
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (domainItems.length > 0) {
      setDomainList((prevDomains) => [...prevDomains, ...domainItems]);
    }
  }, [domainItems]);

  React.useEffect(() => {
    if (nextPageToken.length > 0) {
      fetchDomains({
        method: 'GET',
        params: {
          nextPageToken: nextPageToken,
        },
      });
    }
  }, [nextPageToken, fetchDomains]);

  const handleLoadMore = () => {
    fetchDomains({
      method: 'GET',
      params: {
        nextPageToken: nextPageToken,
      },
    });
  };

  let content: React.ReactElement;

  if (errorGettingDomains) {
    content = (
      <ErrorMessage
        data-testid="domains-list-error"
        title={t('domainConfiguration.loadError')}
        message={errorGettingDomains.message}
        variant="block"
      />
    );
  } else if (loadingDomains && domainItems.length === 0) {
    content = <LoadingIndicator data-testid="domains-list-init" />;
  } else if (domainList.length === 0) {
    content = (
      <Typography data-testid="domains-list-no-data">{t('domainConfiguration.noData')}</Typography>
    );
  } else {
    const isLoadMoreButtonVisible = !!domainSchemas.nextPageToken;

    const LoadMoreButton = () => (
      <div className={classes.loadMoreButton}>
        {loadingDomains ? (
          <LoadingIndicator data-testid="load-more-loading" />
        ) : (
          <PrimaryButton onClick={handleLoadMore}>
            {t('domainConfiguration.loadMore')}
          </PrimaryButton>
        )}
      </div>
    );

    const NoMoreData = () => (
      <Typography className={classes.loadMoreButton} data-testid="domains-list-no-more-data">
        {t('domainConfiguration.noMoreData')}
      </Typography>
    );

    content = (
      <div className={classes.tableContainer}>
        <Typography variant="body1" className={classes.description}>
          {t('domainConfiguration.description')}
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classes.domainNameCell}>
                {t('domainConfiguration.domainName')}
              </TableCell>
              <TableCell className={classes.manageSchemaDetails}>
                {t('domainConfiguration.manageSchemaDetails')}
              </TableCell>
            </TableRow>
          </TableHead>
          <tbody>
            {domainList.map((domain, index) => (
              <TableRow key={index}>
                <TableCell>{domain}</TableCell>
                <TableCell className={classes.edit}>
                  <LooseIconButton
                    aria-label={'domain-schema-edit-button'}
                    test-id={'domain-schema-edit-button'}
                    component={Link}
                    onClick={() => {
                      setSelectedDomain(domain);
                      setDialogIsOpen(true);
                    }}
                  >
                    <Typography component="span" variant="inherit">
                      {t('global.edit')}
                    </Typography>
                    <ChevronRight />
                  </LooseIconButton>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        {isLoadMoreButtonVisible ? <LoadMoreButton /> : <NoMoreData />}
      </div>
    );
  }

  return (
    <PageTemplate
      showTabs
      headerControl={<AddDomainButton fetchDomains={fetchDomains} />}
      title={t('domainConfiguration.pageTitle')}
      data-testid="configuration-domain-list-page"
    >
      {selectedDomain && (
        <EditDomainSchemaDialog
          id={`edit-domain-schema-dialog-${selectedDomain}`}
          domain={selectedDomain}
          onClose={() => setDialogIsOpen(false)}
          open={dialogIsOpen}
        />
      )}
      {content}
    </PageTemplate>
  );
};
