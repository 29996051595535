import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UASProvider, UASProviderProps } from '@bb-ui/auth/dist/auth0';
import { useAuth0Context } from '@bb-ui/auth';
import { EditTool } from '@bb-ui/icons/dist/medium/EditTool';
import { PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useTenantContext } from 'contexts/TenantContext';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { IdentityProvider } from 'pages/TenantIdp/TenantIdp.types';
import { debounce } from 'lodash';

interface TenantSupportLoginButtonProps {
  tenantId: string;
  idpId: string;
}

const TenantSupportLoginButton: React.FC<TenantSupportLoginButtonProps> = (props) => {
  const { tenantId, idpId } = props;
  const { t } = useTranslation();
  const { login } = useAuth0Context();

  const doLogin = React.useCallback(
    debounce(
      () => {
        if (login) {
          login({
            bb_login_params: window.btoa(JSON.stringify({ tenantId, idpId, isSupport: true })),
          });
        }
      },
      1000,
      { leading: true },
    ),
    [login, tenantId, idpId],
  );

  return (
    <PrimaryButton onClick={doLogin} startIcon={<EditTool />} data-testid="support-button">
      {' '}
      {t('tenant.support')}
    </PrimaryButton>
  );
};

export const TenantSupportLogin: React.FunctionComponent = () => {
  const { tenant } = useTenantContext();
  const { supportAuth0 } = useAppConfigContext();

  const tenantId = tenant?.id;
  const tenantUriComponent = tenant?.uriComponent;
  const { data: supportIdpData } = useRestApi(
    apiUrl('sso', `tenants/${tenantId}/identityProviders?isSupport=true`),
  );
  const supportIdpId = React.useMemo(
    () => (supportIdpData?.results[0] as IdentityProvider | undefined)?.id,
    [supportIdpData],
  );
  const tenantRegion = tenant?.region;
  if (!tenantRegion || !tenantId || !supportIdpId || !tenantUriComponent) {
    return null;
  }

  if (!supportAuth0) {
    throw new Error('External Auth0 configuration not provided');
  }

  const supportAuth0Application = supportAuth0.regions?.[tenantRegion];
  if (!supportAuth0Application) {
    return null;
  }

  const uasProps: UASProviderProps = {
    apiBaseURL: '/sso/api/v1',
    audienceNamespace: `${supportAuth0.bbNamespace}/tenants`,
    auth0Clients: {
      [tenantRegion]: {
        domain: supportAuth0Application.domain,
        client_id: supportAuth0Application.clientId,
      },
    },
    redirect_uri: `${supportAuth0.redirectUri}?isSupport=true&tenantId=${tenantId}&tenantUriComponent=${tenantUriComponent}`,
    tenantId,
    tenantUriComponent,
  };

  return (
    <UASProvider {...uasProps}>
      <TenantSupportLoginButton tenantId={tenantId} idpId={supportIdpId} />
    </UASProvider>
  );
};
