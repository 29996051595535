// A table of group mappings that may be edited by the user. Edits are communicated upward.

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@bb-ui/react-library/dist/components/Table';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { GroupMappingEditableTableRow } from './GroupMappingEditableTableRow';
import { GroupMappingTableRow } from './GroupMappingTableRow';
import { GroupMappings, FoundationsGroup } from './GroupMapping.types';

export interface GroupMappingTableProps {
  isEditing: boolean;
  groupMappings: GroupMappings[];
  onChangeMapping: (fndsGroup: FoundationsGroup, tenantGroups: string[]) => void;
}

export const GroupMappingTable: React.FunctionComponent<GroupMappingTableProps> = ({ isEditing, groupMappings, onChangeMapping }) => {
  const { t } = useTranslation();

  function handleMappingChange(fndsGroup: FoundationsGroup, tenantGroups: string[]) {
    onChangeMapping(fndsGroup, tenantGroups);
  }

  return (
    <Table data-testid="group-mapping-table">
      <TableHead>
        <TableRow>
          <TableCell>{t('tenantIdp.groupTitle')}</TableCell>
          <TableCell>{t('tenantIdp.typeTitle')}</TableCell>
          <TableCell>{t('tenantIdp.groupsTitle')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groupMappings.map(item => (
          isEditing ? (
            <GroupMappingEditableTableRow key={`row-${item.fndsGroup.name}`} onChange={handleMappingChange} {...item} />
          ) : (
            <GroupMappingTableRow key={`row-${item.fndsGroup.name}`} {...item} />
          )
        ))}
      </TableBody>
    </Table>
  );
};
