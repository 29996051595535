// A clickable card representing an IDP that navigates to a particular route.

import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from '@bb-ui/icons/dist/small/ChevronRight';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { IdpConnectionType } from 'App.types';
import { ButtonBase } from '@bb-ui/react-library/dist/components/ButtonBase';

export interface IdpCardProps {
  displayName: string;
  to: string;
  type: IdpConnectionType;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.background.b2,
      textDecoration: 'underline',
    },
  },
  label: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridAutoFlow: 'column',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 1, 0),
    borderRadius: 999,
    fontSize: 10,
    fontWeight: 'bold',
  },
}));

export const IdpCard: React.FunctionComponent<IdpCardProps> = ({ displayName, to, type }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const LooseButtonBase = ButtonBase as any;

  return (
    <LooseButtonBase className={classes.root} component={Link} to={to}>
      <span className={classes.label}>
        <Typography variant="h4">{displayName}</Typography>
        <Typography className={classes.badge}>{t(`idpCard.connectionType.${type}`)}</Typography>
      </span>
      <ChevronRight />
    </LooseButtonBase>
  );
};
