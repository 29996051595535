import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  PrimaryButton,
  Typography,
} from '@bb-ui/react-library';
import { Switch } from '@bb-ui/react-library/dist/components/Switch';
import { DialogProps } from '@bb-ui/react-library/dist/components/Dialog/Dialog.types';
import { TenantData } from 'App.types';

export interface SsoDialogProps extends Omit<DialogProps, 'onSubmit'> {
  onClose: () => void;
  onSave: ({ isHidden }: Partial<TenantData>) => void;
  tenant?: Partial<TenantData>;
}

export const SsoDialog = (props: SsoDialogProps) => {
  const { id, open, onSave, onClose, tenant } = props;
  const [isHidden, setIsHidden] = React.useState<boolean>();
  const { t } = useTranslation();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    onSave({ isHidden });
  }

  React.useEffect(
    () => {
      if (open) {
        // Reset on open
        setIsHidden(tenant?.isHidden);
      }
    },
    [open, tenant],
  );

  return (
    <Dialog
      id={id}
      open={open}
      onClose={onClose}
      aria-labelledby={`${id}-title`}
      maxWidth="md"
    >
      <DialogTitle id={`${id}-title`} onClose={onClose}>{t('tenantAuth.singleSignOn')}</DialogTitle>
      <form onSubmit={handleSubmit} data-testid="sso-form">
        <DialogContent>
          <Typography variant="subtitle2">{t('tenantAuth.tenantDiscoverability')}</Typography>
          <Typography color="textSecondary" variant="body2" paragraph>
            <Trans
              i18nKey="tenantAuth.tenantDiscoverabilityDetail"
              components={[<Link href="https://signin.blackboard.com" />]}
              values={{ linkText: 'signin.blackboard.com' }}
            />
          </Typography>
          <FormControlLabel
            checked={!isHidden}
            htmlFor="isHidden"
            control={
              <Switch id="isHidden" onChange={() => setIsHidden(!isHidden)} />
            }
            label={t(isHidden ? 'global.off' : 'global.on')}
          />
        </DialogContent>
        <DialogActions>
          <DefaultButton onClick={onClose}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton type="submit">{t('global.save')}</PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
