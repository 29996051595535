import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardHeader,
  CardContent,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  DefaultButton,
  PrimaryButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@bb-ui/react-library';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { useTenantContext } from 'contexts/TenantContext';
import { PageCard } from 'components/PageCard';
import { useRestApi } from 'hooks/useRestApi';
import { useSnackbar } from 'hooks/useSnackbar';
import { apiUrl } from 'utils/apiUrl';
import { useAuthorization } from 'hooks/useAuthorization';
import { TooltipIconButton } from 'components/TooltipIconButton';

const cancelDialogId = 'cancel-edit-display-name';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  pageTitle: {
    marginBottom: theme.spacing(3),
  },
  cardRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageCard: {
    minHeight: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(0, 0, 4, 0),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardHeaderSecondary: {
    padding: theme.spacing(0, 0, 3, 0),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  cardHeaderAction: {
    marginRight: 0,
  },
  cardHeaderActionControl: {
    marginLeft: theme.spacing(1.5),
  },
  cardContent: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  inputField: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    textAlign: 'justify',
  },
  dialogTitle: {
    padding: theme.spacing(2, 2, 3, 3),
  },
  inputLabel: {
    fontSize: theme.typography.h3.fontSize,
  },
  displayName: {
    fontStyle: 'italic',
  },
}));

export const TenantDisplay = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant, fetch } = useTenantContext();
  const { hasPermission } = useAuthorization();
  const canEdit = hasPermission('tenant', 'update');
  const [isEditing, setIsEditing] = React.useState(false);
  const [displayNameInput, setDisplayNameInput] = React.useState('');
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const [displayName, setDisplayName] = React.useState(tenant?.displayName ?? t('tenantDisplay.notSet'));
  const {
    doPatch,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
    loadingRequests,
  } = useRestApi(apiUrl('tenancy', 'tenants'), { manual: true });

  const handleOnDialogClose = () => {
    setIsCancelDialogOpen(false);
    setIsEditing(false);
  };

  function showCancelDialog() {
    setIsCancelDialogOpen(true);
  }

  function save() {
    if (tenant) {
      setIsEditing(false);
      setDisplayName(displayNameInput);
      doPatch(tenant?.id, { displayName: displayNameInput });
      setDisplayNameInput('');
    } else {
      enqueueSnackbar(t('tenantDisplay.tenantNotDefined'), { variant: 'error' });
    }
  }

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (loadingRequests) {
      save();
    }
  }

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      fetch!();
      clearSucceededRequests();
      enqueueSnackbar(t('tenantDisplay.editDisplayNameSuccess'), { variant: 'success' });
    }
  }, [succeededRequests, clearSucceededRequests, fetch, enqueueSnackbar, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      clearFailedRequests();
      enqueueSnackbar(t('tenantDisplay.editDisplayNameError'), { variant: 'error' });
    }
  }, [failedRequests, clearFailedRequests, enqueueSnackbar, t]);

  return (
    <PageCard className={classes.pageCard} data-testid="group-display-card">
      <>
        <Dialog
          open={isCancelDialogOpen}
          onClose={handleOnDialogClose}
          aria-labelledby={`${cancelDialogId}-dialog-title`}
          maxWidth="sm"
        >
          <DialogTitle
            className={classes.dialogTitle}
            onClose={() => setIsCancelDialogOpen(false)}
            id={`${cancelDialogId}-dialog-title`}
            disableTypography
          >
            <Typography variant="h3" className="bb-h1-element" tabIndex={-1}>
              {t('tenantDisplay.cancelChanges')}
            </Typography>
          </DialogTitle>
          <DialogActions data-testid="dialog-cancel-edit-display">
            <DefaultButton onClick={() => setIsCancelDialogOpen(false)}>
              {t('global.goBack')}
            </DefaultButton>
            <PrimaryButton onClick={handleOnDialogClose}>
              {t('global.yes')}
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <CardHeader
          className={classes.cardHeaderSecondary}
          classes={{ action: classes.cardHeaderAction }}
          title={t('tenantDisplay.displaySettings')}
          titleTypographyProps={
            { component: 'h3', variant: 'h2', color: 'textPrimary' } as any
          } // any: component expects 'span' | undefined
          action={
            isEditing ? (
              <>
                <DefaultButton
                  onClick={showCancelDialog}
                  data-testid="cancel-display-edit"
                >
                  {t('global.cancel')}
                </DefaultButton>
                <PrimaryButton
                  onClick={save}
                  className={classes.cardHeaderActionControl}
                  data-testid="save-display-edit"
                >
                  {t('global.save')}
                </PrimaryButton>
              </>
            ) : (
              canEdit && (
                <TooltipIconButton
                  data-testid="edit-display"
                  label={t('global.edit')}
                  onClick={() => setIsEditing(true)}
                >
                  <Edit />
                </TooltipIconButton>
              )
            )
          }
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.cardRow}>
            <div>
              {isEditing ? (
                <form onSubmit={handleOnSubmit}>
                  <div className={classes.inputField}>
                    <TextField
                      id="tenant-display-name"
                      helperText={t('tenantDisplay.helpText')}
                      label={t('tenantDisplay.tenantDisplayName')}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      inputProps={{ 'data-testid': 'input-field-display-edit' }}
                      defaultValue={tenant?.displayName ?? ''}
                      onChange={(event) => {
                        setDisplayNameInput(event.target.value.trim());
                      }}
                      fullWidth
                    />
                  </div>
                </form>
              ) : (
                <div>
                  <Typography variant="h3">
                    {t('tenantDisplay.tenantDisplayName')}
                  </Typography>
                  <Typography
                    className={classes.displayName}
                    variant="body2"
                    color="textSecondary"
                  >
                    {displayName.trim().length === 0 ?
                      t('tenantDisplay.notSet') :
                      displayName}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </>
    </PageCard>
  );
};
